import { CommonModule } from '@angular/common';
import { Component, ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../../../shared/services/global.service';
import { ErrorMsgService } from '../../../../shared/services/error-msg.service';
import { SubscriberService } from '../../_services/subscriber.service';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;

@Component({
  selector: 'app-add-subscriber',
  standalone: true,
  imports: [CommonModule , TranslateModule , ReactiveFormsModule , MatFormFieldModule , MatInputModule , FormsModule , GoogleMapsModule],
  templateUrl: './add-subscriber.component.html',
  styleUrl: './add-subscriber.component.scss'
})
export class AddSubscriberComponent {
  subscriberForm!: UntypedFormGroup;
  currLang!: string;
  modalTitle!: string;
  formFlag: string = 'view';
  loading!: boolean;
  fieldsReadOnly = false;
  id!: number;
  image: any;
  imageFile: any;
  selectedImage: any;
  address!: string;
  latitude: any = 0;
  longitude: any = 0;
  zoom: number = 12;
  markerState: boolean = false;
  @ViewChild('searchSubLoc') searchSubLocElemRef!: ElementRef;
  searchSubscriberLocation!: string;

  trips: any[] = [];
  center!: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    zoomControl: true,
    disableDefaultUI: true,
    fullscreenControl: true,
    disableDoubleClickZoom: true,
    mapTypeControl: true,
    scrollwheel: true
    // mapTypeId: 'hybrid',
    // maxZoom:this.maxZoom,
    // minZoom:this.minZoom,
  };
  markers: any;
  constructor(private subscriberService: SubscriberService,
    private translate: TranslateService,
    private globalService: GlobalService,
    private errorMsgService: ErrorMsgService,
    private ngZone: NgZone,
    public activeModal: NgbActiveModal,

  ) {
    this.globalService.getLang().subscribe((lng) => {
      this.currLang = lng ? lng : "en";
      this.translate.use(this.currLang);
    });

    this.translate.onLangChange.subscribe((res) => {
      this.currLang = res.lang ? res.lang : "en";
      this.translate.use(this.currLang);
    });
  }

  ngOnInit(): void {
    this.subscriberForm = new UntypedFormGroup({
      name: new UntypedFormControl({ value: '', disabled: true }),
      contry_code: new UntypedFormControl({ value: '', disabled: true }),
      phone_no: new UntypedFormControl({ value: '', disabled: true }),
      school_name: new UntypedFormControl({ value: '', disabled: true }),
      school_location: new UntypedFormControl({ value: '', disabled: false }),
      profile_pic: new UntypedFormControl({ value: '', disabled: false }),
      school_logo: new UntypedFormControl({ value: '', disabled: false }),
      integratable_type: new UntypedFormControl({ value: '', disabled: false }),
      integratable_id: new UntypedFormControl({ value: '', disabled: false }),
      company_id: new UntypedFormControl({ value: '', disabled: false }),
    });
    this.subscriberService.getSubscriberData().subscribe((res) => {
      if (res?.subscriber != null) {
        this.formFlag = 'view';
        this.id = res.subscriber.id;
        this.image = res.subscriber?.profile_pic;
        this.splitLatLong(res.subscriber.home_location);
        this.initalizeLocation(this.latitude, this.longitude);
        this.fieldsReadOnly = true;
        this.subscriberForm.controls['name'].setValue(res.subscriber.name);
        this.subscriberForm.controls['contry_code'].setValue(res.subscriber.country_code);
        this.subscriberForm.controls['phone_no'].setValue(res.subscriber.phone_no);
        this.subscriberForm.controls['school_name'].setValue(res.subscriber.school_name);
        // this.subscriberForm.controls['school_location'].setValue(res.subscriber.school_location);
        // this.subscriberForm.controls['profile_pic'].setValue(res.subscriber.profile_pic);
        // this.subscriberForm.controls['school_logo'].setValue(res.subscriber.school_logo);
        // this.subscriberForm.controls['integratable_type'].setValue(res.subscriber.integratable_type);
        // this.subscriberForm.controls['integratable_id'].setValue(res.subscriber.integratable_id);
        // this.subscriberForm.controls['company_id'].setValue(res.subscriber.company_id);
      }
    });
  }

  ngAfterViewInit(): void {
    let autocomplete = new google.maps.places.Autocomplete(this.searchSubLocElemRef.nativeElement);
    autocomplete.addListener("place_changed", () => {
      this.ngZone.run(() => {
        //get the place result
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();
        //verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        //set latitude, longitude and zoom
        this.latitude = place?.geometry?.location?.lat();
        this.longitude = place?.geometry?.location?.lng();
        this.center = {
          lat: this.latitude,
          lng: this.longitude
        };
        this.addMarker();
        this.zoom = 12;
        this.getAddress(this.latitude, this.longitude)
      });
    });
  }


  saveSubscriber() {
    let body: any = {}
    // body = this.subscriberForm.value;
    body.id = this.id;
    body.home_location = `${this.latitude},${this.longitude}`;
    body.address = this.address;
    this.subscriberService.editSubscriberLOcation(body).subscribe(
      (res) => {
        this.subscriberService.setLoadSubscriberData(true);
        this.closeModalHandler();
      },
      (error) => {
        this.errorMsgService.setMessage(error.error.Error[0]);
      }
    );
  }

  editSubscriber() {
    this.markerState = true;
    this.addMarker();
    this.formFlag = 'edit'
  }

  markerDragEnd($event: any) {
    this.searchSubscriberLocation = '';
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    this.center = {
      lat: this.latitude,
      lng: this.longitude
    };
    this.getAddress(this.latitude, this.longitude);
  }

  splitLatLong(location: string) {
    let latLng = [];
    latLng = location.split(',');
    this.latitude = Number(latLng[0]);
    this.longitude = Number(latLng[1]);
    this.center = {
      lat: this.latitude,
      lng: this.longitude
    };
    this.addMarker();
  }

  initalizeLocation(latitude: any, longitude: any) {
    if (latitude > 0 && longitude > 0) {
      this.getAddress(latitude, longitude);
    }
    else {
      this.getCurrentLocation();
    }
  }

  getAddress(latitude: any, longitude: any) {
    this.address = ''
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results: any, status: any) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          console.log('No results found');
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }
    });
  }


  getCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.searchSubscriberLocation = '';
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.center = {
          lat: this.latitude,
          lng: this.longitude
        };
        this.addMarker();
        this.getAddress(this.latitude, this.longitude);
      },
        err => { }, { enableHighAccuracy: true });
    }
  }

  closeModalHandler() {
    this.formFlag = 'view';
    this.fieldsReadOnly = false;
    this.subscriberForm.reset();
    this.image = null;
    this.imageFile = null;
    this.latitude = this.longitude = 0;
    this.center = {
      lat: this.latitude,
      lng: this.longitude
    };
    this.markerState = false;
    this.addMarker();
    this.searchSubscriberLocation = '';
    this.address = '';
    this.activeModal.close('Close click');
  }

  addMarker() {
    this.markers = [];
    this.markers.push({
      position: {
        lat: this.center.lat,
        lng: this.center.lng
      },
      options: { draggable: this.markerState },
    });
  }

  closeModal() {
    this.activeModal.close('Close click');
  }

  ngOnDestroy() {
    this.subscriberService.setSubscriberData(null, 'add');
    this.closeModalHandler();
  }
}
