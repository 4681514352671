import { Injectable, Injector, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { SnackBarErrorComponent } from '../components/snack-bar-error/snack-bar-error/snack-bar-error.component';
@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor() {}

  private _snackBarMessage = new BehaviorSubject<string | null>(null);
  snackBarMessage = this._snackBarMessage.asObservable();

  setSnackBarMessage(message: any) {
    this._snackBarMessage.next(message);
  }

  getSnackBarMessage() {
    return this._snackBarMessage;
  }
}
