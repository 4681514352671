import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../../../shared/services/global.service';
import { ErrorMsgService } from '../../../shared/services/error-msg.service';
import { CommonModule } from '@angular/common';
import { ControlBarComponent } from '../../../shared/components/control-bar/control-bar.component';
import { TableViewComponent } from '../../../shared/components/table-view/table-view.component';
import { SubscriberService } from '../_services/subscriber.service';
import { StorageService } from '../../../authentication/services/storage.service';
import { Subscriber } from '../../../models/subscriber.model';
import { AddSubscriberComponent } from './add-subscriber/add-subscriber.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;

@Component({
  selector: 'app-subscribers',
  standalone: true,
  imports: [TableViewComponent , ControlBarComponent , CommonModule , AddSubscriberComponent],
  templateUrl: './subscribers.component.html',
  styleUrl: './subscribers.component.scss'
})
export class SubscribersComponent {
  tableControls: any = { data: [] };
  searchText: string = '';
  pageNumber: number = 1;
  itemsPerPage: number = 25;
  order: { order_type?: string, order_by?: string } = {}

  currLang: string = 'en';
  loading!: boolean;

  rowsView: string = 'list';
  filter = {};
  selectedCheckBoxes: any = [];
  from!: number;
  to!: number;
  total!: number;

  dataArray: Subscriber[] = [];
  actions = [
    // {
    //   type: 'add',
    //   name: 'subscribers.add-new',
    // },
    // {
    //   type: 'del',
    //   name: 'subscribers.delete',
    // },
  ];
  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private subscriberService: SubscriberService,
    private storage: StorageService,
    private errorMsgService: ErrorMsgService,
    private modalService: NgbModal,

  ) {
    this.globalService.getLang().subscribe((lng) => {
      this.currLang = lng ? lng : "en";
      this.translate.use(this.currLang);
    });

    this.translate.onLangChange.subscribe((res) => {
      this.currLang = res.lang ? res.lang : "en";
      this.translate.use(this.currLang);
      this.setTableData(this.dataArray);
    });
    this.subscriberService.getLoadSubscriberData().subscribe((res) => {
      if (res) {
        this.getSubscribersData();
      }
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.searchText = params['search'] || '';

      this.filter = {
        subscriptions: {
          name: this.searchText.trim().toLowerCase(),
          phone_no: this.searchText.trim().toLowerCase(),
          operator: 'or',
        },

      }
      if (params['order_by'] && params['order_type']) {
        this.order = {
          order_by: params['order_by'],
          order_type: params['order_type'],
        }
      }
      this.pageNumber = +params['page'] || 1;
      this.itemsPerPage = params['show'] || 25;
      this.getSubscribersData();
    })
  }
  setTableData(data: any[]) {
    this.tableControls = {
      selection: false,
      paging: true,
      view: this.rowsView,
      title:'table-view.titles.subscriber',
      selectedTitle:'table-view.selected-titles.subscriber',
      totalTitle:'table-view.total-titles.subscriber',
      pagination: {
        from: this.from,
        to: this.to,
        total: this.total,
      },
      columns: [
        {
          name: '',
          sort: false,
          type: 'image',
          source: 'profile_pic',
        },
        {
          name: this.translate.instant('subscribers.name'),
          sort: true,
          type: 'string',
          source: 'name',
        },
        {
          name: this.translate.instant('subscribers.school-name'),
          sort: false,
          type: 'string',
          source: 'school_name',
        },

        {
          name: this.translate.instant('subscribers.phone_no'),
          sort: true,
          type: 'string',
          source: 'phone_no',
        },

        {
          name: this.translate.instant('subscribers.home-location'),
          sort: false,
          type: 'hover',
          source: 'address',
          show_hover_data: false,
          show_hover_data_title: 'show_hover_data_title',
          hovered_data_title: 'hovered_data_title',
          hovered_data: 'home_location'
        },
      ],
      data: data,
    };
  }
  tableEventHandeler(event: any) {
    switch (event.action) {
      case 'rowClicked':
        this.rowClicked(event.target);
        break;
      case 'checkboxChanged':
        this.selectChanged(event.target);
        break;
      case 'sorting':
        this.sorting(event.target);
        break;
      // case 'linkClicked':
      //   this.linkClicked(event.target);
      //   break;
      case 'pagination':
        this.paginationHandling(event.target);
        break;
      // case 'del':
      //   this.deleteSubscribers();
      //   break;
      // case 'add':
      //   this.addSubscriber();
      //   break;
    }
  }
  sorting(target: { order: string; source: string }) {
    this.router.navigate(['layout/management/subscribers'], {
      queryParams: { order_type: target.order, order_by: target.source },
      queryParamsHandling: 'merge',
    });
  }

  rowClicked(event: any) {
    this.subscriberService.setSubscriberData(event.data , 'show');
    this.modalService.open(AddSubscriberComponent, { size: 'xl' });
    // $('#addSubscriberModal').modal('show');
    event.index;
    event.data;
  }

  selectChanged(event: any) {
    let index = event.index;
    let row = event.data;
    let checked = event.checked;
    if (checked) {
      // checked
      if (index == 'all') {
        this.selectedCheckBoxes = this.dataArray.map(
          (element: any) => element.id
        );
      } else {
        this.selectedCheckBoxes.push(row);
      }
    } else {
      // unchecked
      if (index == 'all') {
        this.selectedCheckBoxes = [];
      } else {
        let checkboxIndex = this.selectedCheckBoxes.indexOf(row);
        this.selectedCheckBoxes.splice(checkboxIndex, 1);
      }
    }
  }

  paginationHandling(event: any) {
    this.pageNumber += event.dir;
    // this.getSubscribersData();
    this.router.navigate(['layout/management/subscribers'], {
      queryParams: { page: this.pageNumber },
      queryParamsHandling: 'merge',
    });
  }
  getSubscribersData() {
    this.loading = true;
    this.subscriberService
      .getSubscribers(this.pageNumber, this.itemsPerPage, [], this.filter, this.order)
      .subscribe(
        (result) => {
          let res = result['data'].subscriptions;
          let dataArray: any = [];
          res['data'].forEach((element: Subscriber) => {
            dataArray.push({
              id: element?.id,
              home_location: element?.home_location,
              name: element?.name,
              phone_no: element?.phone_no,
              profile_pic: this.storage.imageUrl + element?.profile_pic,
              school_name: element?.school_name,
              country_code: element?.country_code,
              school_location: element?.school_location,
              school_logo: this.storage.imageUrl + element?.school_logo,
              integratable_type: element?.integratable_type,
              integratable_id: element?.integratable_id,
              company_id: element?.company_id,
              address: element?.address,
              show_hover_data_title: true,
              hovered_data_title: 'subscribers.address_hover_title',
            });
          });
          this.dataArray = dataArray;
          this.from = res['from'];
          this.to = res['to'];
          this.total = res['total'];
          this.selectedCheckBoxes = [];
          this.setTableData(dataArray);
          this.loading = false;
        },
        (error) => {
          this.errorMsgService.setMessage(error.error.Error[0]);
        }
      );
  }
  setViewType(type: string) {
    this.rowsView = type;
  }
  onSeachFn(text: any) {
    this.searchText = text;
    this.pageNumber = 1;
    this.router.navigate(['layout/management/subscribers'], {
      queryParams: { page: this.pageNumber, search: this.searchText },
      queryParamsHandling: 'merge',
    });
    // this.filter = {
    //   subscriptions: {
    //     name: text.trim().toLowerCase(),
    //     phone_no: text.trim().toLowerCase(),
    //     operator: 'or',
    //   },
    // };
    // this.getSubscribersData();
  }
  setItemsPerPage($event: any) {
    this.itemsPerPage = $event;
    this.pageNumber = 1;
    this.router.navigate(['layout/management/subscribers'], {
      queryParams: { show: this.itemsPerPage, page: this.pageNumber },
      queryParamsHandling: 'merge',
    });

    // this.getSubscribersData();
  }


}
