import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arabicNumber',
  standalone: true
})
export class ArabicNumberPipe implements PipeTransform {

  transform(value: number | string, currentLanguage: string): string {
    if (value === null || value === undefined) {
      // Return an empty string 
      return '';
    }

    if (currentLanguage === 'ar') {
      if (typeof value === 'string') {
        // If the input is a string, retain non-digit characters (e.g., ":")
        return value.replace(/\d/g, digit => {
          const arabicNumerals = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
          return arabicNumerals[parseInt(digit, 10)];
        });
      } else {
        // If the input is a number, convert it to a string first
        return value.toString().replace(/\d/g, digit => {
          const arabicNumerals = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
          return arabicNumerals[parseInt(digit, 10)];
        });
      }
    } else {
      // If the current language is not 'ar', return the original value
      return value?.toString();
    }
  }
}
