<div class="list" [ngStyle]="{ direction: currLang == 'en' ? 'ltr' : 'rtl' }" *ngIf="!itemSelected">
    <!-- (messagesSearchChange)="onSeachFn($event)" -->
    <div class="no-daily-trips" *ngIf="(tableControls.length == 0 && !loading)">
        <div class="list-empty">
            <div class="list-empty-daily-trips">
                <img src="../../../../assets/images/svg/noDailyTrips.svg" alt="">
                <span>{{'bus-tracking.no-daily-trips' | translate}}</span>
            </div>
        </div>
    </div>

    <div *ngIf="(tableControls.length != 0 && !loading)">
        <app-control-bar [listType]="'trip'" [selectedItemsPerPage]="itemsPerPage" [hideSearch]="hideSearch"
            [selectedRows]="selectedCheckBoxes" (onViewTypeChange)="setViewType($event)"
            (onChangedItemsPerPage)="setItemsPerPage($event)" [actions]="actions"
            (onEventClicked)="tableEventHandeler($event)"></app-control-bar>

        <app-table-view [loading]="loading" [controls]="tableControls" (eventHandeler)="tableEventHandeler($event)"
            [currentView]="rowsView"></app-table-view>
    </div>
</div>
<div class="container tracking-item" id="tracking-item" *ngIf="itemSelected" [class.rtl]="currLang == 'ar'">
    <div class="tracking-item-header">
        <button class="btn btn-transparent back-btn" (click)="back()">
            <img src="../../../../assets/images/svg/backBtn.svg" alt=""
                [ngStyle]="{ transform: currLang == 'en' ? '' : 'rotate(180deg)' }" />
            {{ "bus-tracking.tracking" | translate }} / {{ trip.name }}
        </button>
        <div class="date-refresh">
            <span>
                {{ "bus-tracking.last-updated" | translate }}
                :
                <span>
                    <!-- {{ lastUpdated | date: "mediumTime" }} -->
                    {{convertDateTime(lastUpdated , false)}}
                </span></span>
            <button class="btn btn-primary refresh-btn" (click)="refresh()">
                <img src="../../../../assets/images/svg/refresh-line.svg" alt="" />

                {{ "bus-tracking.refresh" | translate }}
            </button>
        </div>
    </div>


    <div class="map-container">
        <div class="stepper-wrapper">
            <div *ngFor="let stop of stepperPoints; index as i" class="stepper-item">
                <div class="step-time">{{convertDateTime(stop?.stopTime , true)}}</div>
                <!-- (mouseenter)="stop.showStudents = true" (mouseleave)="stop.showStudents = false" -->
                <div class="step-counter" (click)="stop.showStudents = !stop.showStudents">
                    <img style="width: 24.5px; height: 24.5px;" [src]="stop.stopIcon.icon" alt="" />
                </div>
                <div class="step-name">{{"bus-tracking.stop" | translate }} {{i+1 |arabicNumber: currLang}}</div>
                <div class="students-container" style="width: 24.5px; height: 24.5px; position: relative;">
                    <div *ngIf="stop.showStudents" class="step-students">
                        <div class="header">
                            <span> {{"bus-tracking.students-names" | translate }} </span>
                            <span (click)="stop.showStudents = !stop.showStudents" class="close"> x </span>
                        </div>
    
                        <!-- <div class="body">
                  <div *ngFor="let student of stop.studentsNames; index as j" class="student-name">
                    <span>{{formatNumber(j+1)}}-{{student?.subscription_histroy?.subscription_name}}</span>
                  </div>
                </div> -->
                        <ul class="body">
                            <ng-container *ngFor="let name of stop.studentNames; index as j" class="student-name">
                                <li class="student-name">
                                    {{name}}
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- agm-map -->
        <google-map #myGoogleMap height="100%" width="100%" [zoom]="11" [center]="center" [options]="options" (mapInitialized)="onMapInitialized($event)">
            <!-- School Points Markers -->
            <map-marker *ngFor="let marker of schoolPoints" [position]="marker?.location" [options]="marker?.stopIcon">
            </map-marker>
            <!-- Students Points Markers -->
            <map-marker *ngFor="let marker of studentPoints; index as studentIndex" [position]="marker?.location"
                [options]="marker?.stopIcon">
                <!-- [label]="studentIndex+''" -->
            </map-marker>
            <!-- Bus Marker  -->
            <map-marker [position]="{lat:busLat , lng:busLng}" [options]="busIcon">
            </map-marker>
            <!-- to draw a polyline for bus path with different marker for first point  -->
            <!-- <map-marker *ngIf="busPath.length > 0" [position]="busPath[0]" [options]="firstBusPoint"></map-marker> -->
            <!-- <map-polyline [path]="busPath" [options]="polylineOptions"></map-polyline> -->
            <!-- <map-directions-renderer *ngIf="(directionsResults$ | async) as directionsResults"
                            [directions]="directionsResults" [options]="renderOptions"></map-directions-renderer> -->
        </google-map>
        <div *ngIf="loading" class="loading">
            <mat-spinner *ngIf="true" mode="indeterminate"></mat-spinner>
        </div>
    </div>
    <div class="map-legend">
        <div class="legend">
            <div class="legend-icon">
                <img  src="../../../../assets/images/svg/visitedStop.svg" alt="" />
            </div>
            <div class="legend-name">
                <span>{{"bus-tracking.stop-visited" | translate }}</span>

            </div>
        </div>
        <div class="legend">
            <div class="legend-icon">
                <img  src="../../../../assets/images/svg/missedStop.svg" alt="" />
            </div>
            <div class="legend-name">
                <span>{{"bus-tracking.stop-skipped" | translate }}</span>

            </div>
        </div>
        <div class="legend">
            <div class="legend-icon">
                <img  src="../../../../assets/images/svg/notVisitedStop.svg" alt="" />
            </div>
            <div class="legend-name">
                <span>{{"bus-tracking.stop-not-visited" | translate }}</span>
            </div>
        </div>
        <div class="legend">
            <div class="legend-icon">
                <img  src="../../../../assets/images/svg/school.svg" alt="" />
            </div>
            <div class="legend-name">
                {{"bus-tracking.stop-school" | translate }}
            </div>
        </div>
    </div>
</div>