import { Component } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { GlobalService } from '../../services/global.service';
import { Router, RouterModule } from '@angular/router';
import {Menu} from '../../../models/menu.model'
import { MenuService } from '../../services/menu.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-side-nav',
  standalone: true,
  imports: [CommonModule,TranslateModule,SvgIconComponent,RouterModule],
  templateUrl: './side-nav.component.html',
  styleUrl: './side-nav.component.scss'
})
export class SideNavComponent {
  currLang!: string;
  activeModule!: string;
  menu:Menu[]=[]
  constructor(
    private translate: TranslateService,
    private globalService: GlobalService,
    private router: Router,
    private menuService:MenuService
  ) {
    this.globalService.getLang().subscribe((lng) => {
      this.currLang = lng ? lng : "en";
      this.translate.use(this.currLang);
    });
    this.translate.onLangChange.subscribe((res) => {
      this.currLang = res.lang ? res.lang : "en";
      this.translate.use(this.currLang);
    });
  }

  ngOnInit(): void {
    this.menu=this.menuService.getMenu()
    this.activeModule = window.location.pathname.split('/')[2];

    this.router.events.subscribe((res: any) => {
      if (res.url && res.url.split('/')[2] != this.activeModule) {
        this.activeModule = res.url.split('/')[2]?.split('?')[0];
      }
    });
  }

}
