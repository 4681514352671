import { Component } from '@angular/core';
import { UserTripHistory } from '../../../models/user-trip-history.model';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../../shared/services/global.service';
import { ReportsService } from '../../management/_services/reports.service';
import { ErrorMsgService } from '../../../shared/services/error-msg.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ExportExcelService } from '../../management/_services/export-excel.service';
import { PrintService } from '../../management/_services/print.service';
import moment from 'moment';
import { ReportsHeaderComponent } from '../reports-header/reports-header.component';
import { ControlBarComponent } from '../../../shared/components/control-bar/control-bar.component';
import { TableViewComponent } from '../../../shared/components/table-view/table-view.component';
import { CommonModule } from '@angular/common';
import { ExportServiceService } from '../../../shared/services/export-service.service';

@Component({
  selector: 'app-user-trip-history',
  standalone: true,
  imports: [ReportsHeaderComponent , ControlBarComponent , TableViewComponent , CommonModule],
  templateUrl: './user-trip-history.component.html',
  styleUrl: './user-trip-history.component.scss'
})
export class UserTripHistoryComponent {
  tableControls: any = { data: [] };
  searchText: string = '';
  pageNumber: number = 1;
  itemsPerPage: number = 25;
  currLang: string = 'en';
  loading!: boolean;
  rowsView: string = 'list';
  filter = {};
  from!: number;
  to!: number;
  total!: number;
  userName: any;
  userId: number;
  from_date: any;
  to_date: any;
  dataArray: UserTripHistory[] = [];
  routeQueryParams = {}
  constructor(
    private translate: TranslateService,
    private globalService: GlobalService,
    private reportsService: ReportsService,
    private errorMsgService: ErrorMsgService,
    private router: Router,
    private _ActivatedRoute: ActivatedRoute,
    private exportExcel: ExportExcelService,
    private printService: PrintService,
    private exportService:ExportServiceService

  ) {
    this.userId = _ActivatedRoute.snapshot.params['id'];
    this.globalService.getLang().subscribe((lng) => {
      this.currLang = lng ? lng : "en";
      this.translate.use(this.currLang);
    });

    this.translate.onLangChange.subscribe((res) => {
      this.currLang = res.lang ? res.lang : "en";
      this.translate.use(this.currLang);
      this.setTableData(this.dataArray);
    });
  }

  ngOnInit(): void {
    this.userName = localStorage.getItem('userNameTripHistory');
    this._ActivatedRoute.queryParams.subscribe((params) => {
      this.pageNumber = +params['page'] || 1;
      this.from_date = params['from']
      this.to_date = params['to'];
      this.itemsPerPage = params['show'] || 25;
      this.routeQueryParams = params
      this.getUserTripHistory();

    })
  }

  setTableData(data: UserTripHistory[]) {
    this.tableControls = {
      selection: false,
      paging: true,
      view: this.rowsView,
      title:'table-view.titles.trip-history',
      totalTitle:'table-view.total-titles.trip-history',
      pagination: {
        from: this.from,
        to: this.to,
        total: this.total,
      },
      columns: [
        {
          name: this.translate.instant('reports.trip-history.trip-date'),
          sort: false,
          type: 'string',
          source: 'trip_date',
        },
        {
          name: this.translate.instant('reports.trip-history.type'),
          sort: false,
          type: 'string',
          source: 'trip_type',
        },
        {
          name: this.translate.instant('reports.trip-history.approach-time'),
          sort: false,
          type: 'date_time',
          source: 'approach_time',
        },
        {
          name: this.translate.instant('reports.trip-history.notified-parent'),
          sort: false,
          type: 'date_time',
          source: 'notified_parent',
        },
        {
          name: this.translate.instant('reports.trip-history.pickup-time'),
          sort: false,
          type: 'date_time',
          source: 'pickup_time',
        },
        {
          name: this.translate.instant('reports.trip-history.dropoff-time'),
          sort: false,
          type: 'date_time',
          source: 'dropoff_time',
        },
        {
          name: this.translate.instant('reports.trip-history.attendance'),
          sort: false,
          type: 'attendanceStatus',
          source: 'attendance',
        }
      ],
      data: data,
    };
  }

  getUserTripHistory() {
    this.loading = true;
    this.reportsService
      .getUserTripHistoryById(this.userId, this.pageNumber, this.itemsPerPage, this.from_date, this.to_date)
      .subscribe(
        (result) => {
          let res = result['data'].daily_trip_passengers;
          this.dataArray = res['data'].map((element: any) => {
            return {
              id: element?.id,
              trip_date: element?.trip_date,
              trip_type: element?.type,
              approach_time: element?.approach_time,
              notified_parent: element?.arrived_time,
              pickup_time: element?.pick_up_time,
              dropoff_time: element?.drop_off_time,
              attendance: element?.attendance
            };
          });
          this.from = res['from'];
          this.to = res['to'];
          this.total = res['total'];
          this.setTableData(this.dataArray);
          this.loading = false;
        },
        (error) => {
          this.errorMsgService.setMessage(error.error.Error[0])

        }
      );
  }

  eventHandeler(event: any) {
    switch (event.action) {
      case 'pagination':
        this.paginationHandling(event.target);
        break;
      case 'print':
        this.print();
        break;
      case 'export':
        this.exportEcxel();
        break;
      case 'back':
        this.back();
        break;
    }
  }


  onSeachFn(text: any) {
    this.searchText = text;
    this.pageNumber = 1;
    this.filter = {
      trips: {
        name: text.trim().toLowerCase(),
        operator: 'or',
      },
    };
    this.router.navigate(['layout/user-history/' + this.userId], {
      queryParams: { page: this.pageNumber, search: this.searchText },
      queryParamsHandling: 'merge',
    });
    // this.getUserTripHistory();
  }

  setItemsPerPage($event: any) {
    this.itemsPerPage = $event;
    this.pageNumber = 1;
    this.router.navigate(['layout/user-history/' + this.userId], {
      queryParams: { page: this.pageNumber, show: this.itemsPerPage },
      queryParamsHandling: 'merge',
    });
    // this.getUserTripHistory();
  }

  paginationHandling(event: any) {
    this.pageNumber += event.dir;
    this.router.navigate(['layout/user-history/' + this.userId], {
      queryParams: { page: this.pageNumber },
      queryParamsHandling: 'merge',
    });
    // this.getUserTripHistory();
  }

  back() {
    this.reportsService.getLastTripDetailsQueryParams().subscribe(res => {
      if (res) {
        this.router.navigate([`layout/trip-details/${localStorage.getItem('tripDetailsId')}`], {
          queryParams: res
        });
      } else {

        this.router.navigate([`layout/trip-details/${localStorage.getItem('tripDetailsId')}`]);
      }
    })

  }

  print() {
    this.printService.printPdf('.table-container');
  }

  // exportEcxel() {
  //   this.loading = true;
  //   this.reportsService.exportUserTripHistory(this.userId, this.from_date, this.to_date).subscribe((res:any) => {
  //     this.loading = false;
  //     let date: any = this.from_date && this.to_date ? `${this.from_date} to ${this.to_date}` : moment(new Date()).format('YYYY-MM-DD')

  //     this.exportExcel.downloadFile(res, this.userName + date + 'Reports');
  //   });
  //   // let valuesToBeExported:any = [];
  //   // if(this.currLang == 'en'){
  //   //   valuesToBeExported = this.dataArray.map(data => {
  //   //     return {
  //   //       'Trip Date': data['trip_date'],
  //   //       'Type': data['trip_type'],
  //   //       'Approach Time': data['approach_time'],
  //   //       'Notified Parent': data['notified_parent'],
  //   //       'Pick Up Time': data['pickup_time'],
  //   //       'Drop Off Time': data['dropoff_time'],
  //   //       'Attendance': data['attendance']
  //   //     }
  //   //   })
  //   // }
  //   // else if(this.currLang == 'ar'){
  //   //   valuesToBeExported = this.dataArray.map(data => {
  //   //     return {
  //   //       'تاريخ الرحلة': data['trip_date'],
  //   //       'النوع': data['trip_type'],
  //   //       'وقت الاقتراب	': data['approach_time'],
  //   //       'وقت إعلام الوالد': data['notified_parent'],
  //   //       'وقت المغادرة	': data['pickup_time'],
  //   //       'وقت النزول	': data['dropoff_time'],
  //   //       'الحضور': data['attendance']
  //   //     }
  //   //   })
  //   // }

  //   // this.exportExcel.exportAsExcelFile(valuesToBeExported,this.userName+'Reports')
  // }

  exportEcxel(){
    const params: any = { subscription_id: this.userId };

    if (this.from_date) {
      params.from_date = this.from_date;
    }
    
    if (this.to_date) {
      params.to_date = this.to_date;
    }
    this.exportService.exportFile(
      'backoffice/report/exportDTPForPassenger',
      'backoffice/Exports/getExportById',
      params
    )
  }

  setDate(event: any, flag: string) {
    switch (flag) {
      case 'from':
        this.from_date = event;
        this.from_date = this.from_date == undefined || this.from_date == '' ? null : this.from_date;
        break;
      case 'to':
        this.to_date = event;
        this.to_date = this.to_date == undefined || this.to_date == '' ? null : this.to_date;
        break;
    }
    if ((this.from_date != null && this.to_date != null) || (this.from_date == null && this.to_date == null)) {
      this.router.navigate(['layout/user-history/' + this.userId], {
        queryParams: { from: this.from_date, to: this.to_date },

        queryParamsHandling: 'merge',
      });
      // this.getUserTripHistory();
    }
  }

}
