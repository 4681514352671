import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Trip } from '../../../../models/trip.model';
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../../../../shared/services/global.service';
import { ErrorMsgService } from '../../../../shared/services/error-msg.service';
import { DriverService } from '../../_services/driver.service';
import { ArabicNumberPipe } from "../../../../shared/pipes/arabic-number.pipe";

@Component({
    selector: 'app-add-driver',
    standalone: true,
    templateUrl: './add-driver.component.html',
    styleUrl: './add-driver.component.scss',
    imports: [CommonModule, TranslateModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, ArabicNumberPipe]
})
export class AddDriverComponent {
  driverForm!: UntypedFormGroup;
  currLang!: string;
  modalTitle!: string;
  formFlag: string = 'add';
  loading!: boolean;
  fieldsReadOnly = false;
  id!: number;
  image: any;
  imageFile: any;
  selectedImage: any;

  trips: Trip[] = [];
  @ViewChild('saveBtn', { static: false }) saveBtn!: ElementRef;

  constructor(
    private driverService: DriverService,
    private translate: TranslateService,
    public activeModal: NgbActiveModal,
    private globalService: GlobalService,
    private errorMsgService: ErrorMsgService
  ) {
    this.globalService.getLang().subscribe((lng) => {
      this.currLang = lng ? lng : "en";
      this.translate.use(this.currLang);
    });

    this.translate.onLangChange.subscribe((res) => {
      this.currLang = res.lang ? res.lang : "en";
      this.translate.use(this.currLang);
    });
  }
  ngOnInit(): void {

    this.driverForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      national_id: new UntypedFormControl(''),
      phone_no: new UntypedFormControl('', [Validators.required, Validators.pattern("[0-9]*")]),

      username: new UntypedFormControl(''),
      password: new UntypedFormControl(''),
    });
    this.driverService.getDriverData().subscribe((res) => {
      if (res?.driver != null) {
        this.formFlag = 'view';

        this.fieldsReadOnly = true;
        this.id = res.driver.id;
        this.driverForm.controls['username'].setValue(res.driver.username);
        // this.image = environment.img_url + res.driver.image_path;

        this.driverForm.controls['name'].setValue(res.driver.name);
        this.driverForm.controls['phone_no'].setValue(res.driver.phone_no);
        this.driverForm.controls['national_id'].setValue(
          res.driver.national_id
        );
        this.trips = res.driver.trips;
      }
    });
    this.setValidators();
  }

  setValidators() {
    const usernameControl:any = this.driverForm.get('username');
    const passwordControl:any = this.driverForm.get('password');

    if (this.formFlag === 'add') {
      usernameControl.setValidators([Validators.required]);
      passwordControl.setValidators([Validators.required]);
    } else {
      // to clear the validation in case of edit as the fields are hidden in edit view , so the save btn isn't disabled
      usernameControl.clearValidators();
      passwordControl.clearValidators();
    }

    usernameControl.updateValueAndValidity();
    passwordControl.updateValueAndValidity();
  }
  closeModal() {
    this.activeModal.close('Close click');
  }
  getImage(url: any) {
    // return environment.img_url + url;
  }

  getValidationMessage(control: string) {
    let errorMsg;
    if (this.driverForm.controls[control].errors) {
      const fieldName = this.translate.instant(
        'drivers.add-driver-modal.' + control
      );
      const requiredText = this.translate.instant(
        'drivers.add-driver-modal.required'
      );
      const invalidText = this.translate.instant(
        'drivers.add-driver-modal.invalid'
      );
      errorMsg =
        this.driverForm.controls[control]?.errors &&
          this.driverForm.controls[control]?.errors?.['required']
          ? `${fieldName + requiredText}`
          : `${fieldName + invalidText}`;
    }
    return errorMsg;
  }

  // image upload
  changeDriverProfile() {
    let fileUpload: any;
    fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.onclick = function () {
      fileUpload.value = null;
    };
    fileUpload.onchange = () => {
      this.selectedImage = null;
      // tslint:disable-next-line:prefer-for-of
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.imageFile = file;
      }
    };

    fileUpload.click();
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file: File = input.files[0];
      this.selectedImage = file;
  
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        this.image = e.target?.result;
      };
      reader.readAsDataURL(this.selectedImage);
    }
  }
  editDriver() {
    this.formFlag = 'edit';
    this.fieldsReadOnly = false;
  }

  saveDriver() {

    const body = this.driverForm.value;
    let sub;

    if (this.formFlag == 'add') {
      sub = this.driverService.addDriver(body)
    } else {
      delete body.password;
      sub = this.driverService.editDriver({ ...body, driver_id: this.id })
    }
    sub.subscribe(
      (res) => {
        this.driverService.setLoadDriversData(true);
        this.closeModal()
      },
      (error) => {
        this.errorMsgService.setMessage(error.error.Error[0]);
      }
    );
  }
  deactive() { }
  convertTime(time: any) {
    return this.globalService.convertTime(time);
  }

  ngOnDestroy() {
    this.driverService.setDriverData(null, 'add')
  }

}
