<div class="modal-container" [class.rtl]="currLang == 'ar'">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">
                {{ "areas.add-area-modal.area" | translate }}
                <!-- <span *ngIf="formArea.value.name">( {{ formArea.value.name }} )</span> -->
            </h5>
            <div class="header-btns">
                <svg (click)="closeModal()" xmlns="http://www.w3.org/2000/svg" width="14" height="16"
                    viewBox="0 0 14 16" fill="none">
                    <path d="M6.94989 8L12.8998 15" stroke="#707070" stroke-width="2" stroke-linecap="round" />
                    <path d="M6.94977 8L0.999873 15" stroke="#707070" stroke-width="2" stroke-linecap="round" />
                    <path d="M6.94989 8L12.8998 1" stroke="#707070" stroke-width="2" stroke-linecap="round" />
                    <path d="M6.94977 8L0.999873 1" stroke="#707070" stroke-width="2" stroke-linecap="round" />
                </svg>
            </div>

        </div>
        <div class="modal-body">
            <form [id]="formFlag === 'edit' ? 'areaForm' : null"
                [ngStyle]="{ 'text-align': currLang == 'en' ? 'left' : 'right' }" [formGroup]="formArea">
                <label class="section-header">{{
                    "areas.add-area-modal.area-info" | translate
                    }}</label>
                <!-- view state -->
                <div class="row view-info" *ngIf="formFlag == 'view'">
                    <div class="info-details-wrapper">
                        <div class="info-item">
                            <div class="label">{{ "areas.add-area-modal.area-name" | translate }} :</div>
                            <div class="value">{{ formArea.get('name')?.value }}</div>
                        </div>
                        <div class="info-item">
                            <div class="label">{{ "areas.add-area-modal.code" | translate }} :</div>
                            <div class="value">{{ formArea.get('code')?.value }}</div>
                        </div>
                    </div>
                    <div class="edit-btn-wrapper">
                        <button class="btn btn-primary edit-btn" (click)="editArea()">
                            {{ "areas.edit" | translate }}
                        </button>
                    </div>
                </div>
                <!-- add/edit state -->
                <div class="row" style="margin-top: 1rem;" *ngIf="formFlag != 'view'">
                    <div class="col-lg-6">
                        <mat-form-field class="w-100" appearance="outline" dir="ltr" *ngIf="formFlag != 'view'">
                            <mat-label>{{
                                "areas.add-area-modal.area-name" | translate
                                }}</mat-label>
                            <input matInput formControlName="name" [readonly]="fieldsReadOnly" />
                            <mat-error>{{ getValidationMessage("name") }}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                        <mat-form-field class="w-100" appearance="outline" dir="ltr" *ngIf="formFlag != 'view'">
                            <mat-label>{{
                                "areas.add-area-modal.code" | translate
                                }}</mat-label>
                            <input matInput formControlName="code" [readonly]="fieldsReadOnly" />
                            <mat-error>{{ getValidationMessage("code") }}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </form>

            <!-- assigned trips -->
            <div class="trips" *ngIf="formFlag == 'view'">
                <label class="section-header">{{
                    "areas.add-area-modal.trips" | translate
                    }} ({{trips.length | arabicNumber:currLang}})</label>

                <div class="row trips-content" *ngIf="trips.length > 0">
                    <div class="col-lg-5 trip-item" *ngFor="let trip of trips">
                        <div class="d-flex flex-column justify-content-between">
                            <h5 class="name">{{ trip?.name }}</h5>
                            <h6 class="from-to">
                                {{ "trip-ways."+trip?.way | translate }}

                            </h6>
                        </div>
                        <div>
                            <h3 class="time">{{ convertTime(trip?.start_time!) }}</h3>
                        </div>
                    </div>
                </div>
                <div class="row no-trips" *ngIf="trips.length == 0">
                    <h4>{{ "areas.add-area-modal.no-trips" | translate }}</h4>
                </div>
            </div>
        </div>
        <div class="modal-footer" [class.none]="formFlag == 'view'">
            <div class="btns-wrapper">
                <button type="button" (click)="closeModal()" class="btn btn-primary cancel-btn">
                    {{ "areas.add-area-modal.cancel" | translate }}
                </button>
                <button type="button" class="btn btn-primary save-btn" [disabled]="formArea.invalid" #saveBtn
                    (click)="saveArea()">
                    {{ "areas.add-area-modal.save" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>