<div class="modal-container" [class.rtl]="currLang == 'ar'">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">
                {{ "busses.add-bus-modal.bus" | translate }}
            </h5>
            <div class="header-btns">
                <svg (click)="closeModal()" xmlns="http://www.w3.org/2000/svg" width="14" height="16"
                    viewBox="0 0 14 16" fill="none">
                    <path d="M6.94989 8L12.8998 15" stroke="#707070" stroke-width="2" stroke-linecap="round" />
                    <path d="M6.94977 8L0.999873 15" stroke="#707070" stroke-width="2" stroke-linecap="round" />
                    <path d="M6.94989 8L12.8998 1" stroke="#707070" stroke-width="2" stroke-linecap="round" />
                    <path d="M6.94977 8L0.999873 1" stroke="#707070" stroke-width="2" stroke-linecap="round" />
                </svg>
            </div>
        </div>
        <div class="modal-body">
            <form [id]="formFlag === 'edit' ? 'busForm' : null"
                [ngStyle]="{ 'text-align': currLang == 'en' ? 'left' : 'right' }" [formGroup]="busForm">
                <label class="section-header">{{
                    "busses.add-bus-modal.bus-info" | translate
                    }}</label>

                <!-- view state -->
                <div class="row view-info" *ngIf="formFlag == 'view'">
                    <div class="info-details-wrapper">
                        <div class="info-item">
                            <div class="label">{{ "busses.add-bus-modal.name" | translate }} :</div>
                            <div class="value">{{ busForm.get('name')?.value }}</div>
                        </div>
                        <div class="info-item">
                            <div class="label">{{ "busses.add-bus-modal.plate_no" | translate }} :</div>
                            <div class="value">{{ busForm.get('plate_no')?.value }}</div>
                        </div>
                        <div class="info-item">
                            <div class="label">{{ "busses.add-bus-modal.brand" | translate }} :</div>
                            <div class="value">{{ busForm.get('brand')?.value }}</div>
                        </div>
                        <div class="info-item">
                            <div class="label">{{ "busses.add-bus-modal.capacity" | translate }} :</div>
                            <div class="value">{{ busForm.get('capacity')?.value }}</div>
                        </div>
                    </div>
                    <div class="edit-btn-wrapper">
                        <button class="btn btn-primary edit-btn" (click)="editBus()">
                            {{ "busses.edit" | translate }}
                        </button>
                    </div>
                </div>

                <!-- add/edit state -->
                <div class="row" style="margin-top: 1rem;" *ngIf="formFlag != 'view'">
                    <div class="col-lg-6">
                        <mat-form-field class="w-100" appearance="outline" dir="ltr">
                            <mat-label>{{
                                "busses.add-bus-modal.name" | translate
                                }}</mat-label>
                            <input matInput formControlName="name" [readonly]="fieldsReadOnly" />
                            <mat-error>{{ getValidationMessage("name") }}</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-6">
                        <mat-form-field class="w-100" appearance="outline" dir="ltr">
                            <mat-label>{{
                                "busses.add-bus-modal.plate_no" | translate
                                }}</mat-label>
                            <input matInput formControlName="plate_no" [readonly]="fieldsReadOnly" />
                            <mat-error>{{ getValidationMessage("plate_no") }}</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-6">
                        <mat-form-field class="w-100" appearance="outline" dir="ltr">
                            <mat-label>{{
                                "busses.add-bus-modal.brand" | translate
                                }}</mat-label>
                            <input matInput formControlName="brand" [readonly]="fieldsReadOnly" />
                            <mat-error>{{ getValidationMessage("brand") }}</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-6">
                        <mat-form-field class="w-100" appearance="outline" dir="ltr">
                            <mat-label>{{
                                "busses.add-bus-modal.capacity" | translate
                                }}</mat-label>
                            <input matInput formControlName="capacity" [readonly]="fieldsReadOnly" />
                            <mat-error>{{ getValidationMessage("capacity") }}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </form>

            <!-- assigned trips -->
            <div class="trips" *ngIf="formFlag == 'view'">
                <label class="section-header">{{
                    "busses.add-bus-modal.trips" | translate
                    }} ({{trips.length | arabicNumber:currLang}})</label>

                <div class="row trips-content" *ngIf="trips.length > 0">
                    <div class="col-lg-5 trip-item" *ngFor="let trip of trips">
                        <div class="d-flex flex-column justify-content-between">
                            <h5 class="name">{{ trip?.name }}</h5>
                            <h6 class="from-to">
                                {{ "trip-ways." + trip?.way | translate }}
                            </h6>
                        </div>
                        <div>
                            <h3 class="time">{{ convertTime(trip?.start_time) }}</h3>
                        </div>
                    </div>
                </div>
                <div class="row no-trips" *ngIf="trips.length == 0">
                    <h4>{{ "areas.add-area-modal.no-trips" | translate }}</h4>
                </div>
            </div>
        </div>

        <div class="modal-footer" [class.none]="formFlag == 'view'">
            <div class="btns-wrapper">
                <button type="button" (click)="closeModal()" class="btn btn-primary cancel-btn">
                    {{ "busses.add-bus-modal.cancel" | translate }}
                </button>
                <button type="button" class="btn btn-primary save-btn" [disabled]="busForm.invalid" #saveBtn
                    (click)="saveBus()">
                    {{ "busses.add-bus-modal.save" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>