<div class="control-tab">
    <div class="d-flex">
        <ng-container *ngIf="!hideRows">
            <div class="dropdown hide" (click)="toggleItemsPerPageDivFn()" appDropdown>
                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="show-text">{{ "control-bar.show" | translate }}</span>
                    {{ selectedItemsPerPage | arabicNumber:currLang }}
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton"
                    [ngClass]="{ show: toggleItemsPerPageDiv }">
                    <ng-container *ngFor="let item of itemsPerPage">
                        <a (click)="changeRowsNumber(item)" class="dropdown-item">
                        {{item | arabicNumber:currLang}}</a>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <div class="search" [class.w-100]="searchFullWidth" *ngIf="!hideSearch">
            <input [value]="searchVal" (keyup)="onKeyup($event)" class="search-input" type="text" autocomplete="off"
                placeholder="{{ 'control-bar.search' | translate }}" />
        </div>

        <div class="date-filter-container" *ngIf="dateFilter">
            <span> {{ "control-bar.date-from" | translate }} : </span>
            <mat-form-field appearance="outline">
                <input [value]="from_date" id="fromDate" (change)="emitDate($event, 'from')" matInput type="date"
                    [max]="to_date" (keydown)="$event.preventDefault()" />
            </mat-form-field>
            <span> {{ "control-bar.date-to" | translate }} : </span>
            <mat-form-field appearance="outline">
                <input [value]="to_date" id="toDate" (change)="emitDate($event, 'to')" matInput type="date"
                    [min]="from_date" (keydown)="$event.preventDefault()" />
            </mat-form-field>
        </div>

        <div class="show-style-control style-control" *ngIf="showToggleView">
            <div matTooltip="{{ 'control-bar.compact-view' | translate }}">
                <!-- normal view -->
                <svg (click)="setViewType('list')" id="normal-svg"
                    [attr.class]="activatedType == 'list' ? 'active' : ' '" xmlns="http://www.w3.org/2000/svg"
                    width="21.474" height="17" viewBox="0 0 21.474 17">
                    <g id="list_1_" data-name="list (1)" transform="translate(0 -53.333)">
                        <g id="Group_5111" data-name="Group 5111" transform="translate(5.82 55.175)">
                            <g id="Group_5110" data-name="Group 5110">
                                <path id="Path_5426" data-name="Path 5426"
                                    d="M164.527,96H149.793a.46.46,0,1,0,0,.921h14.733a.46.46,0,1,0,0-.921Z"
                                    transform="translate(-149.333 -96)" fill="#707070" />
                            </g>
                        </g>
                        <g id="Group_5113" data-name="Group 5113" transform="translate(5.82 61.373)">
                            <g id="Group_5112" data-name="Group 5112">
                                <path id="Path_5427" data-name="Path 5427"
                                    d="M164.527,245.333H149.793a.46.46,0,1,0,0,.921h14.733a.46.46,0,1,0,0-.921Z"
                                    transform="translate(-149.333 -245.333)" fill="#707070" />
                            </g>
                        </g>
                        <g id="Group_5115" data-name="Group 5115" transform="translate(5.82 67.571)">
                            <g id="Group_5114" data-name="Group 5114">
                                <path id="Path_5428" data-name="Path 5428"
                                    d="M164.527,394.667H149.793a.46.46,0,1,0,0,.921h14.733a.46.46,0,1,0,0-.921Z"
                                    transform="translate(-149.333 -394.667)" fill="#707070" />
                            </g>
                        </g>
                        <g id="Group_5117" data-name="Group 5117" transform="translate(0 65.729)">
                            <g id="Group_5116" data-name="Group 5116">
                                <path id="Path_5429" data-name="Path 5429"
                                    d="M2.3,352a2.3,2.3,0,1,0,2.3,2.3A2.3,2.3,0,0,0,2.3,352Zm0,3.683A1.381,1.381,0,1,1,3.683,354.3,1.383,1.383,0,0,1,2.3,355.683Z"
                                    transform="translate(0 -352)" fill="#707070" />
                            </g>
                        </g>
                        <g id="Group_5119" data-name="Group 5119" transform="translate(0 59.531)">
                            <g id="Group_5118" data-name="Group 5118">
                                <path id="Path_5430" data-name="Path 5430"
                                    d="M2.3,202.667a2.3,2.3,0,1,0,2.3,2.3A2.3,2.3,0,0,0,2.3,202.667Zm0,3.683a1.381,1.381,0,1,1,1.381-1.381A1.383,1.383,0,0,1,2.3,206.35Z"
                                    transform="translate(0 -202.667)" fill="#707070" />
                            </g>
                        </g>
                        <g id="Group_5121" data-name="Group 5121" transform="translate(0 53.333)">
                            <g id="Group_5120" data-name="Group 5120">
                                <path id="Path_5431" data-name="Path 5431"
                                    d="M2.3,53.333a2.3,2.3,0,1,0,2.3,2.3A2.3,2.3,0,0,0,2.3,53.333Zm0,3.683a1.381,1.381,0,1,1,1.381-1.381A1.383,1.383,0,0,1,2.3,57.016Z"
                                    transform="translate(0 -53.333)" fill="#707070" />
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
            <div matTooltip="{{ 'control-bar.list-view' | translate }}">
                <!-- compact view -->
                <svg (click)="setViewType('compact')" [attr.class]="activatedType == 'compact' ? 'active' : ' '"
                    xmlns="http://www.w3.org/2000/svg" width="20.395" height="14.488" viewBox="0 0 20.395 14.488">
                    <g id="Group_5124" data-name="Group 5124" transform="translate(6308 -176)">
                        <g id="Group_5111" data-name="Group 5111" transform="translate(-6308 176)">
                            <g id="Group_5110" data-name="Group 5110">
                                <path id="Path_5426" data-name="Path 5426"
                                    d="M169.128,96H149.933a.6.6,0,0,0,0,1.2h19.195a.6.6,0,0,0,0-1.2Z"
                                    transform="translate(-149.333 -96)" fill="#707070" />
                            </g>
                        </g>
                        <g id="Group_5113" data-name="Group 5113" transform="translate(-6308 179.322)">
                            <g id="Group_5112" data-name="Group 5112">
                                <path id="Path_5427" data-name="Path 5427"
                                    d="M169.128,245.333H149.933a.6.6,0,0,0,0,1.2h19.195a.6.6,0,0,0,0-1.2Z"
                                    transform="translate(-149.333 -245.333)" fill="#707070" />
                            </g>
                        </g>
                        <g id="Group_5115" data-name="Group 5115" transform="translate(-6308 182.644)">
                            <g id="Group_5114" data-name="Group 5114">
                                <path id="Path_5428" data-name="Path 5428"
                                    d="M169.128,394.667H149.933a.6.6,0,0,0,0,1.2h19.195a.6.6,0,0,0,0-1.2Z"
                                    transform="translate(-149.333 -394.667)" fill="#707070" />
                            </g>
                        </g>
                        <g id="Group_5122" data-name="Group 5122" transform="translate(-6308 185.967)">
                            <g id="Group_5112-2" data-name="Group 5112">
                                <path id="Path_5427-2" data-name="Path 5427"
                                    d="M169.128,245.333H149.933a.6.6,0,0,0,0,1.2h19.195a.6.6,0,0,0,0-1.2Z"
                                    transform="translate(-149.333 -245.333)" fill="#707070" />
                            </g>
                        </g>
                        <g id="Group_5123" data-name="Group 5123" transform="translate(-6308 189.289)">
                            <g id="Group_5114-2" data-name="Group 5114">
                                <path id="Path_5428-2" data-name="Path 5428"
                                    d="M169.128,394.667H149.933a.6.6,0,0,0,0,1.2h19.195a.6.6,0,0,0,0-1.2Z"
                                    transform="translate(-149.333 -394.667)" fill="#707070" />
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        </div>
    </div>
    <div class="actions">
        <span *ngFor="let act of actions">
            <button *ngIf="act.type == 'add' || (selectedRows.length > 0 && act.type == 'del')" class="btn act-btn"
                [ngClass]="{'btn-add': act.type == 'add','btn-del': act.type == 'del'}"
                (click)="eventHandler(act.type)">
                <span>
                    <!-- button -->
                    <svg *ngIf="act.type == 'add'" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none">
                        <path
                            d="M12 9V15M15 12H9M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
                            stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <!-- <svg *ngIf="act.type == 'add'" width="13" height="13" viewBox="0 0 13 13" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.5 0.25C3.04813 0.25 0.25 3.04813 0.25 6.5C0.25 9.95187 3.04813 12.75 6.5 12.75C9.95187 12.75 12.75 9.95187 12.75 6.5C12.75 3.04813 9.95187 0.25 6.5 0.25ZM9.625 7.125H7.125V9.625H5.875V7.125H3.375V5.875H5.875V3.375H7.125V5.875H9.625V7.125Z"
                            fill="white" />
                    </svg> -->
                    <img *ngIf="act.type == 'del'" src="../../../../../assets/images/svg/deleteWhite.svg" />
                    <span class="btn-name"> {{ act.name | translate }} </span>
                </span>
            </button>
        </span>
    </div>
</div>