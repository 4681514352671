import { Injectable } from '@angular/core';
import { Firestore, collection, collectionData, doc, setDoc, updateDoc, deleteDoc, docData } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { addDoc } from 'firebase/firestore';
import { environment } from '../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FireStoreService {
  tripsLocationCollType: string = environment.production ? '/trips_locations' : '/trips_locations_dev';
  constructor(private firestore: Firestore) { }
  // Add a new document to the 'locations' collection
  async addItem(item: any): Promise<void> {
    const itemsCollection = collection(this.firestore, this.tripsLocationCollType);
    await addDoc(itemsCollection, item);
  }

  // Get all documents from the 'locations' collection
  getItems(): Observable<any[]> {
    const itemsCollection = collection(this.firestore, this.tripsLocationCollType);
    return collectionData(itemsCollection) as Observable<any[]>;
  }

  getItemById(id: string): Observable<any> {
    const itemDoc = doc(this.firestore, this.tripsLocationCollType+`/${id}`);
    return docData(itemDoc, { idField: 'id' }) as Observable<any>;
  }

  // Update a document in the 'locations' collection
  async updateItem(id: string, item: any): Promise<void> {
    const itemDocRef = doc(this.firestore, this.tripsLocationCollType + `${id}`);
    await updateDoc(itemDocRef, item);
  }

  // Delete a document from the 'locations' collection
  async deleteItem(id: string): Promise<void> {
    const itemDocRef = doc(this.firestore, this.tripsLocationCollType + `${id}`);
    await deleteDoc(itemDocRef);
  }
}
