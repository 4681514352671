<div class="reports-control-bar" [ngClass]="currLang == 'en' ? 'reports-control-bar-ltr' : 'reports-control-bar-rtl'">
    <div class="left">
        <!-- search -->
        <div class="search-wrapper">
            <div class="search-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                    <path
                        d="M12.3929 12.8254L9.48368 9.75075M9.48368 9.75075C10.2711 8.91859 10.7134 7.78994 10.7134 6.61309C10.7134 5.43624 10.2711 4.3076 9.48368 3.47544C8.6963 2.64328 7.62839 2.17578 6.51487 2.17578C5.40135 2.17578 4.33344 2.64328 3.54607 3.47544C2.75869 4.3076 2.31635 5.43624 2.31635 6.61309C2.31635 7.78994 2.75869 8.91859 3.54607 9.75075C4.33344 10.5829 5.40135 11.0504 6.51487 11.0504C7.62839 11.0504 8.6963 10.5829 9.48368 9.75075Z"
                        stroke="#0074EC" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>
            <div class="search-field">
                <input type="text" (click)="resetOpenedFilters()" (keyup)="searchHandler()" [(ngModel)]="searchInput" class="search-input" type="text"
                    autocomplete="off"
                    [placeholder]="'new-dashboard.reports.control-bar.search.placeholder' | translate:{ entityEn: entityEn, entityAr: entityAr }">
            </div>
        </div>
    </div>
    <div class="right">
        <!-- period filter -->
        <div *ngIf="view != 'staff-on-duty' && view != 'driver-on-duty'" class="period-picker-wrapper" (click)="toggleDropdown('period')">
            <div class="selected-option">
                <span>{{ selectedPeriodOption?.name | translate}}</span>
            </div>
            <svg [@rotateAnimation]="!isPeriodDropdownOpen ? '0' : '180'" xmlns="http://www.w3.org/2000/svg" width="16"
                height="16" viewBox="0 0 16 16" fill="none">
                <path d="M13 5.5L8 10.5L3 5.5" stroke="#344054" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
            <div class="dropdown-list" *ngIf="isPeriodDropdownOpen">
                <div class="dropdown-list-item" *ngFor="let option of periodFilterOptions"
                    (click)="selectOption(option , 'period')">
                    {{ option.name | translate }}
                </div>
            </div>
        </div>
        <!-- status filter -->
        <div *ngIf="view == 'bus-attendance'" class="attendance-picker-wrapper" (click)="toggleDropdown('attendance')">
            <div class="selected-option">
                <span>{{ selectedAttendanceOption?.name | translate}}</span>
            </div>
            <svg [@rotateAttendancAnimation]="!isAttendanceDropdownOpen ? '0' : '180'"
                xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M13 5.5L8 10.5L3 5.5" stroke="#344054" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
            <div class="dropdown-list" *ngIf="isAttendanceDropdownOpen">
                <div class="dropdown-list-item" *ngFor="let option of attendanceFilterOptions"
                    (click)="selectOption(option , 'attendance')">
                    {{ option.name | translate }}
                </div>
            </div>
        </div>
        <!-- date filter -->
        <div class="date">
            <p-calendar (click)="resetOpenedFilters()" (onSelect)="setDateFilter($event)" [(ngModel)]="mainDate" [maxDate]="todaysDate"
                selectionMode="single" [readonlyInput]="true" inputId="range" [showIcon]="true"
                [dateFormat]="dateFormat">
            </p-calendar>
        </div>
    </div>
</div>