import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorMsgService {
  private renderer: Renderer2;

  constructor( rendererFactory: RendererFactory2) { 
    this.renderer = rendererFactory.createRenderer(null, null);

  }
  private messageSubject = new ReplaySubject<string | null>(1);

  setMessage(msg: string | null) {
    this.messageSubject.next(msg);
  }

  getMessage(): Observable<string | null> {
    return this.messageSubject.asObservable();
  }
  // private _errorMessage = new BehaviorSubject<any>(null);
  // errorMessage = this._errorMessage.asObservable();

  // setMessage(message: any) {
  //   this._errorMessage.next(message);
  // }

  // getMessage() {
  //   return this._errorMessage;
  // }

  cleanUpOpenedModals() {
    // Select all modals with the class or ID of error-msg-popup
    const modals = document.querySelectorAll('#error-msg-popup');
    
    // Iterate over each modal and hide it
    modals.forEach(modal => {
      this.renderer.setStyle(modal, 'display', 'none');
      this.renderer.removeClass(modal, 'show');
    });
  
    // Select all backdrops with the custom class .err-modal-backdrop
    const backdrops = document.querySelectorAll('.err-modal-backdrop');
    
    // Iterate over each backdrop and remove it
    backdrops.forEach(backdrop => {
      this.renderer.removeChild(document.body, backdrop);
    });
  }
  
}
