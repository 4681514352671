<form id="changePass" [formGroup]="changePasswordForm">
    <div class="form-field-container">
        <mat-form-field class="w-100" appearance="outline" dir="ltr">
            <mat-label>{{ "profile.old_password" | translate }}</mat-label>
            <input matInput formControlName="old_password" [type]="hideOldPass ? 'password' : 'text'" />

            <mat-error>{{ getValidationMessage("old_password") }}</mat-error>
        </mat-form-field>
        <button [ngStyle]="{'left' :currLang=='en'?'unset':'30px'   , 'right' : currLang=='ar'?'unset':'30px'}"
            class="eyeBtn" mat-icon-button matSuffix (click)="hideOldPass = !hideOldPass"
            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideOldPass">
            <mat-icon>{{ hideOldPass ? "visibility_off" : "visibility" }}</mat-icon>
        </button>
    </div>

    <div class="form-field-container">

        <mat-form-field class="w-100" appearance="outline" dir="ltr">
            <mat-label>{{ "profile.new_password" | translate }}</mat-label>
            <input matInput formControlName="new_password" [type]="hideNewPass ? 'password' : 'text'" />

            <!-- <mat-error>{{ getValidationMessage("new_password") }}</mat-error> -->
        </mat-form-field>
        <button [ngStyle]="{'left' :currLang=='en'?'unset':'30px'   , 'right' : currLang=='ar'?'unset':'30px'}"
            class="eyeBtn" mat-icon-button matSuffix (click)="hideNewPass = !hideNewPass"
            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideNewPass">
            <mat-icon>{{ hideNewPass ? "visibility_off" : "visibility" }}</mat-icon>
        </button>
    </div>


    <div class="row my-3 gap-1">
        <div class="col ">
            <app-validator [name]="'minlength'"
                [valid]="new_password.value&&!new_password.hasError('minlength')"></app-validator>
        </div>
        <div class="col ">

            <app-validator [name]="'hasCapitalCase'" [valid]="!new_password.hasError('hasCapitalCase')"></app-validator>

        </div>
        <div class="w-100"></div>
        <div class="col ">
            <app-validator [name]="'hasSmallCase'" [valid]="!new_password.hasError('hasSmallCase')"></app-validator>


        </div>
        <div class="col validator">
            <app-validator [name]="'hasNumber'" [valid]="!new_password.hasError('hasNumber')"></app-validator>


        </div>
    </div>
</form>
<div class="modal-footer">


    <button type="button" class="btn btn-primary save-btn" [disabled]="changePasswordForm.invalid"
        (click)="handleChangePassword()">
        {{ "profile.change_password" | translate }}
    </button>
</div>