<div class="list" [ngStyle]="{ direction: currLang == 'en' ? 'ltr' : 'rtl' }">
    <app-control-bar [listType]="'bus'" [searchVal]="searchText" [selectedItemsPerPage]="itemsPerPage"
        [selectedRows]="selectedCheckBoxes" (messagesSearchChange)="onSeachFn($event)"
        (onChangedItemsPerPage)="setItemsPerPage($event)" [actions]="actions"
        (onEventClicked)="tableEventHandeler($event)"></app-control-bar>

    <app-table-view [loading]="loading" [controls]="tableControls" (eventHandeler)="tableEventHandeler($event)"
        [sortedCol]="order.order_by" [sortingOrder]="order.order_type"></app-table-view>
</div>
<app-confirm-delete [deletedItemsType]="'supervisor'" [deletedItemsCount]="selectedCheckBoxes.length"
    (onConfirmDelete)="deleteSupervisiors()"></app-confirm-delete>