<div class="list" [ngStyle]="{ direction: currLang == 'en' ? 'ltr' : 'rtl' }" style="padding: 0 2rem;">
    <app-reports-header [listType]="'trip-user-history'" [title]="userName"
        (onEventClicked)="eventHandeler($event)"></app-reports-header>
    <app-control-bar [listType]="'trip-user-history'" [hideSearch]="true" [dateFilter]="true"
        [selectedItemsPerPage]="itemsPerPage" [from_date]="from_date" [to_date]="to_date"
        (messagesSearchChange)="onSeachFn($event)" (onChangedItemsPerPage)="setItemsPerPage($event)"
        (onEventClicked)="eventHandeler($event)" (fromDateChange)="setDate($event,'from')"
        (toDateChange)="setDate($event,'to')"></app-control-bar>

    <app-table-view [loading]="loading" [showExport]="true" [controls]="tableControls" (eventHandeler)="eventHandeler($event)"
        [currentView]="rowsView"></app-table-view>
</div>