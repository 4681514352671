import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localizeCount',
  standalone: true
})
export class LocalizeCountPipe implements PipeTransform {
  transform(itemsCount: number, curLang: string, type: string): string {
    if (curLang === 'en') {
      if (itemsCount === 1) {
        return type;
      } else {
        return type + 's';
      }
    } else if (curLang === 'ar') {
      if (itemsCount === 1) {
        switch (type) {
          case 'trip': return 'رحلة واحدة';
          case 'bus': return 'حافلة واحدة';
          case 'area': return 'منطقة واحدة';
          case 'driver': return 'سائق واحد';
          case 'supervisor': return 'مشرف واحد';
          default: return 'عنصر واحد'
        }
      } else if (itemsCount === 2) {
        switch (type) {
          case 'trip': return 'رحلتين';
          case 'bus': return 'حافلتين';
          case 'area': return 'منطقتين';
          case 'driver': return 'سائقين';
          case 'supervisor': return 'مشرفين';
          default: return 'عنصرين'
        }
      }
      else if (itemsCount > 2 && itemsCount < 11) {
        switch (type) {
          case 'trip': return 'رحلات';
          case 'bus': return 'حافلات';
          case 'area': return 'مناطق';
          case 'driver': return 'سواق';
          case 'supervisor': return 'مشرفين';
          default: return 'عناصر'
        }
      }
      else {
        switch (type) {
          case 'trip': return 'رحلة';
          case 'bus': return 'حافلة';
          case 'area': return 'منطقة';
          case 'driver': return 'سائق';
          case 'supervisor': return 'مشرف';
          default: return 'عنصر'
        }
      }
    }
    return type;
  }
}
