import { Component, Renderer2 } from '@angular/core';
import { TableControls } from '../../../models/table-controls.model';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../../../shared/services/global.service';
import { ErrorMsgService } from '../../../shared/services/error-msg.service';
import { map } from 'rxjs';
import { ToastrService } from 'ngx-toastr'
import { CommonModule } from '@angular/common';
import { ControlBarComponent } from '../../../shared/components/control-bar/control-bar.component';
import { TableViewComponent } from '../../../shared/components/table-view/table-view.component';
import { Bus } from '../../../models/bus.model';
import { BusService } from '../_services/bus.service';
import { AddBusComponent } from './add-bus/add-bus.component';
import { ConfirmDeleteComponent } from '../../../shared/components/confirm-delete/confirm-delete.component';
declare var $: any;

@Component({
  selector: 'app-buses',
  standalone: true,
  imports: [CommonModule, ControlBarComponent, TableViewComponent, AddBusComponent, ConfirmDeleteComponent],
  templateUrl: './buses.component.html',
  styleUrl: './buses.component.scss'
})
export class BusesComponent {
  tableControls: TableControls = { data: [] };

  searchText = '';
  pageNumber: number = 1;
  itemsPerPage: number = 25;
  order: { order_type?: string, order_by?: string } = {}
  currLang: string = 'en';
  loading!: boolean;
  rowsView: string = 'list';
  filter = {};
  selectedCheckBoxes: number[] = [];
  from!: number;
  to!: number;
  total!: number;

  dataArray: Bus[] = [];
  actions = [
    {
      type: 'add',
      name: 'busses.add-new',
    },
    {
      type: 'del',
      name: 'busses.delete',
    },
  ];

  isAddBusModalOpen = false;

  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private globalService: GlobalService,
    private busService: BusService,

    private errorMsgService: ErrorMsgService,
    private toastr: ToastrService,
    private renderer: Renderer2

  ) {
    this.globalService.getLang().subscribe((lng) => {
      this.currLang = lng ? lng : "en";
      this.translate.use(this.currLang);
    });
    this.translate.onLangChange.subscribe((res) => {
      this.currLang = res.lang ? res.lang : "en";
      this.translate.use(this.currLang);
      this.setTableData(this.dataArray);
    });
    this.busService.getLoadBussesData().subscribe((res) => {
      if (res) {
        this.getBussesData();
      }
    });
  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.searchText = params['search'] || '';

      this.filter = {
        buses: {
          name: this.searchText.trim().toLowerCase(),
          plate_no: this.searchText.trim().toLowerCase(),
          capacity: this.searchText.trim().toLowerCase(),
          brand: this.searchText.trim().toLowerCase(),
          model: this.searchText.trim().toLowerCase(),
          operator: 'or',
        },

      }
      if (params['order_by'] && params['order_type']) {
        this.order = {
          order_by: params['order_by'],
          order_type: params['order_type'],
        }
      }
      this.pageNumber = +params['page'] || 1;
      this.itemsPerPage = params['show'] || 25;
      this.getBussesData();

    })
  }
  setTableData(data: Bus[]) {
    this.tableControls = {
      selection: true,
      paging: true,
      title: 'table-view.titles.bus',
      selectedTitle: 'table-view.selected-titles.bus',
      totalTitle: 'table-view.total-titles.bus',
      pagination: {
        from: this.from,
        to: this.to,
        total: this.total,
      },
      columns: [
        // {
        //   name: this.translate.instant('busses.color'),
        //   sort: false,
        //   type: 'color',
        //   source: 'color',
        // },
        {
          name: this.translate.instant('busses.name'),
          sort: false,
          type: 'string',
          source: 'name',
        },
        {
          name: this.translate.instant('busses.plate-no'),
          sort: true,
          type: 'string',
          source: 'plate_no',
        },
        {
          name: this.translate.instant('busses.brand'),
          sort: true,
          type: 'string',
          source: 'brand',
        },
        // {
        //   name: this.translate.instant('busses.model'),
        //   sort: true,
        //   type: 'string',
        //   source: 'model',
        // },

        {
          name: this.translate.instant('busses.capacity'),
          sort: false,
          type: 'string',
          source: 'capacity',
        },
        {
          name: this.translate.instant('busses.no-assign-trip'),
          name2: 'busses.trips',
          sort: true,
          type: 'multipleValues',
          source: 'trips_count',
          source2: 'ways',
        },

      ],
      data: data,
    };
  }
  tableEventHandeler(event: { action: string; target: any }) {
    switch (event.action) {
      case 'rowClicked':
        this.rowClicked(event.target);
        break;
      case 'checkboxChanged':
        this.selectChanged(event.target);
        break;
      case 'sorting':
        this.sorting(event.target);
        break;

      case 'pagination':
        this.paginationHandling(event.target);
        break;
      case 'del':
        this.showDeleteConfirmation();
        break;
      case 'add':
        this.addBus();
        break;
    }
  }
  sorting(target: { order: string; source: string }) {
    this.router.navigate(['layout/management/buses'], {
      queryParams: { order_type: target.order, order_by: target.source },
      queryParamsHandling: 'merge',
    });
  }

  rowClicked(event: { index: number; data: Bus }) {
    if (this.isAddBusModalOpen) {
      return; // Prevent opening multiple modals
    }
    this.isAddBusModalOpen = true; // Set flag to indicate modal is open
    this.busService.getBusById(event.data.id, ['trips']).subscribe((res: any) => {
      this.busService.setBusData(res.data.bus, 'show');

      const modalRef = this.modalService.open(AddBusComponent, { size: 'xl' });

      modalRef.result.finally(() => {
        this.isAddBusModalOpen = false; // Reset flag when modal is closed
      });
    }, () => {
      this.isAddBusModalOpen = false; // Reset flag in case of error
    });
  }

  selectChanged(event: {
    index: number | 'all';
    data?: Bus;
    checked: boolean;
  }) {
    let index = event.index;
    let row = event.data;

    let checked = event.checked;
    if (checked) {
      // checked
      if (index == 'all') {
        this.selectedCheckBoxes = this.dataArray.map((element: Bus) => element.id!);
      } else {
        this.selectedCheckBoxes.push(row?.id!);
      }
    } else {
      // unchecked
      if (index == 'all') {
        this.selectedCheckBoxes = [];
      } else {
        let checkboxIndex = this.selectedCheckBoxes.indexOf(row?.id!);

        this.selectedCheckBoxes.splice(checkboxIndex, 1);
      }
    }
  }


  paginationHandling(event: { dir: number }) {
    this.pageNumber += event.dir;
    this.router.navigate(['layout/management/buses'], {
      queryParams: { page: this.pageNumber },
      queryParamsHandling: 'merge',
    });
  }
  getBussesData() {
    this.loading = true;

    this.busService
      .getBusess(
        this.pageNumber,
        this.itemsPerPage,
        ['trips'],
        ['trips'],
        this.filter, this.order
      )
      .pipe(map((res) => res['data'].buses))

      .subscribe(
        (res) => {
          let dataArray: Bus[] = [];
          dataArray = res['data'].map((element: Bus) => {
            let ways: any = [];

            element.trips!.map((el: any) => {
              ways.push(el.way);
            });
            return {
              color: element.color,
              plate_no: element.plate_no,
              name: element.name,
              capacity: element.capacity,
              id: element.id,
              model: element.model,
              brand: element.brand,
              trips_count: element.trips_count,
              // tripsLength: element.trips!.length,
              ways: ways,
            };
          });

          this.dataArray = dataArray;
          this.from = res['from'];
          this.to = res['to'];
          this.total = res['total'];
          this.selectedCheckBoxes = [];
          this.setTableData(dataArray);
          this.loading = false;
        },

        (error) => {
          this.errorMsgService.setMessage(error.error.Error[0]);
        }
      );
  }

  onSeachFn(text: string) {
    this.searchText = text;
    this.pageNumber = 1;
    this.router.navigate(['layout/management/buses'], {
      queryParams: { page: this.pageNumber, search: this.searchText },
      queryParamsHandling: 'merge',
    });

  }
  setItemsPerPage($event: number) {
    this.itemsPerPage = $event;
    this.pageNumber = 1;
    this.router.navigate(['layout/management/buses'], {
      queryParams: { show: this.itemsPerPage, page: this.pageNumber },
      queryParamsHandling: 'merge',
    });

  }
  addBus() {
    this.modalService.open(AddBusComponent, { size: 'xl' });
  }

  showDeleteConfirmation() {
    const modal = this.renderer.selectRootElement('#confirm-delete-popup', true);
    this.renderer.setStyle(modal, 'display', 'block');
    this.renderer.addClass(modal, 'show');
  }

  hideDeleteConfirmation() {
    const modal = this.renderer.selectRootElement('#confirm-delete-popup', true);
    this.renderer.setStyle(modal, 'display', 'none');
    this.renderer.removeClass(modal, 'show');
  }

  deleteBusses() {
    let currPage = this.pageNumber;
    if (this.selectedCheckBoxes.length == this.dataArray.length) {
      this.pageNumber--;
      this.pageNumber = this.pageNumber == 0 ? 1 : this.pageNumber;
    }

    this.busService.deleteBusses(this.selectedCheckBoxes).subscribe(
      (res) => {
        if (currPage == this.pageNumber) {

          this.getBussesData();
        } else {
          this.router.navigate(['layout/management/buses'], {
            queryParams: { page: this.pageNumber },
            queryParamsHandling: 'merge',
          });
        }
        this.toastr.success(this.deleteToastrMessage(), this.deleteToastrTitle(), {
          positionClass: 'toast-top-center'
        });
      },
      (error) => {
        this.hideDeleteConfirmation();
        this.errorMsgService.setMessage(error.error.Error[0]);
      }, () => {
        this.hideDeleteConfirmation();
      }
    );
  }

  deleteToastrMessage(): string {
    return this.currLang == 'en' ? 'Deleted Successfully' : 'تم الحذف بنجاح';
  }
  deleteToastrTitle(): string {
    return this.currLang == 'en' ? 'Buses Deletion' : 'حذف الحافلات';
  }

}
