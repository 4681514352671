<div  class="modal" id="confirm-delete-popup" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="modal-head">
                    <div class="title">
                        {{'confirm-delete.title' | translate }} {{localizeDeletedItems(deletedItemsType)}} <span [ngStyle]="{'transform': currLang =='ar' ? 'scaleX(-1)' : 'scaleX(1)'}">?</span>
                    </div>
                </div>
                <div class="confirm-delete-body">
                    <div class="confirmation-msg">
                        {{'confirm-delete.desc1' | translate }} <span *ngIf="(deletedItemsCount > 2 && currLang =='ar') || currLang =='en'">{{deletedItemsCount | arabicNumber: currLang}}</span> {{ deletedItemsCount | localizeCount:currLang:deletedItemsType }} <span [ngStyle]="{'transform': currLang =='ar' ? 'scaleX(-1)' : 'scaleX(1)'}"> ? </span> {{'confirm-delete.desc2' | translate }}
                    </div>
                    <div class="confirmation-actions">
                        <button type="button" (click)="hideModal()" class="Btn cancelBtn">{{'confirm-delete.cancel-btn' | translate }}</button>
                        <button class="Btn deleteBtn" (click)="confirmDelete()">{{'confirm-delete.delete-btn' | translate }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>