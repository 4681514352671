import { Component } from '@angular/core';
import { SnackBarService } from '../../../services/snack-bar.service';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  selector: 'app-snack-bar-error',
  standalone: true,
  imports: [SvgIconComponent],
  templateUrl: './snack-bar-error.component.html',
  styleUrl: './snack-bar-error.component.scss'
})
export class SnackBarErrorComponent {
  message!: string;

  constructor(private snackbarService: SnackBarService) { }

  ngOnInit(): void {
    this.snackbarService.getSnackBarMessage().subscribe((res: any) => {
      if (res !== null) {
        this.message = res;
      }
    });
  }

}
