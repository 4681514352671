import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../services/global.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarErrorComponent } from '../snack-bar-error/snack-bar-error/snack-bar-error.component';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DropdownDirective } from '../../directives/dropdown.directive';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { SnackBarService } from '../../services/snack-bar.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ConfirmDeleteComponent } from '../confirm-delete/confirm-delete.component';
import { AddTripComponent } from '../../../feature_components/management/trip/add-trip/add-trip.component';
import { ArabicNumberPipe } from "../../pipes/arabic-number.pipe";
AddTripComponent
@Component({
    selector: 'app-control-bar',
    standalone: true,
    templateUrl: './control-bar.component.html',
    styleUrl: './control-bar.component.scss',
    imports: [CommonModule, MatFormFieldModule, DropdownDirective, MatInputModule, MatButtonModule, TranslateModule, MatTooltipModule, ConfirmDeleteComponent, AddTripComponent, ArabicNumberPipe]
})
export class ControlBarComponent {
  showItemsPerPageDiv = false;
  itemsPerPage = [10, 25, 50];
  @Input() selectedItemsPerPage: number = 25;
  @Input() searchVal: string = '';
  from: number = 1;
  to: number = 25;
  timer: any = null;
  currLang!: any;
  @Input() from_date!: string;
  @Input() to_date!: string;
  @Input('listType') listType!: string;
  @Input() hideSearch: boolean = false;
  @Input() searchFullWidth: boolean = false;
  @Input() hideRows: boolean = false;
  @Input() dateFilter: boolean = false;
  @Input() showToggleView: boolean = false;
  @Input() selectedRows: number[] = [];
  @Output() onViewTypeChange = new EventEmitter();
  @Output() onChangedItemsPerPage = new EventEmitter();
  @Output() messagesSearchChange: EventEmitter<any> = new EventEmitter();
  activatedType = 'list'; //2 only to put active class on icon
  toggleItemsPerPageDiv = false;
  @Input('actions') actions: any;
  // @Output() onAddbuttonClicked = new EventEmitter();
  @Output() onEventClicked = new EventEmitter();
  @Output() fromDateChange: EventEmitter<any> = new EventEmitter();
  @Output() toDateChange: EventEmitter<any> = new EventEmitter();
  constructor(
    private translate: TranslateService,
    private globalService: GlobalService,
    private snackBar: SnackBarService,
    private _snackBar: MatSnackBar
  ) {
    this.globalService.getLang().subscribe((lng) => {
      this.currLang = lng ? lng : "en";
      this.translate.use(this.currLang);
    });
  }

  ngOnInit(): void { }

  setViewType(type: string) {
    this.onViewTypeChange.emit(type);
    // TODO delete after implement parmas in all routes that use control-bar

    this.activatedType = type;
  }
  toggleItemsPerPageDivFn() {
    this.toggleItemsPerPageDiv = !this.toggleItemsPerPageDiv;
  }
  changeRowsNumber(itemsPerPage: number) {
    // TODO delete after implement parmas in all routes that use control-bar

    this.selectedItemsPerPage = itemsPerPage;
    this.onChangedItemsPerPage.emit(itemsPerPage);
  }
  onKeyup($event: any) {
    // TODO delete after implement parmas in all routes that use control-bar

    this.searchVal = $event.target.value;
    let term = $event.target.value.trim();
    const regex = '^[a-zA-z0-9-_\u0621-\u064A\u0660-\u0669 ]*$';
    let foundError = term.match(regex);
    if (term === '' || foundError !== null) {
      // the search input is empty or doesnt has special chars
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.messagesSearchChange.emit(term);
      }, 1400);
    } else {
      this.snackBar.setSnackBarMessage(
        this.translate.instant('control-bar.special-warning')
      );
      this._snackBar.openFromComponent(SnackBarErrorComponent, {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }
  // onAdd() {
  //   this.onAddbuttonClicked.emit();
  // }
  eventHandler(type: string) {
    this.onEventClicked.emit({
      action: type,
    });
  }

  emitDate(event: any, flag: string) {
    switch (flag) {
      case 'from':
        this.fromDateChange.emit(event.target.value);
        // TODO delete after implement parmas in all routes that use control-bar
        this.from_date = event.target.value;
        break;
      case 'to':
        this.toDateChange.emit(event.target.value);
        // TODO delete after implement parmas in all routes that use control-bar

        this.to_date = event.target.value;
        break;
    }
  }

}
