import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  token = localStorage.getItem('token');
  options = {
    headers: new HttpHeaders({
      // tslint:disable-next-line:object-literal-key-quotes
      'Authorization': `Bearer ${this.token}`
    })
  };

  constructor(private http: HttpClient) { }
  // Dashboard
  getDailyCounts(dashboardDate: any, company_id: any) {
    return this.http.get(environment.url + `backoffice/dashboard-statistics/getDailyTripsCount?company_id=${company_id}&date=${dashboardDate}`);
  }

  getJourneyTimes(dashboardDate: any, company_id: any) {
    return this.http.get(environment.url + `backoffice/dashboard-statistics/getJourneyTimes?company_id=${company_id}&date=${dashboardDate}`);
  }

  getWaitingTime(dashboardDate: any, company_id: any) {
    return this.http.get(environment.url + `backoffice/dashboard-statistics/getBusAverageWaitingTime?company_id=${company_id}&date=${dashboardDate}`);
  }

  getBusAttendance(dashboardDate: any, company_id: any) {
    return this.http.get(environment.url + `backoffice/dashboard-statistics/getStudentBusAttendance?company_id=${company_id}&date=${dashboardDate}`);
  }

  getDailyDelay(dashboardDate: any, company_id: any) {
    return this.http.get(environment.url + `backoffice/dashboard-statistics/getDailyTripsDelayTime?company_id=${company_id}&date=${dashboardDate}`);
  }

  getStaffOnDuty(dashboardDate: any, company_id: any, search: string, perPage: number, isPaginated: any , page:any) {
    return this.http.get(environment.url + `backoffice/dashboard-statistics/getStaffOnDuty?company_id=${company_id}&date=${dashboardDate}&search=${search}&per_page=${perPage}&is_paginated=${isPaginated}&page=${page}`);
  }

  getDriverDailyProgression(dashboardDate: any, company_id: any, search: string, perPage: number, isPaginated: any, currentPage: number) {
    return this.http.get(environment.url + `backoffice/dashboard-statistics/getDriverDailyProgression?company_id=${company_id}&date=${dashboardDate}&search=${search}&per_page=${perPage}&is_paginated=${isPaginated}&page=${currentPage}`);
  }

  getDriverOnDuty(dashboardDate: any, company_id: any, search: string, perPage: number, isPaginated: any , page:any) {
    return this.http.get(environment.url + `backoffice/dashboard-statistics/getDriverOnDuty?company_id=${company_id}&date=${dashboardDate}&search=${search}&per_page=${perPage}&is_paginated=${isPaginated}&page=${page}`);
  }

  // Reports
  getJourneyTimesReport(company_id: any , date:any , way:any , search:any , per_page:any , is_paginated:any , page:any) {
    return this.http.get(environment.url + `backoffice/bus-dashboard/getJourneyTimesTable?company_id=${company_id}&date=${date}&way=${way}
    &search=${search}&per_page=${per_page}&is_paginated=${is_paginated}&page=${page}`);
  }

  getBusAttendanceReport(company_id: any , date:any , way:any , search:any , per_page:any , is_paginated:any , page:any , status:any) {
    return this.http.get(environment.url + `backoffice/bus-dashboard/getSubscriptionsBusAttendanceTable?company_id=${company_id}&date=${date}&way=${way}
    &search=${search}&per_page=${per_page}&is_paginated=${is_paginated}&page=${page}&attendance_status=${status}`);
  }

  getDailyDelayReport(company_id: any , date:any , way:any , search:any , per_page:any , is_paginated:any , page:any) {
    return this.http.get(environment.url + `backoffice/bus-dashboard/getDailyTripsDelayTable?company_id=${company_id}&date=${date}&way=${way}
    &search=${search}&per_page=${per_page}&is_paginated=${is_paginated}&page=${page}`);
  }
}
