<!-- <div class="table-responsive"> -->
<div class="table-wrapper" style="padding:0px .4rem;">
    <div class='table-container' [class.rtl]="currLang == 'ar'">
        <div class="table-heading" [ngStyle]="toggleTableBorderRadius(false,false,currLang,'heading')">
            <div class="table-meta">
                <div class="table-title">
                    {{tableTitle | translate}}
                </div>
                <div class="total-chip" *ngIf="showTotalNumberFlag">
                    <div class="selected-elements" *ngIf="selection && showSelectionNumberFlag && selectedCount>0">
                        {{selectedCount | arabicNumber:currLang}} {{selectedTitle | translate}}<span
                            style="margin-inline-start: 4px; margin-inline-end: 4px;">/</span>
                    </div>
                    <div class="total-elements">
                        {{data?.length | arabicNumber:currLang}} {{totalTitle |translate}} <span
                            *ngIf="showPagination">{{'table-view.total-titles.per-page' | translate}}</span>
                    </div>
                </div>
            </div>
            <div class="table-actions">
                <div *ngIf="showExport" class="generat-excel-container">
                        <button class="download-btn" (click)="generateExcelFile()" [disabled]="data?.length == 0">
                            <svg *ngIf="data?.length > 0;else disabledExportSvg"
                                xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M1.375 12.0625V13.8438C1.375 14.3162 1.56267 14.7692 1.89672 15.1033C2.23077 15.4373 2.68383 15.625 3.15625 15.625H13.8438C14.3162 15.625 14.7692 15.4373 15.1033 15.1033C15.4373 14.7692 15.625 14.3162 15.625 13.8438V12.0625M12.0625 8.5L8.5 12.0625M8.5 12.0625L4.9375 8.5M8.5 12.0625V1.375" stroke="#0074EC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <ng-template #disabledExportSvg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                    <path d="M1.375 12.0625V13.8438C1.375 14.3162 1.56267 14.7692 1.89672 15.1033C2.23077 15.4373 2.68383 15.625 3.15625 15.625H13.8438C14.3162 15.625 14.7692 15.4373 15.1033 15.1033C15.4373 14.7692 15.625 14.3162 15.625 13.8438V12.0625M12.0625 8.5L8.5 12.0625M8.5 12.0625L4.9375 8.5M8.5 12.0625V1.375" stroke="#B4B4B4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </ng-template>
                            <span>{{'table-view.export-btn' | translate}}</span>
                        </button>
                    </div>
            </div>
        </div>
        <div class="tableFixHead">
            <table class="fixed_header">
                <thead>
                    <tr [class]="currentView == 'compact' ? 'compact-tr' : ''">
                        <th *ngIf="selectionAll" class="check-box--cell" scope="col">
                            <mat-checkbox [checked]="AllRowsIsChecked" (change)="checkBoxSelectAll($event)"
                                *ngIf="!singleSelect"></mat-checkbox>
                        </th>
                        <ng-container *ngFor="let col of columns">
                            <th *ngIf="checkColumn(col.type, view)" [class]="col.type"
                                [ngStyle]="{ 'text-align': currLang == 'en' ? 'left' : 'right' }">
                                {{ col.name }}
                                <ng-container *ngIf="col.sort">
                                    <svg [class]="sortingOrder === 'asc' && sortedCol === col.source
                                        ? 'sorting-icons active'
                                        : 'sorting-icons'" (click)="sort(col, 'asc')"
                                        xmlns="http://www.w3.org/2000/svg" width="6.686" height="11.806"
                                        viewBox="0 0 6.686 11.806">
                                        <path id="Path_5503" data-name="Path 5503"
                                            d="M3.342,11.806a1.372,1.372,0,0,1-.98-.408L.262,9.285A.9.9,0,0,1,.9,7.748H2.881V.461a.461.461,0,0,1,.922,0V7.748a.923.923,0,0,1-.922.922H.951l2.066,2.078a.459.459,0,0,0,.652,0L5.734,8.67H5.279a.461.461,0,1,1,0-.922h.507a.9.9,0,0,1,.636,1.538L4.322,11.4a1.372,1.372,0,0,1-.98.408Zm0,0"
                                            transform="translate(0.001)" fill="#707070" />
                                    </svg>
                                    <!--  -->
                                    <svg (click)="sort(col, 'desc')" [class]="sortingOrder === 'desc' && sortedCol === col.source
                                        ? 'sorting-icons active'
                                        : 'sorting-icons'" xmlns="http://www.w3.org/2000/svg" width="6.686"
                                        height="11.806" viewBox="0 0 6.686 11.806">
                                        <path id="Path_5502" data-name="Path 5502"
                                            d="M225.353,11.806a.461.461,0,0,1-.461-.461V4.058h-1.983a.9.9,0,0,1-.636-1.538l2.1-2.113a1.382,1.382,0,0,1,1.96,0l2.1,2.113a.9.9,0,0,1-.636,1.538h-.507a.461.461,0,1,1,0-.922h.454l-2.066-2.078a.459.459,0,0,0-.652,0l-2.066,2.078h1.93a.923.923,0,0,1,.922.922v7.287A.461.461,0,0,1,225.353,11.806Zm0,0"
                                            transform="translate(-222.01)" fill="#707070" />
                                    </svg>
                                </ng-container>
                            </th>
                        </ng-container>
                    </tr>
                </thead>
                <tbody>
                    <div *ngIf="loading" class="loading">
                        <mat-spinner *ngIf="true" mode="indeterminate"></mat-spinner>
                    </div>
                    <div class="body-container" *ngIf="!loading">
                        <ng-container *ngFor="let row of data; index as rowIndex">
                            <tr [class]="{
                                'compact-tr': view == 'compact',
                                selected: selectedRows[rowIndex]
                            }" (click)="rowClicked(row, rowIndex)">
                                <td class="check-box--cell" *ngIf="selection">
                                    <mat-checkbox [checked]="AllRowsIsChecked || row.isSelected"
                                        (change)="checkboxAction($event, row, rowIndex)"
                                        (click)="$event.stopPropagation()">
                                    </mat-checkbox>
                                </td>
                                <ng-container *ngFor="let col of columns">
                                    <td *ngIf="col.type == 'index'" [class]="col.type" [ngStyle]="{
                                    'text-align': currLang == 'en' ? 'left' : 'right'
                                  }">
                                        <span [id]="'index_' + row.id">
                                            {{ rowIndex + 1 }}
                                        </span>
                                    </td>
                                    <td *ngIf="view == 'list' && col.type == 'image'" [class]="col.type">
                                        <img [id]="'img_' + row.id" class="profile-img"
                                            [src]="getImage(row[col.source])" [ngStyle]="{
                                      'text-align': currLang == 'en' ? 'left' : 'right'
                                    }" />
                                    </td>
                                    <td *ngIf="col.type == 'string'" [ngStyle]="{
                                    'text-align': currLang == 'en' ? 'left' : 'right'
                                  }">
                                        <span [id]="col.source + '_' + row.id"
                                            [ngClass]="{ link: row[col.source + '_link'] }"
                                            (click)="linkClicked(row, col, rowIndex)">{{ row[col.source] }}</span>

                                        <div class="empty-cell" *ngIf="!row[col.source] && row[col.source] !=0">
                                            <div class="filler"></div>
                                        </div>
                                    </td>

                                    <td *ngIf="col.type == 'hover'" [ngStyle]="{
                                  'text-align': currLang == 'en' ? 'left' : 'right'
                                }">
                                        <div class="hover-wrapper" [id]="col.source + '_' + row.id"
                                            [ngClass]="{ link: row[col.source + '_link'] }"
                                            (click)="linkClicked(row, col, rowIndex)">
                                            <div class="hover-element" (mouseenter)="row[col.show_hover_data]=true"
                                                (mouseleave)="row[col.show_hover_data]=false">
                                                {{ row[col.source] }}
                                            </div>
                                            <div class="hovered-data" *ngIf=" row[col.show_hover_data]">
                                                <div class="hovered-title" *ngIf=" row[col.show_hover_data_title]">
                                                    {{row[col.hovered_data_title] | translate}}
                                                </div>
                                                <div class="hovered-content">
                                                    {{ row[col.hovered_data]}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="empty-cell" *ngIf="!row[col.source] && row[col.source] !=0">
                                            <div class="filler"></div>
                                        </div>
                                    </td>

                                    <td *ngIf="col.type == 'date_time'" [ngStyle]="{
                                    'text-align': currLang == 'en' ? 'left' : 'right'
                                  }">
                                        <span [id]="col.source + '_' + row.id">{{
                                            row[col.source] | localDateTime
                                            }}</span>
                                    </td>
                                    <td *ngIf="col.type == 'color'" class="color" [ngStyle]="{
                                    'text-align': currLang == 'en' ? 'left' : 'right'
                                  }">
                                        <div [id]="col.source + '_' + row.id"
                                            [ngStyle]="{ 'background-color': row[col.source] }"></div>
                                    </td>

                                    <td *ngIf="col.type == 'attendanceStatus'" class="attendance-container" [ngStyle]="{
                                    'text-align': currLang == 'en' ? 'left' : 'right'
                                  }">
                                        <span [id]="col.source + '_' + row.id" *ngIf="row[col.source]" [class]="
                                      row[col.source] == 'present'
                                        ? 'label-present '
                                        : 'label-not-present'
                                    ">{{ "table-view." + row[col.source] | translate }}</span>
                                    </td>

                                    <!-- tooltip with multivalues -->
                                    <td *ngIf="col.type === 'multipleValues'" [ngStyle]="{
                                    'text-align': currLang == 'en' ? 'left' : 'right'
                                  }">
                                        <!-- "right-top" -->
                                        <span *ngIf="row[col.source2]?.length == 0" [id]="col.source + '_' + row.id">
                                            {{ row[col.source] }}
                                        </span>
                                        <span [id]="col.source + '_' + row.id" *ngIf="row[col.source2]?.length > 0"
                                            triggers="manual" #t="ngbTooltip"
                                            (mouseover)="t.open(); $event.stopPropagation()"
                                            (mouseleave)="t.close(); $event.stopPropagation()" [placement]="
                                      currLang == 'en' ? 'bottom-left' : 'bottom-right'
                                    " tooltipClass="my-custom-class" [ngbTooltip]="infoTemplate">
                                            {{ row[col.source] }}
                                        </span>
                                        <ng-template #infoTemplate>
                                            <div class="info-container">
                                                <span class="header" *ngIf="row[col.source2]?.length > 0">{{
                                                    "busses.trips" | translate
                                                    }}</span>
                                                <div *ngFor="let item of row[col.source2]">{{ item }}</div>
                                            </div>
                                        </ng-template>
                                    </td>

                                    <td *ngIf="col.type === 'backSlashSeperatedNumbers'" [ngStyle]="{
                                            'text-align': currLang == 'en' ? 'left' : 'right'
                                          }">
                                        {{ row[col.source]?.first_number | arabicNumber:currLang}} / {{
                                        row[col.source]?.second_number | arabicNumber:currLang}}
                                    </td>
                                    <td *ngIf="col.type === 'translateNumbers'" [ngStyle]="{
                                            'text-align': currLang == 'en' ? 'left' : 'right'
                                        }">
                                        {{ (row[col.source] ? row[col.source] : 0) | arabicNumber:currLang}}
                                    </td>

                                    <td *ngIf="col.type == 'report-way'" [ngStyle]="{
                                        'text-align': currLang == 'en' ? 'left' : 'right'
                                      }">
                                        <span [id]="col.source + '_' + row.id" *ngIf="row[col.source]">
                                            <span *ngIf="row[col.source] == 'Morning'">
                                                {{"table-view.reports.way-morning"| translate }}
                                            </span>
                                            <span *ngIf="row[col.source] == 'Afternoon'">
                                                {{"table-view.reports.way-afternoon"| translate }}
                                            </span>
                                    </span>
                                    </td>

                                    <td *ngIf="col.type == 'report-time'" [ngStyle]="{
                                        'text-align': currLang == 'en' ? 'left' : 'right'
                                    }">
                                    {{ (row[col.source] ? row[col.source] : 0) | arabicNumber:currLang }}
                                    <span>
                                            {{"table-view.reports.time-prefix"| translate }}
                                    </span>
                                    </td>

                                    <td *ngIf="col.type == 'report-date'" [ngStyle]="{
                                        'text-align': currLang == 'en' ? 'left' : 'right'
                                    }">
                                    <span>
                                            {{localizeDate(row[col.source])}}
                                    </span>
                                    </td>
                                </ng-container>
                            </tr>
                        </ng-container>
                    </div>
                </tbody>
                <div *ngIf="noResultsFlag && !loading" class="centered text-muted">
                    <strong>{{
                        currLang === "en" ? "No Results Found" : "لا توجد بيانات"
                        }}</strong>
                </div>
            </table>
        </div>
        <div class="table-footer" *ngIf="pagination" [ngStyle]="toggleTableBorderRadius(false,false,currLang,'footer')">
            <button class="nav-btn prev-btn" (click)="paginationChange(-1)"
                [disabled]="pagination?.from == 1 || data?.length == 0">
                <div class="icon">
                    <svg [ngStyle]="{'transform': currLang == 'en' ? 'rotate(0)' : 'rotate(180deg)' , 'stroke': pagination?.from === 1 || data?.length == 0 ? '#B7B7B7' : '#344054'}"
                        xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M15.8332 10.0003H4.1665M4.1665 10.0003L9.99984 15.8337M4.1665 10.0003L9.99984 4.16699"
                            stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
                <div class="label">
                    {{'table-view.previous' |translate}}
                </div>
            </button>
            <div class="paganation-text">
                {{pagination?.from | arabicNumber:currLang}} {{'table-view.to' |translate}} {{pagination?.to |
                arabicNumber:currLang}} {{'table-view.of' | translate}} {{pagination?.total | arabicNumber:currLang}}
            </div>
            <button class="nav-btn next-btn" (click)="paginationChange(1)"
                [disabled]="pagination?.to == pagination?.total || data?.length == 0">
                <div class="label">
                    {{'table-view.next' |translate}}
                </div>
                <div class="icon">
                    <svg [ngStyle]="{'transform': currLang == 'en' ? 'rotate(0)' : 'rotate(180deg)', 'stroke': pagination?.to == pagination?.total || data?.length == 0 ? '#B7B7B7' : '#344054'}"
                        xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path
                            d="M4.1665 10.0003H15.8332M15.8332 10.0003L9.99984 4.16699M15.8332 10.0003L9.99984 15.8337"
                            stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
            </button>
        </div>

    </div>
</div>