import { Component, Renderer2 } from '@angular/core';
import { TableControls } from '../../../models/table-controls.model';
import { Filter } from '../../../models/filter.model';
import { Trip, TripPassenger } from '../../../models/trip.model';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../../../shared/services/global.service';
import { TripService } from '../../management/_services/trip.service';
import { ErrorMsgService } from '../../../shared/services/error-msg.service';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs';
import { TableViewComponent } from '../../../shared/components/table-view/table-view.component';
import { ControlBarComponent } from '../../../shared/components/control-bar/control-bar.component';
import { CommonModule } from '@angular/common';
import { ConfirmDeleteComponent } from '../../../shared/components/confirm-delete/confirm-delete.component';
import { AddTripComponent } from './add-trip/add-trip.component';
declare var $: any;

@Component({
  selector: 'app-trip',
  standalone: true,
  imports: [TableViewComponent , ControlBarComponent , CommonModule , ConfirmDeleteComponent , AddTripComponent],
  templateUrl: './trip.component.html',
  styleUrl: './trip.component.scss'
})
export class TripComponent {
  tableControls: TableControls = { data: [] };
  searchText: string = '';
  pageNumber: number = 1;
  itemsPerPage: number = 25;
  order:{order_type?:string,order_by?:string}={}
  currLang: string = 'en';
  loading!: boolean;
  filter :Filter= {};
  selectedCheckBoxes: number[] = [];

  from!: number;
  to!: number;
  total!: number;

  dataArray: Trip[] = [];
  actions = [
    {
      type: 'add',
      name: 'trip.add-new',
    },
    {
      type: 'del',
      name: 'trip.delete',
    },
  ];
  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private tripService: TripService,

    private errorMsgService: ErrorMsgService,
    private toastr: ToastrService,
    private renderer: Renderer2
  ) {
    this.globalService.getLang().subscribe((lng) => {
      this.currLang = lng ? lng : "en";
      this.translate.use(this.currLang);
    });

    this.translate.onLangChange.subscribe((res) => {
      this.currLang = res.lang ? res.lang : "en";
      this.translate.use(this.currLang);
      this.setTableData(this.dataArray);
    });
    this.tripService.getLoadTripsData().subscribe((res) => {
      if (res) {
        this.getTripsData();
      }
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.searchText = params['search'] || '';

      this.filter = {
        trips: {
          name: this.searchText.trim().toLowerCase(),
          operator: 'or',
        },
      };
      if(params['order_by']&&params['order_type']){
        this.order={
          order_by:params['order_by'],
          order_type:params['order_type'],
        }
      }
      this.pageNumber = +params['page'] || 1;
      this.itemsPerPage = params['show'] || 25;
      this.getTripsData();
    });
  }
  setTableData(data: Trip[]) {
    this.tableControls = {
      selection: true,
      paging: true,
      title:'table-view.titles.trip',
      selectedTitle:'table-view.selected-titles.trip',
      totalTitle:'table-view.total-titles.trip',
      pagination: {
        from: this.from,
        to: this.to,
        total: this.total,
      },
      columns: [
        {
          name: this.translate.instant('trip.trip-name'),
          sort: true,
          type: 'string',
          source: 'name',
        },
        {
          name: this.translate.instant('trip.bus-name'),
          sort: false,
          type: 'string',
          source: 'bus_name',
        },
        {
          name: this.translate.instant('trip.area-name'),
          sort: false,
          type: 'string',
          source: 'area',
        },
        {
          name: this.translate.instant('trip.driver-name'),
          sort: false,
          type: 'string',
          source: 'driver_name',
        },
        {
          name: this.translate.instant('trip.supervisor-name'),
          sort: false,
          type: 'string',
          source: 'supervisor_name',
        },
        {
          name: this.translate.instant('trip.capacity'),
          sort: false,
          type: 'string',
          source: 'capacity',
        },
      ],
      data: data,
    };
  }
  tableEventHandeler(event: { action: string; target: any }) {
    switch (event.action) {
      case 'rowClicked':
        this.rowClicked(event.target);
        break;
      case 'checkboxChanged':
        this.selectChanged(event.target);
        break;
      case 'sorting':
        this.sorting(event.target);
        break;
      case 'pagination':
        this.paginationHandling(event.target);
        break;
      case 'del':
        this.showDeleteConfirmation();
        break;
      case 'add':
        this.addTrip();
        break;
    }
  }
  sorting(target: { order: string; source: string }) {

    this.router.navigate(['layout/management/trip'], {
      queryParams: { order_type : target.order,order_by :target.source  },
      queryParamsHandling: 'merge',
    });
  }

  rowClicked(event:  { index: number; data: Trip }) {
    this.tripService
      .getTripById(event.data.id, ['trip_passengers.subscription'])
      .subscribe((res) => {
        const trip = res.data.trip;
        if (trip.way == 'to_home') {
          //sort by drop order
          trip.trip_passengers.sort(
            (a: TripPassenger, b: TripPassenger) =>
              a.drop_order! - b.drop_order!
          );
        } else {
          //sort by pick order
          trip.trip_passengers.sort(
            (a: TripPassenger, b: TripPassenger) =>
              a.pick_order! - b.pick_order!
          );
        }
        this.tripService.setTripData(trip, 'show');
        this.showAddTripModal();
      });
  }

  showAddTripModal(){
    const modal = this.renderer.selectRootElement('#addTripModal', true);
    this.renderer.setStyle(modal, 'display', 'block');
    this.renderer.addClass(modal, 'show');
  }
  selectChanged(event:  {
    index: number | 'all';
    data?: Trip;
    checked: boolean;
  }) {
    let index = event.index;
    let row = event.data;

    let checked = event.checked;
    if (checked) {
      // checked
      if (index == 'all') {
        this.selectedCheckBoxes = this.dataArray.map(
          (element: Trip) => element.id!
        );
      } else {
        this.selectedCheckBoxes.push(row?.id!);
      }
    } else {
      // unchecked
      if (index == 'all') {
        this.selectedCheckBoxes = [];
      } else {
        let checkboxIndex = this.selectedCheckBoxes.indexOf(row?.id!);

        this.selectedCheckBoxes.splice(checkboxIndex, 1);
      }
    }
  }

  paginationHandling(event: { dir: number }) {
    this.pageNumber += event.dir;
    this.router.navigate(['layout/management/trip'], {
      queryParams: { page: this.pageNumber },
      queryParamsHandling: 'merge',
    });
  }
  getTripsData() {
    this.loading = true;
    this.tripService
      .getTrips(
        this.pageNumber,
        this.itemsPerPage,
        ['area', 'bus', 'driver', 'supervisor'],
        this.filter,this.order
      )
      .pipe(map((res) => res['data'].trips))

      .subscribe(
        (res) => {
          this.dataArray = res['data'].map((element: Trip) => {
            return {
              name: element.name,
              id: element.id,
              bus_name: element?.bus.name,
              area: element?.area?.name,
              driver_name: element?.driver.name,
              supervisor_name: element?.supervisor?.name,
              capacity: element.capacity,
            };
          });
          this.from = res['from'];
          this.to = res['to'];
          this.total = res['total'];
          this.selectedCheckBoxes = [];
          this.setTableData(this.dataArray);
          this.loading = false;
        },
        (error) => {
          this.errorMsgService.setMessage(error.error.Error[0]);
        }
      );
  }

  onSeachFn(text: any) {
    this.searchText = text;
    this.pageNumber=1;
    this.router.navigate(['layout/management/trip'], {
      queryParams: { page:this.pageNumber, search: this.searchText },
      queryParamsHandling: 'merge',
    });
  }
  setItemsPerPage($event: number) {
    this.itemsPerPage = $event;
    this.pageNumber = 1;
    this.router.navigate(['layout/management/trip'], {
      queryParams: { show: this.itemsPerPage, page: this.pageNumber },
      queryParamsHandling: 'merge',
    });
  }
  addTrip() {
    this.showAddTripModal();
  }

  showDeleteConfirmation() {
    const modal = this.renderer.selectRootElement('#confirm-delete-popup', true);
    this.renderer.setStyle(modal, 'display', 'block');
    this.renderer.addClass(modal, 'show');
  }

  hideDeleteConfirmation() {
    const modal = this.renderer.selectRootElement('#confirm-delete-popup', true);
    this.renderer.setStyle(modal, 'display', 'none');
    this.renderer.removeClass(modal, 'show');
  }

  deleteTrips() {
    let currPage = this.pageNumber;

    if (this.selectedCheckBoxes.length == this.dataArray.length) {
      this.pageNumber--;
      this.pageNumber = this.pageNumber == 0 ? 1 : this.pageNumber;
    }
    this.tripService.deleteTrips(this.selectedCheckBoxes)
    .subscribe(
      (res) => {
        if (currPage == this.pageNumber) {

        this.getTripsData();
        }else{

          this.router.navigate(['layout/management/trip'], {
            queryParams: { page: this.pageNumber },
            queryParamsHandling: 'merge',
          });
        }
        this.toastr.success(this.deleteToastrMessage(),this.deleteToastrTitle(),{
          positionClass:'toast-top-center'
        });
      },
      (error) => {
        this.hideDeleteConfirmation();
        this.errorMsgService.setMessage(error.error.Error[0]);
      },()=>{
        this.hideDeleteConfirmation();
      }
    );
  }

  deleteToastrMessage():string{
    return this.currLang == 'en' ? 'Deleted Successfully' : 'تم الحذف بنجاح';
  }
  deleteToastrTitle():string{
    return this.currLang == 'en' ? 'Trips Deletion' : 'حذف الرحلات';
  }

}
