<div class="modal-wrapper">
    <div class="content-wrapper">
        <form [formGroup]="userForm" *ngIf="!loading">
            <div class="img-section">
                <div *ngIf="!image" class="modal-no-image">
                    <img src="../../../../../assets/images/svg/admin_profile.svg" alt="" />
                </div>
                <div class="modal-image">
                    <img class="supervisior-image" *ngIf="image" src="{{ image }}" />
        
                </div>
        
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <mat-form-field class="w-100" appearance="outline" dir="ltr">
                        <mat-label>{{
                            "profile.name" | translate
                            }}</mat-label>
                        <input matInput formControlName="name" [readonly]="!editMode" />
                        <mat-error>{{ getValidationMessage("name") }}</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-lg-6">
                    <mat-form-field class="w-100" appearance="outline" dir="ltr">
                        <mat-label>{{
                            "profile.company_name" | translate
                            }}</mat-label>
                        <input matInput [value]="userData?.company.name" disabled="true" />
                        <!-- <mat-error>{{ getValidationMessage("company_name") }}</mat-error> -->
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <mat-form-field class="w-100" appearance="outline" dir="ltr">
                        <mat-label>{{
                            "profile.email" | translate
                            }}</mat-label>
                        <input matInput [value]="userData.email" disabled="true" />
                        <!-- <mat-error>{{ getValidationMessage("email") }}</mat-error> -->
                    </mat-form-field>
                </div>
        
            </div>
            <button class="btn btn-link text-decoration-none" (click)="handleChangeAction()" *ngIf="!editMode">
                {{"profile.change_password"|translate}} </button>
        </form>
        <div *ngIf="loading" class="loading">
            <mat-spinner *ngIf="true" mode="indeterminate"></mat-spinner>
        </div>
    </div>

    <div class="modal-footer" *ngIf="editMode">
        <button type="button" class="btn btn-primary save-btn" (click)="saveInfo()" [disabled]="userForm.invalid">
            {{ "profile.save" | translate }}
        </button>
    </div>
</div>