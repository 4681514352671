<div class="daily-delay-report-wrapper">
    <div class="title">
        <svg style="cursor: pointer;" [routerLink]="['../dashboard-view']"
            [ngStyle]="{'transform': currLang == 'en' ? 'rotate(0)' : 'rotate(180deg)'}"
            xmlns="http://www.w3.org/2000/svg" width="8" height="15" viewBox="0 0 8 15" fill="none">
            <path
                d="M0.0020558 7.45495C0.001568 7.20616 0.0885231 6.96505 0.247827 6.77348L5.59069 0.384663C5.77207 0.167212 6.0327 0.0304653 6.31526 0.00450566C6.59782 -0.0214539 6.87915 0.0654999 7.09737 0.246238C7.3156 0.426976 7.45283 0.686694 7.47888 0.968256C7.50493 1.24982 7.41767 1.53016 7.23629 1.74761L2.44909 7.45495L7.06532 13.1623C7.15408 13.2712 7.22036 13.3965 7.26036 13.5311C7.30036 13.6656 7.31328 13.8067 7.29839 13.9462C7.28349 14.0857 7.24107 14.2209 7.17357 14.344C7.10606 14.4672 7.0148 14.5758 6.90503 14.6637C6.79516 14.7612 6.66626 14.835 6.52641 14.8806C6.38656 14.9262 6.23878 14.9425 6.09232 14.9285C5.94586 14.9145 5.80387 14.8705 5.67526 14.7993C5.54665 14.7281 5.43419 14.6311 5.34492 14.5146L0.183713 8.12578C0.0495902 7.9287 -0.0143689 7.69251 0.0020558 7.45495Z"
                fill="#475467" />
        </svg>
        {{'new-dashboard.reports.daily-delay.title' | translate}}
    </div>

    <div class="control-bar-container">
        <app-reports-control-bar [periodFilterOptions]="periodPickerOptions" [view]="'daily-delay'"
            (optionSelected)="periodFilterChanged($event)" (dateChanged)="dateChanged($event)"
            (searchChanged)="searchChanged($event)"></app-reports-control-bar>
    </div>

    <app-table-view [loading]="loading" [showExport]="true" [controls]="tableControls" (eventHandeler)="tableEventHandeler($event)"
        [currentView]="rowsView"></app-table-view>
</div>