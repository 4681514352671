<div class="modal-container" [class.rtl]="currLang == 'ar'">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">
                {{ "subscribers.add-subscriber-modal.subscriber-details" | translate }}
            </h5>
            <div class="header-btns">
                <svg (click)="closeModal()" xmlns="http://www.w3.org/2000/svg" width="14" height="16"
                    viewBox="0 0 14 16" fill="none">
                    <path d="M6.94989 8L12.8998 15" stroke="#707070" stroke-width="2" stroke-linecap="round" />
                    <path d="M6.94977 8L0.999873 15" stroke="#707070" stroke-width="2" stroke-linecap="round" />
                    <path d="M6.94989 8L12.8998 1" stroke="#707070" stroke-width="2" stroke-linecap="round" />
                    <path d="M6.94977 8L0.999873 1" stroke="#707070" stroke-width="2" stroke-linecap="round" />
                </svg>
            </div>
        </div>
        <div class="modal-body">
            <form [id]="formFlag === 'edit' ? 'subscriberForm' : null" [formGroup]="subscriberForm">
                <!-- view state -->
                <div class="row view-info">
                    <div class="info-pic-wrapper">
                        <div class="pic-name">
                            <div class="img-section">
                                <div *ngIf="!image" class="modal-no-image">
                                    <img src="../../../../../../assets/images/svg/person.svg" alt="" />
                                </div>
                                <div class="modal-image">
                                    <img class="subscriber-image" *ngIf="image" src="{{ image }}" />
                                    <!-- <img src="../../../../../../assets/images/svg/camera.svg" alt="camera"
                                        *ngIf="formFlag === 'edit' || formFlag === 'add'"
                                        (click)="changeDriverProfile()" id="camera" style="top: -2rem; right: auto" /> -->
                                    <!-- [ngStyle]="{
                            top: formFlag == 'add' ? '-2rem' : '0',
                            right: formFlag == 'add' ? 'auto' : '0'
                            }" -->
                                </div>
                                <!-- <input (change)="onFileSelected($event)" id="fileUpload" type="file"
                                    style="display: none;" multiple="multiple" [disabled]="fieldsReadOnly"> -->
                            </div>
                            <div class="info-item">
                                <div class="label">{{"subscribers.add-subscriber-modal.name" | translate }} :</div>
                                <div class="value">{{ subscriberForm.get('name')?.value }}</div>
                            </div>
                        </div>
                        <div class="edit-btn-wrapper" *ngIf="formFlag == 'view'">
                            <button class="btn btn-primary edit-btn" (click)="editSubscriber()">
                                {{ "drivers.add-driver-modal.edit" | translate }}
                            </button>
                        </div>
                    </div>
                    <label class="section-header" [ngStyle]="{ 'text-align': currLang == 'en' ? 'left' : 'right' }">{{
                        "subscribers.add-subscriber-modal.subscriber-info" | translate }}</label>
                    <div class="info-details-wrapper">
                        <div class="info-item">
                            <div class="label">{{"subscribers.add-subscriber-modal.phone_no" | translate }} :</div>
                            <div class="value">{{ subscriberForm.get('contry_code')?.value }}{{
                                subscriberForm.get('phone_no')?.value }}</div>
                        </div>
                        <div class="info-item">
                            <div class="label">{{"subscribers.add-subscriber-modal.school-name" | translate }} :</div>
                            <div class="value">{{ subscriberForm.get('school_name')?.value }}</div>
                        </div>
                    </div>
                </div>


                <div class="info-section">
                    <div class="row">
                        <div class="map-container col-lg-12">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="search-map-section form-group" [hidden]="!markerState">
                                        <label
                                            class="section-header">{{'subscribers.add-subscriber-modal.map.search.label'
                                            |
                                            translate}}</label>
                                        <mat-form-field appearance="outline" dir="ltr">
                                            <input matInput [(ngModel)]="searchSubscriberLocation"
                                                [ngModelOptions]="{standalone: true}"
                                                (keydown.enter)="$event.preventDefault()"
                                                placeholder="{{'subscribers.add-subscriber-modal.map.search.placeholder' | translate}}"
                                                autocorrect="off" autocapitalize="off" spellcheck="off" type="text"
                                                #searchSubLoc />
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="map-section">
                                <!-- agm-map -->
                                <google-map #subscriberGoogleMap height="400px" width="100%" [zoom]="11"
                                    [center]="center" [options]="options">
                                    <map-marker *ngFor="let marker of markers; index as studentIndex"
                                        [position]="marker?.position" [options]="marker?.options"
                                        (mapDragend)="markerDragEnd($event)">
                                    </map-marker>
                                </google-map>
                                <button *ngIf="markerState" class="currentLocation" (click)="getCurrentLocation()">
                                    <svg style="transform: scale(-0.2, -.2);" version="1.1" id="Layer_1"
                                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                        x="0px" y="0px" viewBox="0 0 122.88 122.88"
                                        style="enable-background:new 0 0 122.88 122.88" xml:space="preserve">
                                        <g>
                                            <path
                                                d="M68.23,13.49c10.44,1.49,19.79,6.36,26.91,13.48c7.29,7.29,12.23,16.93,13.58,27.68h14.17v13.58h-14.39 c-1.62,10.13-6.42,19.2-13.36,26.13c-7.11,7.11-16.47,11.99-26.91,13.48v15.04H54.65v-15.04c-10.44-1.49-19.79-6.36-26.9-13.48 c-6.94-6.94-11.74-16-13.36-26.13H0V54.65h14.16c1.35-10.75,6.29-20.39,13.58-27.68c7.11-7.11,16.46-11.99,26.9-13.48V0h13.58 V13.49L68.23,13.49z M61.44,35.41c13.95,0,25.25,11.31,25.25,25.25c0,13.95-11.31,25.25-25.25,25.25 c-13.95,0-25.25-11.31-25.25-25.25C36.19,46.72,47.49,35.41,61.44,35.41L61.44,35.41z M89,33.11c-7.05-7.05-16.8-11.42-27.56-11.42 c-10.76,0-20.51,4.36-27.56,11.42c-7.05,7.05-11.42,16.8-11.42,27.56c0,10.76,4.36,20.51,11.42,27.56 c7.05,7.05,16.8,11.42,27.56,11.42c10.76,0,20.51-4.36,27.56-11.42c7.05-7.05,11.42-16.8,11.42-27.56 C100.41,49.9,96.05,40.16,89,33.11L89,33.11z"
                                                fill="#707070" />
                                        </g>
                                    </svg>
                                </button>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="map-address-section">
                                        <label class="section-header">{{'subscribers.add-subscriber-modal.map.address' |
                                            translate}}</label>
                                        <div class="address">
                                            {{address}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="modal-footer" [class.none]="formFlag == 'view'">
            <div class="btns-wrapper">
                <button type="button" (click)="closeModal()" class="btn btn-primary cancel-btn">
                    {{ "subscribers.add-subscriber-modal.cancel" | translate }}
                </button>
                <button type="button" class="btn btn-primary save-btn" [disabled]="subscriberForm.invalid" #saveBtn
                    (click)="saveSubscriber()">
                    {{ "subscribers.add-subscriber-modal.save" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>