import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TabsComponent } from '../../shared/components/tabs/tabs.component';

@Component({
  selector: 'app-management',
  standalone: true,
  imports: [RouterModule,TabsComponent],
  templateUrl: './management.component.html',
  styleUrl: './management.component.scss'
})
export class ManagementComponent {
  tabs: any = [
    { link: 'trip', name: 'Trip', name_ar: 'الرحله' },
    { link: 'areas', name: 'Areas', name_ar: 'المناطق' },
    { link: 'buses', name: 'Buses', name_ar: 'الحافلات' },
    { link: 'drivers', name: 'Drivers', name_ar: 'السائقين' },
    { link: 'supervisors', name: 'Supervisors', name_ar: 'المشرفين' },
    { link: 'subscribers', name: 'Subscribers', name_ar: 'المشتركين' },
  ];
  constructor() {}

  ngOnInit(): void {}
}

