import { Component, Renderer2 } from '@angular/core';
import { Filter } from '../../../models/filter.model';
import { TableControls } from '../../../models/table-controls.model';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../../../shared/services/global.service';
import { ErrorMsgService } from '../../../shared/services/error-msg.service';
import { map } from 'rxjs';
import { Area } from '../../../models/area.model';
import { AreasService } from '../_services/areas.service';
import { ToastrService } from 'ngx-toastr'
import { CommonModule } from '@angular/common';
import { ControlBarComponent } from '../../../shared/components/control-bar/control-bar.component';
import { TableViewComponent } from '../../../shared/components/table-view/table-view.component';
import { AddAreaComponent } from './add-area/add-area.component';
import { ConfirmDeleteComponent } from '../../../shared/components/confirm-delete/confirm-delete.component';
declare var $: any;

@Component({
  selector: 'app-areas',
  standalone: true,
  imports: [CommonModule, ControlBarComponent, TableViewComponent, AddAreaComponent, ConfirmDeleteComponent],
  templateUrl: './areas.component.html',
  styleUrl: './areas.component.scss'
})
export class AreasComponent {
  tableControls: TableControls = { data: [] };
  searchText: string = '';
  pageNumber: number = 1;
  itemsPerPage: number = 25;
  //TODO sorting need handling from backend
  order: { order_type?: string; order_by?: string } = {};
  currLang: string = 'en';
  loading!: boolean;
  filter?: Filter = {};
  selectedCheckBoxes: number[] = [];
  from!: number;
  to!: number;
  total!: number;

  dataArray: Area[] = [];
  actions = [
    {
      type: 'add',
      name: 'areas.add-new',
    },
    {
      type: 'del',
      name: 'areas.delete',
    },
  ];
  isAddAreaModalOpen = false;
  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private globalService: GlobalService,
    private areaService: AreasService,

    private errorMsgService: ErrorMsgService,
    private toastr: ToastrService,
    private renderer: Renderer2

  ) {
    this.globalService.getLang().subscribe((lng) => {
      this.currLang = lng ? lng : "en";
      this.translate.use(this.currLang);
    });

    this.translate.onLangChange.subscribe((res) => {
      this.currLang = res.lang ? res.lang : "en";
      this.translate.use(this.currLang);
      this.setTableData(this.dataArray);
    });
    this.areaService.getLoadAreasData().subscribe((res: any) => {
      if (res) {
        this.getAreasData();
      }
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.searchText = params['search'] || '';

      this.filter = {
        areas: {
          name: this.searchText.trim().toLowerCase(),
          code: this.searchText.trim().toLowerCase(),
          operator: 'or',
        },
      };
      if (params['order_by'] && params['order_type']) {
        this.order = {
          order_by: params['order_by'],
          order_type: params['order_type'],
        };
      }
      this.pageNumber = +params['page'] || 1;
      this.itemsPerPage = params['show'] || 25;
      this.getAreasData();
    });
  }
  getAreasData() {
    this.loading = true;
    this.areaService
      .getAreas(
        this.pageNumber,
        this.itemsPerPage,
        ['trips'],
        this.filter,
        this.order
      )
      .pipe(map((res: any) => res['data'].areas))
      .subscribe(
        (res: any) => {
          let dataArray: Area[] = [];
          res['data'].forEach((element: Area) => {
            dataArray.push({
              color: element.color,
              name: element.name,
              code: element.code,
              id: element.id,
              trips_count: element?.trips?.length
            });
          });
          this.dataArray = dataArray;
          this.from = res['from'];
          this.to = res['to'];
          this.total = res['total'];
          this.selectedCheckBoxes = [];
          this.setTableData(dataArray);
          this.loading = false;
        },
        (error: any) => {
          this.errorMsgService.setMessage(error.error.Error[0]);
        }
      );
  }

  setTableData(data: Area[]) {
    this.tableControls = {
      selection: true,
      paging: true,
      title: 'table-view.titles.area',
      selectedTitle: 'table-view.selected-titles.area',
      totalTitle: 'table-view.total-titles.area',
      pagination: {
        from: this.from,
        to: this.to,
        total: this.total,
      },
      columns: [
        // {
        //   name: this.translate.instant('areas.color'),
        //   sort: false,
        //   type: 'color',
        //   source: 'color',
        // },
        {
          name: this.translate.instant('areas.name'),
          sort: true,
          type: 'string',
          source: 'name',
        },
        {
          name: this.translate.instant('areas.code'),
          sort: true,
          type: 'string',
          source: 'code',
        },
        {
          name: this.translate.instant('areas.trips_count'),
          sort: false,
          type: 'string',
          source: 'trips_count',
        },
      ],
      data: data,
    };
  }
  tableEventHandeler(event: { action: string; target: any }) {
    switch (event.action) {
      case 'rowClicked':
        this.rowClicked(event.target);
        break;
      case 'checkboxChanged':
        this.selectChanged(event.target);
        break;
      case 'sorting':
        this.sorting(event.target);
        break;
      case 'pagination':
        this.paginationHandling(event.target);
        break;
      case 'del':
        this.showDeleteConfirmation();
        break;
      case 'add':
        this.addArea();
        break;
    }
  }
  sorting(target: { order: string; source: string }) {

    this.router.navigate(['layout/management/areas'], {
      queryParams: { order_type: target.order, order_by: target.source },
      queryParamsHandling: 'merge',
    });
  }

  rowClicked(event: { index: number; data: Area }) {
    if (this.isAddAreaModalOpen) {
      return; // Prevent opening multiple modals
    }
    this.isAddAreaModalOpen = true; // Set flag to indicate modal is open
    this.areaService.getAreaById(event.data.id!, ['trips']).subscribe((res: any) => {
      this.areaService.setAreaData(res.data.area, 'show');

      const modalRef = this.modalService.open(AddAreaComponent, { size: 'xl' });

      modalRef.result.finally(() => {
        this.isAddAreaModalOpen = false; // Reset flag when modal is closed
      });
    }, () => {
      this.isAddAreaModalOpen = false; // Reset flag in case of error
    });
  }

  selectChanged(event: {
    index: number | 'all';
    data?: Area;
    checked: boolean;
  }) {
    let index = event.index;
    let row = event.data;

    let checked = event.checked;
    if (checked) {
      // checked
      if (index == 'all') {
        this.selectedCheckBoxes = this.dataArray.map(
          (element: Area) => element.id!
        );
      } else {
        this.selectedCheckBoxes.push(row?.id!);
      }
    } else {
      // unchecked
      if (index == 'all') {
        this.selectedCheckBoxes = [];
      } else {
        let checkboxIndex = this.selectedCheckBoxes.indexOf(row?.id!);

        this.selectedCheckBoxes.splice(checkboxIndex, 1);
      }
    }
  }

  paginationHandling(event: { dir: number }) {
    this.pageNumber += event.dir;
    this.router.navigate(['layout/management/areas'], {
      queryParams: { page: this.pageNumber },
      queryParamsHandling: 'merge',
    });
  }

  onSeachFn(text: any) {
    this.searchText = text;
    this.pageNumber = 1;
    this.router.navigate(['layout/management/areas'], {
      queryParams: { page: this.pageNumber, search: this.searchText },
      queryParamsHandling: 'merge',
    });
  }
  setItemsPerPage($event: number) {
    this.itemsPerPage = $event;
    this.pageNumber = 1;
    this.router.navigate(['layout/management/areas'], {
      queryParams: { show: this.itemsPerPage, page: this.pageNumber },
      queryParamsHandling: 'merge',
    });
  }
  addArea() {
    this.modalService.open(AddAreaComponent, { size: 'xl' });
  }
  showDeleteConfirmation() {
    const modal = this.renderer.selectRootElement('#confirm-delete-popup', true);
    this.renderer.setStyle(modal, 'display', 'block');
    this.renderer.addClass(modal, 'show');
  }

  hideDeleteConfirmation() {
    const modal = this.renderer.selectRootElement('#confirm-delete-popup', true);
    this.renderer.setStyle(modal, 'display', 'none');
    this.renderer.removeClass(modal, 'show');
  }

  deleteAreas() {
    let currPage = this.pageNumber;
    if (this.selectedCheckBoxes.length == this.dataArray.length) {
      this.pageNumber--;
      this.pageNumber = this.pageNumber == 0 ? 1 : this.pageNumber;
    }
    this.areaService.deleteAreas(this.selectedCheckBoxes).subscribe(
      (res: any) => {
        if (currPage == this.pageNumber) {
          this.getAreasData();
        } else {
          this.router.navigate(['layout/management/areas'], {
            queryParams: { page: this.pageNumber },
            queryParamsHandling: 'merge',
          });
        }
        this.toastr.success(this.deleteToastrMessage(), this.deleteToastrTitle(), {
          positionClass: 'toast-top-center'
        });
      },
      (error: any) => {
        this.hideDeleteConfirmation();
        this.errorMsgService.setMessage(error.error.Error[0]);
      }, () => {
        this.hideDeleteConfirmation();
      }
    );
  }

  deleteToastrMessage(): string {
    return this.currLang == 'en' ? 'Deleted Successfully' : 'تم الحذف بنجاح';
  }
  deleteToastrTitle(): string {
    return this.currLang == 'en' ? 'Areas Deletion' : 'حذف المناطق';
  }

}
