import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Area } from '../../../models/area.model';

environment
@Injectable({
  providedIn: 'root',
})
export class AreasService {
  constructor(private http: HttpClient) {}
  private _AreaData = new BehaviorSubject<any>(null);
  AreaData = this._AreaData.asObservable();

  setAreaData(area: Area | null , flag: string) {
    this._AreaData.next({ area: area, flag: flag });
  }

  getAreaData() {
    return this._AreaData;
  }
  private _LoadAreasData = new BehaviorSubject<any>(null);
  LoadAreasData = this._LoadAreasData.asObservable();

  setLoadAreasData(load: boolean) {
    this._LoadAreasData.next(load);
  }

  getLoadAreasData() {
    return this._LoadAreasData;
  }

  getAreas(page: number, page_size: number, related_objects: string[], filters: any,order:any) {
    let body = {
      page,
      page_size,
      related_objects,
      filters,
      order
    };

    return this.http.post<any>(
      environment.url + 'backoffice/area/getAreas',
      body
    );
  }
  // page_size":2,"related_objects":[],"filters":{}
  addArea(body: Area) {
    return this.http.post<any>(
      environment.url + 'backoffice/area/createArea',
      body
    );
  }
  editArea(body: Area) {
    return this.http.put<any>(
      environment.url + 'backoffice/area/updateArea',
      body
    );
  }
  getAreaById(id: number, related: string[]) {
    let params: any = { area_id: id };
    related.forEach((el: any, i: number) => {
      params[`related_objects[${i}]`] = el;
    });

    return this.http.get<any>(
      ` ${environment.url}backoffice/area/getAreaById`,
      { params }
    );
  }
  deleteAreas(areasIDs: number[]) {
    // let params = new HttpParams();
    let params: any = {};
    if (areasIDs.length > 0) {
      areasIDs.forEach((el, i) => {
        params[`area_ids[${i}]`] = el;
      });
    }
    return this.http.post<any>(
      ` ${environment.url}backoffice/area/deleteAreas`,
      {
        area_ids: areasIDs,
      }
    );
  }
}
