import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../services/user.service';
import { ErrorMsgService } from '../../../services/error-msg.service';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ValidatorComponent } from './validator/validator.component';
import { MatIconModule } from '@angular/material/icon';
import { CustomValidator } from '../../../utilities/custom-validator';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [TranslateModule , CommonModule  , MatFormFieldModule , ReactiveFormsModule , ValidatorComponent , MatIconModule , MatInputModule],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss'
})
export class ChangePasswordComponent {
  changePasswordForm!: UntypedFormGroup;
  hideOldPass = true;
  hideNewPass = true;
  @Input() currLang = 'en';
  @Output() closeModal = new EventEmitter<any>()
  constructor(private translate: TranslateService,
    private userService: UserService,
    private errorMsgService: ErrorMsgService
  ) { }

  ngOnInit() {
    this.changePasswordForm = new UntypedFormGroup({
      old_password: new UntypedFormControl('', Validators.required),
      new_password: new UntypedFormControl('', [
        Validators.minLength(8),
        CustomValidator.patternValidator(/\d/, { hasNumber: true }),
        CustomValidator.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        CustomValidator.patternValidator(/[a-z]/, { hasSmallCase: true }),
      ]),

    });
  }
  get new_password() {
    return this.changePasswordForm.controls['new_password']
  }
  getValidationMessage(control: string) {
    let errorMsg;
    if (this.changePasswordForm.controls[control].errors) {
      const fieldName = this.translate.instant(
        'profile.' + control
      );
      const requiredText = this.translate.instant(
        'profile.required'
      );
      const invalidText = this.translate.instant(
        'profile.invalid'
      );
      errorMsg = this.changePasswordForm.controls[control].errors?.['required']
        ? `${fieldName + requiredText}`
        : `${fieldName + invalidText}`;
    }
    return errorMsg;
  }
  handleChangePassword() {
    this.userService.updatePassword(this.changePasswordForm.value).subscribe(res => {
      this.closeModal.emit()

    }, err => {
      this.errorMsgService.setMessage(err.error.Error[0]);

    })
  }

}
