import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../services/global.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { UserInfoComponent } from './user-info/user-info.component';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [TranslateModule, CommonModule , ChangePasswordComponent , UserInfoComponent],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent {
  currLang!: string;
  editMode = false;


  currentAction = 'info';

  constructor(private translate: TranslateService,
    private globalService: GlobalService,
    public activeModal: NgbActiveModal,

  ) {
    this.globalService.getLang().subscribe((lng) => {
      this.currLang = lng ? lng : "en";
      this.translate.use(this.currLang);
    });

    this.translate.onLangChange.subscribe((res) => {
      this.currLang = res.lang ? res.lang : "en";
      this.translate.use(this.currLang);
    });
  }

  ngOnInit() {

  }

  initForm() {


  }
  onNoClick(): void {
    this.activeModal.close();
  }
  enableEdit() {
    this.editMode = true
  }
  back() {
    this.currentAction = 'info'
  }

}
