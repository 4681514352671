import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Trip } from '../../../../models/trip.model';
import { AreasService } from '../../_services/areas.service';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../../../../shared/services/global.service';
import { ErrorMsgService } from '../../../../shared/services/error-msg.service';
import { ArabicNumberPipe } from "../../../../shared/pipes/arabic-number.pipe";

@Component({
    selector: 'app-add-area',
    standalone: true,
    templateUrl: './add-area.component.html',
    styleUrl: './add-area.component.scss',
    imports: [CommonModule, TranslateModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, ArabicNumberPipe]
})
export class AddAreaComponent {
  formArea!: UntypedFormGroup;
  currLang!: string;
  modalTitle!: string;
  formFlag: string = 'add';
  loading!: boolean;
  trips: Trip[] = [];
  fieldsReadOnly: boolean = false;
  id!: number;
  @ViewChild('saveBtn', { static: false }) saveBtn!: ElementRef;
  constructor(
    private areaService: AreasService,
    private translate: TranslateService,
    public activeModal: NgbActiveModal,
    private globalService: GlobalService,
    private errorMsgService: ErrorMsgService
  ) {
    this.globalService.getLang().subscribe((lng) => {
      this.currLang = lng ? lng : "en";
      this.translate.use(this.currLang);
    });

    this.translate.onLangChange.subscribe((res) => {
      this.currLang = res.lang ? res.lang : "en";
      this.translate.use(this.currLang);
    });
  }

  ngOnInit(): void {
    this.formArea = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      // color: new UntypedFormControl('#000000'),
      code: new UntypedFormControl(''),
    });
    // this.formArea.controls['color'].enable();

    this.areaService.getAreaData().subscribe((res) => {
      if (res?.area != null) {
        this.formFlag = 'view';
        this.fieldsReadOnly = true;
        this.id = res.area.id;
        // this.formArea.controls['color'].setValue(res.area.color);
        // this.formArea.controls['color'].disable();
        this.formArea.controls['name'].setValue(res.area.name);
        this.formArea.controls['code'].setValue(res.area.code);
        this.trips = res.area.trips;
      }
    });
  }
  // ngAfterViewInit(): void {
  //   fromEvent(this.saveBtn?.nativeElement, 'click')
  //     .pipe(
  //       debounceTime(300),
  //       concatMap(() => this.saveArea())
  //     )
  //     .subscribe(
  //       () => {
  //         this.areaService.setLoadAreasData(true);
  //         this.closeModal();
  //       },
  //       (error) => {
  //         this.errorMsgService.setMessage(error.error.Error[0]);
  //         this.closeModal();
  //       }
  //     );
  // }

  closeModal() {
    this.activeModal.close('Close click');
  }
  getValidationMessage(control: string) {
    let errorMsg;
    if (this.formArea.controls[control].errors) {
      const fieldName = this.translate.instant(
        'areas.add-area-modal.' + control
      );
      const requiredText = this.translate.instant(
        'areas.add-area-modal.required'
      );
      const invalidText = this.translate.instant(
        'areas.add-area-modal.invalid'
      );
      errorMsg = this.formArea.controls[control].errors?.['required']
        ? `${fieldName + requiredText}`
        : `${control + invalidText}`;
    }
    return errorMsg;
  }

  editArea() {
    this.formFlag = 'edit';
    // this.formArea.controls['color'].enable();
    this.fieldsReadOnly = false;
  }

  saveArea() {
    let sub;
    let data = this.formArea.value;
    if (this.formFlag == 'add') {
      sub= this.areaService.addArea(data);
    } else {
      sub= this.areaService.editArea({ ...data, area_id: this.id });
      }
      sub.subscribe(
        () => {
          this.areaService.setLoadAreasData(true);
          this.closeModal();
        },
        (error) => {
          this.errorMsgService.setMessage(error.error.Error[0]);
        })
    
  }
  //TODO
  convertTime(time: string) {
    return this.globalService.convertTime(time);
  }

  ngOnDestroy() {
    this.areaService.setAreaData(null, 'add');
  }

}
