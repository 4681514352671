import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ExportNotificationService , Notification} from './export-notification.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExportServiceService {

  constructor(private http: HttpClient, private exportNotificationService$: ExportNotificationService) {}
  // Helper function to get request headers for exporting a PDF
  private getRequestHeadersWithPDFContentType() {
    let headers: HttpHeaders = new HttpHeaders({
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'branch-id': `${localStorage.getItem("branch_id")}`,
      'Accept': 'application/xml'
    });
    return headers;
  }

  // Generalized export method with dynamic parameters and endpoints
  exportFile(
    endpointPath: string, // Relative path for the export endpoint
    statusEndpointPath: string, // Relative path for the status endpoint
    params: any = {}, 
    timeout: number = 15000 // Max time for trying to poll 15 sec
  ) {

    // Add timezone offset to params dynamically
    const timeZoneOffset = parseInt(localStorage.getItem('timeZoneOffset') || '0', 10);
    params.timezone_offset = timeZoneOffset;

    // Convert params to HttpParams for query string
    const queryParams = new HttpParams({ fromObject: params });

    // Use environment URL to construct full export endpoint
    const exportUrl = `${environment.url}${endpointPath}`;

    // Call the export endpoint with custom headers and response type
    this.http.get<any>(exportUrl, {
        params: queryParams,
        headers: this.getRequestHeadersWithPDFContentType(),
      })
      .subscribe((response: any) => {
        const jobId = response?.data?.export?.id;

        const pendingNotification:Notification = {
          id: jobId,
          message: 'Export in progress...',
          status: 'pending'
        };
        // Add "in progress" notification
        this.exportNotificationService$.addNotification(pendingNotification);

        this.pollJobStatus(jobId, statusEndpointPath, timeout);
      }, () => {
        // Handle failure
        // this.exportNotificationService$.updateNotification(jobId, 'failed');
      });
  }
  // Poll the status endpoint until completion or timeout
  private pollJobStatus(jobId: string, statusEndpointPath: string, timeout: number) {
    const startTime = Date.now();
    const pollInterval = 3000; // Poll every 3 seconds
    const statusUrl = `${environment.url}${statusEndpointPath}?export_id=${jobId}`;

    const checkStatus = () => {
      if (Date.now() - startTime >= timeout) {
        this.exportNotificationService$.updateNotification(jobId, 'failed');
        return;
      }

      this.http.get<any>(statusUrl).subscribe((response: any) => {
        const status = response?.data?.export?.status
        if (status === 'finished') {
          // Notify completion with download URL
          const downloadUrl = response?.data?.url;
          this.exportNotificationService$.updateNotification(jobId, 'finished', downloadUrl);
        }
        else if(status === 'failed'){
          this.exportNotificationService$.updateNotification(jobId, 'failed');
        } 
        else {
          // Continue polling if not completed
          setTimeout(checkStatus, pollInterval);
        }
      }, () => {
        // Handle status check failure
        // this.exportNotificationService$.updateNotification(jobId, 'failed');
      });
    };

    checkStatus(); // Start polling
  }
}
