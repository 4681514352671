import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TripService {
  constructor(private http: HttpClient) {}
  private _TripData = new BehaviorSubject<any>(null);
  TripData = this._TripData.asObservable();

  setTripData(trip: any, flag: string) {
    this._TripData.next({ trip: trip, flag: flag });
  }

  getTripData() {
    return this._TripData;
  }
  private _LoadTripsData = new BehaviorSubject<any>(null);
  LoadTripsData = this._LoadTripsData.asObservable();

  setLoadTripsData(load: boolean) {
    this._LoadTripsData.next(load);
  }

  getLoadTripsData() {
    return this._LoadTripsData;
  }

  getTrips(page: any, page_size: any, related_objects: any, filters: any,order:{order_type?:string,order_by?:string}) {
    let body = {
      page,
      page_size,
      related_objects,
      filters,
      order
    };

    return this.http.post<any>(
      environment.url + 'backoffice/trip/getTrips',
      body
    );
  }
  // page_size":2,"related_objects":[],"filters":{}
  createTrip(body: any) {
    return this.http.post<any>(
      environment.url + 'backoffice/trip/createTrip',
      body
    );
  }
  updateTrip(body: any) {
    return this.http.put<any>(
      environment.url + 'backoffice/trip/updateTrip',
      body
    );
  }
  getTripById(id: any, related: any) {
    let params: any = { trip_id: id };
    related.map((el: any, i: number) => {
      params[`related_objects[${i}]`] = el;
    });

    return this.http.get<any>(
      ` ${environment.url}backoffice/trip/getTripById`,
      { params }
    );
  }
  deleteTrips(tripIDs: number[]) {
    // let params = new HttpParams();
    let params: any = {};
    // params.append('area_ids', areasIDs.join(', '));
    if (tripIDs.length > 0) {
      tripIDs.map((el, i) => {
        params[`trip_ids[${i}]`] = el;
      });
    }
    return this.http.post<any>(
      ` ${environment.url}backoffice/trip/deleteTrips`,
      {
        trip_ids: tripIDs,
      }
    );
  }
  getRunningDailyTrips(
    page: any,
    page_size: any,
    related_objects: any,
    filters: any,order:{order_type?:string,order_by?:string}
  ) {
    let body:any = {
      page,
      page_size,
      related_objects,
    
      filters,order
    };
    body['_method']="GET";

    return this.http.post<any>(
      `${environment.url}backoffice/dailyTrip/getRunningDailyTrips?page=${page}&page_size=${page_size}`
   ,body );
  }

  getDailyTripById(id: any, related: any) {
    // let params: any = { daily_trip_id: id };
    // related.map((el: any, i: number) => {
    //   params[`related_objects[${i}]`] = el;
    // });

    return this.http.get<any>(
      ` ${environment.url}backoffice/dailyTrip/getDailyTripByIdWithActiveLogs?daily_trip_id=${id}`,
      // { params }
    );
  }
  getLastBusStatus(id: number) {
    return this.http.get<any>(
      `${environment.url}backoffice/dailyTrip/getLastBusStatus?daily_trip_id=${id}`
    );
  }
}
