import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Supervisior } from '../../../models/supervisior.model';

@Injectable({
  providedIn: 'root'
})
export class SupervisiorService {

  constructor(private http: HttpClient) {}
  private _SupervisiorData = new BehaviorSubject<any>(null);
  SupervisiorData = this._SupervisiorData.asObservable();

  setSupervisiorData(supervisior: Supervisior | null, flag: string) {
    this._SupervisiorData.next({ supervisior: supervisior, flag: flag });
  }

  getSupervisiorData() {
    return this._SupervisiorData;
  }
  private _LoadSupervisiorsData = new BehaviorSubject<any>(null);
  LoadSupervisiorsData = this._LoadSupervisiorsData.asObservable();

  setLoadSupervisiorsData(load: boolean) {
    this._LoadSupervisiorsData.next(load);
  }

  getLoadSupervisiorsData() {
    return this._LoadSupervisiorsData;
  }
  getSupervisiors(page: number, page_size: number, related_objects: string[], filters: any,order:{order_type?: string; order_by?: string }) {
    let body = {
      page,
      page_size,
      related_objects,
      filters,order
    };
    return this.http.post<any>(
      environment.url + 'backoffice/supervisor/getSupervisors',
      body
    );
  }
  addSupervisior(body: Supervisior) {
    return this.http.post<any>(
      environment.url + 'backoffice/supervisor/createSupervisor',
      body
    );
  }
  editSupervisior(body: Supervisior) {
    return this.http.put<any>(
      environment.url + 'backoffice/supervisor/updateSupervisor',
      body
    );
  }
  getSupervisiorById(id: number, related: any) {
    let params: any = { supervisor_id: id };
    related.forEach((el: any, i: number) => {
      params[`related_objects[${i}]`] = el;
    });
    return this.http.get<any>(
      ` ${environment.url}backoffice/supervisor/getSupervisorById`,
      {
        params,
      }
    );
  }
  deleteSupervisiors(supervisorIDs: number[]) {
    let params: any = {};

    if (supervisorIDs.length > 0) {
      supervisorIDs.forEach((el, i) => {
        params[`supervisor_ids[${i}]`] = el;
      });
    }
    return this.http.post<any>(
      ` ${environment.url}backoffice/supervisor/deleteSupervisors`,
      {
        supervisor_ids: supervisorIDs,
      }
    );
  }
}
