import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { GlobalService } from '../../services/global.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ArabicNumberPipe } from '../../pipes/arabic-number.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TruncatePipe } from '../../pipes/truncate.pipe';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { LocalDateTimePipe } from '../../pipes/local-date-time.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import moment from 'moment';

declare var $: any;
@Component({
  selector: 'app-table-view',
  standalone: true,
  imports: [CommonModule, TranslateModule, ArabicNumberPipe, MatCheckboxModule, MatSlideToggleModule, TruncatePipe, NgbTooltipModule, LocalDateTimePipe, MatProgressSpinnerModule],
  templateUrl: './table-view.component.html',
  styleUrl: './table-view.component.scss',
  animations: [
    trigger('sidebarAnimation', [
      state('hidden', style({
        width: '0',
        opacity: '0'
      })),
      state('visible', style({
        width: '50px',
        opacity: '1'
      })),
      transition('hidden => visible', animate('300ms ease-in')),
      transition('visible => hidden', animate('300ms ease-out'))
    ])
  ]
})
export class TableViewComponent {
  //
  @Input('controls') controls: any;

  @Output('eventHandeler') eventHandeler = new EventEmitter();
  @Output() onPaginationBtnClick = new EventEmitter();
  @Input() selectedUsersIDS: any;
  @Input() sortingOrder: any = '';
  @Input() sortedCol: any = '';
  @Input() showExport = false;
  columns: any[] = [];
  data: any = [];
  noResultsFlag: boolean = false;
  view = 'list';
  selection = true;
  selectionAll = true;
  showSelectionNumberFlag = true;
  showTotalNumberFlag = true;
  singleSelect = true;
  pagination: any;
  showPagination: any;
  AllRowsIsChecked = false;
  selectedRows: boolean[] = [];
  selectedCount = 0;
  @Input() loading = false;
  currLang: any;
  @Input() currentView: string = 'list';
  tableTitle = '';
  selectedTitle = '';
  totalTitle = '';

  constructor(
    private translate: TranslateService,
    private globalService: GlobalService
  ) {
    this.globalService.getLang().subscribe((lng) => {
      this.currLang = lng ? lng : "en";
      this.translate.use(this.currLang);
    });
  }
  ngOnInit(): void {
  }

  /////////////////////////////
  ngOnChanges(changes: SimpleChanges) {
    this.tableTitle = this.controls?.title;
    this.selectedTitle = this.controls?.selectedTitle;
    this.totalTitle = this.controls?.totalTitle
    this.selection = this.controls.selection;
    this.selectionAll = this.controls.selection;
    this.showSelectionNumberFlag =
      this.controls?.showSelectionNumberFlag == null
        ? true
        : this.controls?.showSelectionNumberFlag;
    this.showTotalNumberFlag =
      this.controls?.showTotalNumberFlag == null
        ? true
        : this.controls?.showTotalNumberFlag;
    this.singleSelect = this.controls.singleSelect;
    this.selectedRows = new Array(this.controls.data.length).fill(false);
    this.selectedCount = 0;
    this.pagination = this.controls.pagination;
    this.showPagination = this.controls.paging;
    this.view = this.controls.view;
    this.columns = this.controls.columns;
    this.data = this.controls.data;
    if (this.data.length == 0) {
      this.noResultsFlag = true;
    } else {
      this.noResultsFlag = false;
    }
    this.AllRowsIsChecked = false;
    if (changes['currentView']) {
      if (
        changes['currentView'].currentValue != changes['currentView'].previousValue
      ) {
        this.view = changes['currentView'].currentValue;
      }
    }

    this.data?.forEach((element: any, index: any) => {
      if (element.isSelected) {
        this.selectedRows[index] = true;
      }
    });
    this.addSelectedFlagToUsers();
    this.getSelectionCount();
  }
  addSelectedFlagToUsers() {
    this.data.forEach((element: any, index: any) => {
      if (this.selectedUsersIDS?.includes(element.id)) {
        element['isSelected'] = true;
        this.selectedRows[index] = true;
      }
    });
  }

  rowClicked(row: any, index: any) {
    this.eventHandeler.emit({
      action: 'rowClicked',
      target: {
        index: index,
        data: row,
      },
    });
  }
  // iconClicked(event: any, row: any, index: any) {
  //   this.eventHandeler.emit({
  //     action: 'iconClicked',
  //     e: event,
  //     target: {
  //       index: index,
  //       data: row,
  //     },
  //   });
  // }

  checkBoxSelectAll(event: any) {
    this.AllRowsIsChecked = event.checked;
    this.data.forEach((element: any) => {
      element.isSelected = this.AllRowsIsChecked;
    });
    this.selectedRows.fill(this.AllRowsIsChecked);
    this.eventHandeler.emit({
      action: 'checkboxChanged',
      target: {
        index: 'all',
        data: 'all',
        dataValues: this.data,
        checked: event.checked,
      },
    });
    this.getSelectionCount();
  }

  checkboxAction(event: any, row: any, index: any) {
    if (this.singleSelect) {
      this.selectedRows.fill(false);
      this.data.forEach((element: any) => {
        element.isSelected = false;
      });
    }
    row.isSelected = !row.isSelected;
    this.selectedRows[index] = event.checked;

    if (this.selectedRows.includes(false)) {
      this.AllRowsIsChecked = false;
    }
    this.eventHandeler.emit({
      action: 'checkboxChanged',
      target: {
        index: index,
        data: row,
        checked: event.checked,
      },
    });
    this.getSelectionCount();
  }
  // changeActivation(event: any, row: any, index: any) {
  //   this.eventHandeler.emit({
  //     action: 'activationChanged',
  //     e: event,
  //     target: {
  //       index: index,
  //       data: row,
  //     },
  //   });
  // }

  // showQR(event: any, row: any, index: any) {
  //   this.eventHandeler.emit({
  //     action: 'showQr',
  //     e: event,
  //     target: {
  //       index: index,
  //       data: row,
  //     },
  //   });
  // }
  sort(col: any, order: any) {

    this.eventHandeler.emit({
      action: 'sorting',
      target: {
        source: col.source,
        order: order,
      },
    });
  }
  linkClicked(row: any, col: any, index: any) {
    if (row[col.source + '_link'] == true) {
      this.eventHandeler.emit({
        action: 'linkClicked',
        target: {
          source: col.source,
          row: row,
          index: index,
        },
      });
    }
  }

  generateExcelFile() {
    this.eventHandeler.emit({
      action: 'export'
    });
  }
  // changeTime(row: any, col: any, index: any, event: any) {
  //   this.eventHandeler.emit({
  //     action: 'timeChanged',
  //     target: {
  //       source: col.source,
  //       row: row,
  //       index: index,
  //       time: event.target.value,
  //     },
  //   });
  // }

  getImage(imagePath: string) {
    return imagePath;
  }
  paginationHandler(value: any) {
    this.onPaginationBtnClick.emit(value);
  }

  paginationChange(direction: any) {
    if (
      (direction == 1 && this.pagination.to != this.pagination.total) ||
      (direction == -1 && this.pagination.from != 1)
    ) {
      this.eventHandeler.emit({
        action: 'pagination',
        target: {
          dir: direction,
        },
      });
    }
  }

  checkColumn(type: string, view: string) {
    let result = type === 'image' && view === 'compact' ? false : true;
    return result;
  }

  getSelectionCount() {
    this.selectedCount = this.selectedRows.filter((row) => row == true).length;
  }

  toggleTableBorderRadius(flag1: any, flag2: any, lng: any, type: any) {
    const borderRadius: any = {};
    if (type === 'heading') {
      if (!flag1 && !flag2) {
        borderRadius['border-top-left-radius'] = '10px';
        borderRadius['border-top-right-radius'] = '10px';
      } else if ((flag1 || flag2) && lng === 'en') {
        borderRadius['border-top-right-radius'] = '10px';
      } else if ((flag1 || flag2) && lng === 'ar') {
        borderRadius['border-top-left-radius'] = '10px';
      }
    } else if (type === 'footer') {
      if (!flag1 && !flag2) {
        borderRadius['border-bottom-left-radius'] = '10px';
        borderRadius['border-bottom-right-radius'] = '10px';
      } else if ((flag1 || flag2) && lng === 'en') {
        borderRadius['border-bottom-right-radius'] = '10px';
      } else if ((flag1 || flag2) && lng === 'ar') {
        borderRadius['border-bottom-left-radius'] = '10px';
      }
    }
    return borderRadius;
  }

  localizeDate(date:any){
    let localizedDate;
    localizedDate = moment(date).locale(this.currLang).format('DD-MM-YYYY');
    return localizedDate
  }
}
