<div class="modal-container" [class.rtl]="currLang == 'ar'">
    <div class="modal-content" [ngStyle]="{ direction: currLang == 'en' ? 'ltr' : 'rtl' }">
        <div class="modal-header">
            <h5 class="modal-title">

                <button *ngIf="currentAction=='change_password'" class="  btn-back"
                    [ngStyle]="{ transform: currLang == 'en' ? '' : 'rotate(180deg)' }" (click)="back()">
                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1.16878 7.70559L8.04399 1.0402C8.15333 0.945996 8.29565 0.895854 8.44186 0.900026C8.58807 0.904199 8.72711 0.962373 8.83054 1.06265C8.93397 1.16292 8.99398 1.29772 8.99829 1.43947C9.00259 1.58121 8.95086 1.7192 8.85369 1.8252L2.38335 8.09809L8.85516 14.371C8.95233 14.477 9.00406 14.615 8.99975 14.7567C8.99545 14.8985 8.93544 15.0333 8.832 15.1335C8.72857 15.2338 8.58954 15.292 8.44333 15.2961C8.29712 15.3003 8.15479 15.2502 8.04546 15.156L1.16878 8.49343C1.11529 8.44177 1.07286 8.38038 1.0439 8.31278C1.01494 8.24518 1.00003 8.17271 1.00003 8.09951C1.00003 8.02631 1.01494 7.95383 1.0439 7.88624C1.07286 7.81864 1.11529 7.75725 1.16878 7.70559Z"
                            fill="#707070" stroke="#707070" />
                    </svg>

                </button>
                {{(currentAction=='info'? "profile.profile":"profile.change_password" )| translate }}
            </h5>
            <div class="header-btns">
                <button *ngIf="!editMode&&currentAction=='info'" class="btn btn-primary edit-btn"
                    (click)="enableEdit()">
                    {{ "profile.edit" | translate }}
                </button>
                <button type="button" class="close" (click)="onNoClick()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
        <div class="modal-body" [ngStyle]="{ 'text-align': currLang == 'en' ? 'left' : 'right' }">
            <ng-container *ngIf="currentAction=='info'">

                <app-user-info [editMode]="editMode" (changeAction)="currentAction=$event"
                    (changeEditMode)="editMode=$event"></app-user-info>

            </ng-container>
            <ng-container *ngIf="currentAction=='change_password'">

                <app-change-password [currLang]="currLang" (closeModal)="onNoClick()"></app-change-password>
            </ng-container>


        </div>

    </div>
</div>