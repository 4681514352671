import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TabsComponent } from '../../shared/components/tabs/tabs.component';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [RouterModule,TabsComponent],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {
  activeModule!: string;
  activeTab!:string
  tabs: any = [
    {link: "dashboard-view" , name:"Transportation dashboard", name_ar:"تقارير الإنتقالات"},
    { link: 'bus-tracking', name: 'Track bus', name_ar: 'تتبع الحافلة' },
  ];
}
