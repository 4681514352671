import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Bus } from '../../../models/bus.model';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BusService {
  constructor(private http: HttpClient) {}
  private _BusData = new BehaviorSubject<any>(null);
  BusData = this._BusData.asObservable();

  setBusData(bus: any, flag: string) {
    this._BusData.next({ bus: bus, flag: flag });
  }

  getBusData() {
    return this._BusData;
  }
  private _LoadBussesData = new BehaviorSubject<any>(null);
  LoadBussesData = this._LoadBussesData.asObservable();

  setLoadBussesData(load: boolean) {
    this._LoadBussesData.next(load);
  }

  getLoadBussesData() {
    return this._LoadBussesData;
  }

  getBusess(
    page: number,
    page_size: number,
    related_objects: string[]=[],
    related_objects_count: string[] = [],
    filters: any,order:{ order_type?: string; order_by?: string }
  ) {
    let body = {
      page,
      page_size,
      related_objects,
      related_objects_count,
      filters,
      order
    };
    return this.http.post<any>(
      environment.url + 'backoffice/bus/getBuses',
      body
    );
  }
  addBus(body: Bus) {
    return this.http.post<any>(
      environment.url + 'backoffice/bus/createBus',
      body
    );
  }
  editBus(body: Bus) {
    return this.http.put<any>(
      environment.url + 'backoffice/bus/updateBus',
      body
    );
  }
  getBusById(
    id: any,
    related: string[] = [],
    related_objects_count: string[] = []
  ) {
    let params: any = { bus_id: id };
    related.forEach((el: any, i: number) => {
      params[`related_objects[${i}]`] = el;
    })
    related_objects_count.forEach((el: any, i: number) => {
      params[`related_objects_count[${i}]`] = el;
    })
    return this.http.get<any>(
      ` ${environment.url}backoffice/bus/getBusById`,
      { params }

    );
  }
  deleteBusses(bussesIDs: number[]) {
    let params: any = {};

    if (bussesIDs.length > 0) {
      bussesIDs.forEach((el, i) => {
        params[`bus_ids[${i}]`] = el;
      });
    }
    return this.http.post<any>(
      ` ${environment.url}backoffice/bus/deleteBuses`,
      {
        bus_ids: bussesIDs,
      }
    );
  }
}
