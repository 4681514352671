import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-validator',
  standalone: true,
  imports: [TranslateModule , CommonModule],
  templateUrl: './validator.component.html',
  styleUrl: './validator.component.scss'
})
export class ValidatorComponent {
  @Input() name!: string
  @Input() valid!: boolean
  constructor() { }

  ngOnInit() {
  }

}
