import { Injectable,NgZone } from '@angular/core';
import html2canvas from 'html2canvas';
import * as pdfMake from 'pdfmake/build/pdfmake';
@Injectable({
  providedIn: 'root'
})
export class PrintService {

  constructor(private _ngZone: NgZone) { }

  public printPdf(table:any): void {
    this._ngZone.runOutsideAngular(() => {
      html2canvas(document.querySelector(table), { logging: true, allowTaint: false, useCORS: true }).then(canvas => {
        const imgData = canvas.toDataURL('image/jpeg', 1);
        const documentDefinition:any = this.getDocumentDefinition(imgData);
        pdfMake.createPdf(documentDefinition).print();
      });
    })


  }

  getDocumentDefinition(imgData:any) {
    let docContent =
      [
        {
          pageSize: 'A4',
          image: imgData,
          // margin: [-25, -35, -25, -14],
          fit: [800, 1500],
        }
      ]

    return {
      pageSize: 'A4',
      pageOrientation: 'Landscape',
      content: docContent
    }
  }
}
