import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _UserDataChanged = new BehaviorSubject<any>(null);
  UserDataChanged = this._UserDataChanged.asObservable();
  setUserDataChanged(load: boolean) {
    this._UserDataChanged.next(load);
  }

  getUserDataChanged() {
    return this._UserDataChanged;
  }

  constructor(private http: HttpClient) { }

  getUserData() {
    return this.http.get<any>(environment.url + 'backoffice/user/getUser');
  }

  updateUserData(name: string) {
    return this.http.put<any>(
      environment.url + 'backoffice/user/updateUser',
      { name }
    );
  }

  updatePassword(body: { old_password: string, new_password: string }) {
    return this.http.put<any>(
      environment.url + 'backoffice/user/updateUserPassword',
      body
    );
  }
}
