import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'localDateTime',
  standalone: true
})
export class LocalDateTimePipe implements PipeTransform {

  currLang = localStorage.getItem('lang') || "en"
  transform(date: any, args?: any): any {
    if (!date) {
      return ''
    }
    return moment(date)
      .add(localStorage.getItem('timeZoneOffset'), 'h')
      .locale(this.currLang)
      .format('D MMM YYYY, hh:mm A');
  }
}
