import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SubscriberService {
  constructor(private http: HttpClient) {}
  private _subscriberData = new BehaviorSubject<any>(null);
  subscriberData = this._subscriberData.asObservable();

  setSubscriberData(subscriber: any | null , flag: string) {
    this._subscriberData.next({subscriber: subscriber , flag: flag});
  }

  getSubscriberData() {
    return this._subscriberData;
  }
  private _LoadSubscriberData = new BehaviorSubject<any>(null);
  LoadSubscriberData = this._LoadSubscriberData.asObservable();

  setLoadSubscriberData(load: boolean) {
    this._LoadSubscriberData.next(load);
  }

  getLoadSubscriberData() {
    return this._LoadSubscriberData;
  }

  getSubscribers(
    page: number,
    page_size: number,
    related_objects: string[],
    filters: any,
    order:{order_type?:string,order_by?:string}
  ) {
    let body = {
      page,
      page_size,
      related_objects,
      filters,order
    };

    return this.http.post<any>(
      environment.url + 'backoffice/subscription/getSubscriptions',
      body
    );
  }

  getSearchSubscribers(
    page: number,
    page_size: number,
    related_objects: string[],
    search_word:string,
    excluded_ids: number[],
    order:{order_type?:string,order_by?:string}
  ) {
    let body = {
      page,
      page_size,
      related_objects,
      search_word,excluded_ids,order
    };

    return this.http.post<any>(
      environment.url + 'backoffice/subscription/searchSubscriptions',
      body
    );
  }


  editSubscriberLOcation(body: any) {
    return this.http.post<any>(
      environment.url + 'backoffice/subscription/updateSubscriptionLocation',
      body
    );
  }
}
