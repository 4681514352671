<div class="modal-container" [class.rtl]="currLang == 'ar'">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">
                {{ "drivers.add-driver-modal.driver-details" | translate }}
            </h5>
            <div class="header-btns">
                <button *ngIf="fieldsReadOnly" class="btn btn-transparent deactive-btn" (click)="deactive()" hidden>
                    {{ "drivers.add-driver-modal.deactive" | translate }}
                </button>
                <svg (click)="closeModal()" xmlns="http://www.w3.org/2000/svg" width="14" height="16"
                    viewBox="0 0 14 16" fill="none">
                    <path d="M6.94989 8L12.8998 15" stroke="#707070" stroke-width="2" stroke-linecap="round" />
                    <path d="M6.94977 8L0.999873 15" stroke="#707070" stroke-width="2" stroke-linecap="round" />
                    <path d="M6.94989 8L12.8998 1" stroke="#707070" stroke-width="2" stroke-linecap="round" />
                    <path d="M6.94977 8L0.999873 1" stroke="#707070" stroke-width="2" stroke-linecap="round" />
                </svg>
            </div>
        </div>
        <div class="modal-body">
            <form [id]="formFlag === 'edit' ? 'driverForm' : null" [formGroup]="driverForm">
                <!-- view state -->
                <div class="row view-info">
                    <div class="info-pic-wrapper">
                        <div class="pic-name">
                            <div class="img-section">
                                <div *ngIf="!image" class="modal-no-image">
                                    <img src="../../../../../../assets/images/svg/person.svg" alt="" />
                                </div>
                                <div class="modal-image">
                                    <img class="driver-image" *ngIf="image" src="{{ image }}" />
                                    <!-- <img src="../../../../../../assets/images/svg/camera.svg" alt="camera"
                                        *ngIf="formFlag === 'edit' || formFlag === 'add'"
                                        (click)="changeDriverProfile()" id="camera" style="top: -2rem; right: auto" /> -->
                                    <!-- [ngStyle]="{
                            top: formFlag == 'add' ? '-2rem' : '0',
                            right: formFlag == 'add' ? 'auto' : '0'
                          }" -->
                                </div>
                                <!-- <input (change)="onFileSelected($event)" id="fileUpload" type="file"
                                    style="display: none;" multiple="multiple" [disabled]="fieldsReadOnly"> -->
                            </div>
                            <div class="info-item" *ngIf="formFlag == 'view'">
                                <div class="label">{{ "drivers.add-driver-modal.name" | translate }} :</div>
                                <div class="value">{{ driverForm.get('name')?.value }}</div>
                            </div>
                            <div class="info-mat" *ngIf="formFlag != 'view'">
                                <mat-form-field appearance="outline" dir="ltr">
                                    <mat-label>{{
                                        "drivers.add-driver-modal.name" | translate
                                        }}</mat-label>
                                    <input matInput formControlName="name" [readonly]="fieldsReadOnly"
                                        id="driverName" />
                                    <mat-error>
                                        {{ getValidationMessage("name") }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="edit-btn-wrapper" *ngIf="formFlag == 'view'">
                            <button class="btn btn-primary edit-btn" (click)="editDriver()">
                                {{ "drivers.add-driver-modal.edit" | translate }}
                            </button>
                        </div>
                    </div>
                    <label class="section-header" [ngStyle]="{ 'text-align': currLang == 'en' ? 'left' : 'right' }">{{
                        "drivers.add-driver-modal.driver-info" | translate }}</label>
                    <div class="info-details-wrapper" *ngIf="formFlag == 'view'">
                        <div class="info-item">
                            <div class="label">{{ "drivers.add-driver-modal.phone_no" | translate }} :</div>
                            <div class="value">{{ driverForm.get('phone_no')?.value }}</div>
                        </div>
                        <div class="info-item">
                            <div class="label">{{ "drivers.add-driver-modal.national_id" | translate }} :</div>
                            <div class="value">{{ driverForm.get('national_id')?.value }}</div>
                        </div>
                        <div class="info-item">
                            <div class="label">{{ "drivers.add-driver-modal.username" | translate }} :</div>
                            <div class="value">{{ driverForm.get('username')?.value }}</div>
                        </div>
                    </div>
                </div>
                <div class="info-section" *ngIf="formFlag != 'view'">
                    <div class="row">
                        <div class="col-lg-6">
                            <mat-form-field appearance="outline" dir="ltr">
                                <mat-label>{{
                                    "drivers.add-driver-modal.username" | translate
                                    }}</mat-label>
                                <input matInput formControlName="username" [readonly]="fieldsReadOnly" />
                                <mat-error>
                                    {{ getValidationMessage("username") }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-6">
                            <mat-form-field appearance="outline" dir="ltr">
                                <mat-label>{{
                                    "drivers.add-driver-modal.phone_no" | translate
                                    }}</mat-label>
                                <input matInput formControlName="phone_no" [readonly]="fieldsReadOnly" />
                                <mat-error>{{
                                    getValidationMessage("phone_no")
                                    }}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-6">
                            <mat-form-field class="col-lg-12 national-id" appearance="outline" dir="ltr">
                                <mat-label>{{
                                    "drivers.add-driver-modal.national_id" | translate
                                    }}</mat-label>
                                <input matInput formControlName="national_id" [readonly]="fieldsReadOnly" />
                                <mat-error>{{
                                    getValidationMessage("national_id")
                                    }}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-6">
                            <mat-form-field appearance="outline" dir="ltr" *ngIf="formFlag == 'add'">
                                <mat-label>{{
                                    "drivers.add-driver-modal.password" | translate
                                    }}</mat-label>
                                <input matInput formControlName="password" [readonly]="fieldsReadOnly" />
                                <mat-error>
                                    {{ getValidationMessage("password") }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </form>

            <!-- assigned trips -->
            <div class="trips" *ngIf="formFlag == 'view'">
                <label class="section-header">{{
                    "drivers.add-driver-modal.trips" | translate
                    }} ({{trips.length | arabicNumber:currLang}})</label>
                <!-- <div class="row trips-heading">
                    <h2>{{ "areas.add-area-modal.trips" | translate }}</h2>
                </div> -->
                <div class="row trips-content" *ngIf="trips.length > 0">
                    <div class="col-lg-5 trip-item" *ngFor="let trip of trips">
                        <div class="d-flex flex-column justify-content-between">
                            <h5 class="name">{{ trip?.name }}</h5>
                            <h6 class="from-to">{{ "trip-ways."+trip?.way | translate }}</h6>
                        </div>
                        <div>
                            <h3 class="time">{{ convertTime(trip?.start_time) }}</h3>
                        </div>
                    </div>
                </div>
                <div class="row no-trips" *ngIf="trips.length == 0">
                    <h4>{{ "areas.add-area-modal.no-trips" | translate }}</h4>
                </div>
            </div>
            <!-- <div class="modal-footer" [class.none]="formFlag == 'view'">
                <button class="btn btn-primary save-btn" [disabled]="driverForm.invalid" (click)="saveDriver()">
                    {{ "drivers.add-driver-modal.save" | translate }}
                </button>
                <button type="button" (click)="closeModal()" class="btn btn-transparent close">
                    {{ "drivers.add-driver-modal.cancel" | translate }}
                </button>
            </div> -->
        </div>

        <div class="modal-footer" [class.none]="formFlag == 'view'">
            <div class="btns-wrapper">
                <button type="button" (click)="closeModal()" class="btn btn-primary cancel-btn">
                    {{ "drivers.add-driver-modal.cancel" | translate }}
                </button>
                <button type="button" class="btn btn-primary save-btn" [disabled]="driverForm.invalid" #saveBtn
                    (click)="saveDriver()">
                    {{ "drivers.add-driver-modal.save" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>