import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from '../services/login.service';
import { StorageService } from '../services/storage.service';
import { GlobalService } from '../../shared/services/global.service';
import { SnackBarService } from '../../shared/services/snack-bar.service';
import { SnackBarErrorComponent } from '../../shared/components/snack-bar-error/snack-bar-error/snack-bar-error.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SvgIconComponent } from 'angular-svg-icon';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule,ReactiveFormsModule,FormsModule,TranslateModule,MatFormFieldModule,SvgIconComponent,MatInputModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  loginForm!: UntypedFormGroup;
  passwordType!: string;
  currLang!: string;

  constructor(
    private translate: TranslateService,
    private loginService: LoginService,
    private _snackBar: MatSnackBar,
    private snackBar: SnackBarService,
    private storage: StorageService,
    private router: Router,
    private globalService: GlobalService
  ) {
    this.globalService.getLang().subscribe((lng: string) => {
      this.currLang = lng ? lng : 'en';
      this.translate.use(this.currLang);

      // document.dir = this.currLang == 'en' ? 'ltr' : 'rtl';
    });
    this.translate.onLangChange.subscribe((res) => {
      this.currLang = res.lang ? res.lang : 'en';
      this.translate.use(this.currLang);
    });
  }

  ngOnInit(): void {
    this.router.navigateByUrl('/');

    this.initForm();
  }
  initForm() {
    this.passwordType = 'password';

    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern(
          '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'
        ),
      ]),
      password: new UntypedFormControl('', Validators.required),
    });
  }
  togglePassword() {
    this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
  }

  keyDownFunc(event: any) {
    if (event.keyCode === 13) {
      this.login();
    }
  }

  login() {
    let formData = new FormData();
    formData.append('email', this.loginForm.value['email']);
    formData.append('password', this.loginForm.value['password']);
    // formData.append('platform', 'web');
    this.loginService.login(formData).subscribe(
      (res: any) => {
        this.storage.userToken = res.data.token;
        this.storage.userData = res.data.user;
        this.storage.imageUrl = res.data.image_base_url;
        localStorage.setItem('lang', this.currLang);
        this.metaData()

        this.router.navigateByUrl('/layout/management');
      },
      (error) => {
        this.snackBar.setSnackBarMessage(error.error.Error[0]);
        this._snackBar.openFromComponent(SnackBarErrorComponent, {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
    );
  }

  getValidationMessage(control: any) {
    let errorMsg;
    if (this.loginForm.controls[control].errors) {
      const fieldName = this.translate.instant('login.' + control);
      const requiredText = this.translate.instant('login.required');
      const invalidText = this.translate.instant('login.invalid');
      errorMsg = this.loginForm.controls[control].errors?.['required']
        ? `${fieldName + requiredText}`
        : `${control + invalidText}`;
    }
    return errorMsg;
  }
  metaData() {
    let timeZoneOffset;
    timeZoneOffset = new Date().getTimezoneOffset() / -60
    localStorage.setItem('timeZoneOffset', String(timeZoneOffset))
  }

}
