<aside [class.rtl]="currLang == 'ar'">
    <div class="side-bar--logo">
        <img *ngIf="currLang == 'en'" src="../../../../assets/images/svg/logo.svg" />
        <img *ngIf="currLang == 'ar'" src="../../../../assets/images/svg/logoAr.svg" />
    </div>
    <div class="content">
        <!-- -->
        <a [routerLink]="item.link" routerLinkActive="active" *ngFor="let item of menu">
            <div [class]="activeModule === item?.moduleName? 'route active' : 'route'">
                <div class="icon">
                    <img [src]="item.img" alt="">
                    <span>{{ item.name!| translate }}</span>
                </div>
            </div>
        </a>
    </div>
</aside>