import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  constructor(private translate: TranslateService) { }

  private _toggleStartLoading = new BehaviorSubject<boolean>(true);
  toggleStartLoading = this._toggleStartLoading.asObservable();
  toggleStartLoadingFlag = true;
  private _toggleInitialLoading = new BehaviorSubject<boolean>(true);
  toggleInitialLoading = this._toggleInitialLoading.asObservable();
  toggleInitialLoadingFlag = true;

  private _languageChanged = new BehaviorSubject<string>(
    localStorage.getItem('lang') as string
  );
  languageChanged = this._languageChanged.asObservable();

  startLoading() {
    this._toggleStartLoading.next(true);
  }

  stopLoading() {
    this._toggleStartLoading.next(false);
  }
  // stopLoading() {
  //     this.toggleStartLoadingFlag=false;
  //     if(!this.toggleInitialLoadingFlag){
  //         this._toggleStartLoading.next(false);
  //     }
  // }

  getLoading() {
    return this._toggleStartLoading;
  }

  startInitialLoading() {
    this._toggleInitialLoading.next(true);
  }

  stopInitialLoading() {
    this._toggleInitialLoading.next(false);
  }

  // stopInitialLoading() {
  //     this.toggleInitialLoadingFlag=false;
  //     this._toggleInitialLoading.next(false);
  //     if(!this.toggleStartLoadingFlag){
  //         this.stopLoading();
  //     }
  // }

  setLang(lang: any) {
    lang = lang ? lang : 'en';
    localStorage.setItem('lang', lang);
    this.translate.use(lang);
    this._languageChanged.next(lang);
    document.dir = lang == 'en' ? 'ltr' : 'rtl';
  }
  getLang() {
    return this._languageChanged;
  }
  convertTime(time: any) {
    if (time) {
      let res: any = time.split(':');
      let status = 'am';
      if (Number(res[0]) == 12) {
        status = 'pm';
      } else if (Number(res[0]) > 12) {
        res[0] = Number(res[0]) - 12;

        status = 'pm';
        if (Number(res[0]) == 24) {
          status = 'am';
        }
      }
      return res[0] + ':' + res[1] + ' ' + status;
    } else {
      return time;
    }
  }
}
