import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Driver } from '../../../models/driver.model';

@Injectable({
  providedIn: 'root',
})
export class DriverService {
  constructor(private http: HttpClient) {}
  private _DriverData = new BehaviorSubject<any>(null);
  DriverData = this._DriverData.asObservable();

  setDriverData(driver: Driver | null, flag: string) {
    this._DriverData.next({ driver: driver, flag: flag });
  }

  getDriverData() {
    return this._DriverData;
  }
  private _LoadDriversData = new BehaviorSubject<any>(null);
  LoadDriversData = this._LoadDriversData.asObservable();

  setLoadDriversData(load: boolean) {
    this._LoadDriversData.next(load);
  }

  getLoadDriversData() {
    return this._LoadDriversData;
  }
  getDrivers(page: number, page_size: number, related_objects: string[], filters: any,order:{ order_type?: string; order_by?: string}) {
    let body = {
      page,
      page_size,
      related_objects,
      filters,order
    };
    return this.http.post<any>(
      environment.url + 'backoffice/driver/getDrivers',
      body
    );
  }
  addDriver(body: any) {
    return this.http.post<any>(
      environment.url + 'backoffice/driver/createDriver',
      body
    );
  }
  editDriver(body: any) {
    return this.http.put<any>(
      environment.url + 'backoffice/driver/updateDriver',
      body
    );
  }
  getDriverById(id: any, related: any) {
    let params: any = { driver_id: id };
    related.map((el: any, i: number) => {
      params[`related_objects[${i}]`] = el;
    });
    return this.http.get<any>(
      ` ${environment.url}backoffice/driver/getDriverById`,
      {
        params,
      }
    );
  }
  deleteDrivers(driverIDs: number[]) {
    let params: any = {};

    if (driverIDs.length > 0) {
      driverIDs.forEach((el, i) => {
        params[`driver_ids[${i}]`] = el;
      });
    }
    return this.http.post<any>(
      ` ${environment.url}backoffice/driver/deleteDrivers`,
      {
        driver_ids: driverIDs,
      }
    );
  }
}
