import { ChangeDetectorRef, Component } from '@angular/core';
import { DailyTrip } from '../../../models/dailyTrips';
import { Observable, Subscription, forkJoin, map } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../../../shared/services/global.service';
import { TripService } from '../../management/_services/trip.service';
import { ErrorMsgService } from '../../../shared/services/error-msg.service';
import { GoogleMapsModule, MapDirectionsService } from '@angular/google-maps';
import moment from 'moment';
import { CommonModule } from '@angular/common';
import { TableViewComponent } from '../../../shared/components/table-view/table-view.component';
import { ControlBarComponent } from '../../../shared/components/control-bar/control-bar.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import 'moment/locale/ar'
import { ArabicNumberPipe } from "../../../shared/pipes/arabic-number.pipe";
import { FireStoreService } from '../../../shared/services/fire-store.service';
ChangeDetectorRef
@Component({
    selector: 'app-bus-tracking',
    standalone: true,
    templateUrl: './bus-tracking.component.html',
    styleUrl: './bus-tracking.component.scss',
    imports: [CommonModule, TableViewComponent, ControlBarComponent, TranslateModule, GoogleMapsModule, MatProgressSpinnerModule, ArabicNumberPipe]
})
export class BusTrackingComponent {
  itemSelected = false;
  trip: any = null;
  passengers: any = [];
  busStops: any = [
    {
      name: 'start point', time: '8:00 AM', status: 'start', students: [
        { name: 'Ahmed Ehab' },
        { name: 'Wessam Ahmed' },
        { name: 'Rami Amr' },
      ], showStudents: false
    },
    {
      name: 'stop 1', time: '8:30 AM', status: 'visited', students: [
        { name: 'Abdelrahman Ghareeb' },
        { name: 'Abdelrahman Abu Sharkh' },
        { name: 'Abdelrahman Mostafa' },
      ], showStudents: false
    },
    {
      name: 'stop 2', time: '9:00 AM', status: 'visited', students: [
        { name: 'Fady Youssry' },
      ], showStudents: false
    },
    {
      name: 'stop 3', time: '9:30 AM', status: 'visited', students: [
        { name: 'Yasmin Fathy' },
      ], showStudents: false
    },
    {
      name: 'stop 4', time: '10:00 AM', status: 'visited', students: [
        { name: 'Walid Awny' },
        { name: 'Amr Foda' }
      ], showStudents: false
    },
    {
      name: 'stop 5', time: null, status: 'not-visited', students: [
        { name: 'Ahmed Hamdy' },
      ], showStudents: false
    },
    {
      name: 'stop 6', time: null, status: 'skipped', students: [
        { name: 'Omar Negm' },
      ], showStudents: false
    },
    {
      name: 'stop 7', time: null, status: 'not-visited', students: [
        { name: 'Mai Hosssam' },
        { name: 'Sara Shalaby' },
      ], showStudents: false
    },
    {
      name: 'stop 8', time: null, status: 'end', students: [
        { name: 'Mai Hosssam' },
        { name: 'Sara Shalaby' },
      ], showStudents: false
    }
  ];
  tableControls: any = { data: [] };
  searchText = ''
  pageNumber: number = 1;
  itemsPerPage: number = 25;
  order: { order_type?: string, order_by?: string } = {}

  currLang: string = 'en';
  loading!: boolean;
  // listType!: string;
  rowsView: string = 'list';
  filter = {};
  selectedCheckBoxes: any = [];
  // filterStatus!: boolean;
  from!: number;
  to!: number;
  total!: number;

  dataArray: DailyTrip[] = [];
  actions = [];
  lat: number = 0;
  lng: number = 0;
  busIcon: any = {
    draggable: false,
    icon: '../../../../assets/images/svg/bus.svg',
    zIndex: 6000
  };
  schoolIcon: any = {
    url: '../../../../assets/images/svg/school.svg',
    scaledSize: {
      width: 20,
      height: 20,
    },
  };
  studentIcon: any = {
    url: '../../../../assets/images/svg/circle.svg',
    scaledSize: {
      width: 20,
      height: 20,
    },
  };

  busLat!: number;
  busLng!: number;
  // busPath: google.maps.LatLngLiteral[] = [];
  // polylineOptions: google.maps.PolylineOptions = {
  //   strokeColor: '#4285F4', // Google Maps default blue color
  //   strokeOpacity: 1.0,
  //   strokeWeight: 5
  // };
  // firstBusPoint: any = {
  //   draggable: false,
  //   icon: '../../../../assets/images/svg/circle.svg',
  //   zIndex: 5000
  // };
  studentPoints: any[] = [];
  schoolPoints: any[] = [];
  waypoints: any[] = [];
  optimiziedWaypoints: any[] = [];;
  origin: any = { lat: 0, lng: 0 };
  destination: any = { lat: 0, lng: 0 };
  stepperPoints: any[] = [];
  tripData: DailyTrip = {};
  schoolList: any[] = [];
  hideSearch: boolean = true;
  center!: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    zoomControl: true,
    disableDefaultUI: true,
    fullscreenControl: true,
    disableDoubleClickZoom: true,
    mapTypeControl: true,
    scrollwheel: true
    // mapTypeId: 'hybrid',
    // maxZoom:this.maxZoom,
    // minZoom:this.minZoom,
  };
  directionsResults$!: Observable<google.maps.DirectionsResult | undefined>;
  showDirectionOnMap: boolean = false;
  public renderOptions = {
    suppressMarkers: true,
    polylineOptions: { strokeColor: '#0074EC' }
  };

  lastUpdated: any;
  busLocationSub$!: Subscription;


  onMapInitialized(map: google.maps.Map) {
    map.setOptions({
      mapTypeControl: false,
      // mapTypeControlOptions: {
      //   position: google.maps.ControlPosition.LEFT_TOP// Positioning the Satellite button
      // },
      // mapTypeControlOptions: {
      //   style: google.maps.MapTypeControlStyle.DEFAULT,
      //   position: google.maps.ControlPosition.LEFT_BOTTOM,
      //   mapTypeIds: [
      //     google.maps.MapTypeId.ROADMAP,
      //     google.maps.MapTypeId.HYBRID
      //   ]
      // },
      fullscreenControl: true,
      fullscreenControlOptions: {
        position: google.maps.ControlPosition.LEFT_BOTTOM // Positioning the Fullscreen button
      },
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_BOTTOM // Positioning the Zoom buttons
      }
    });

    this.addCustomMapTypeControl(map);
  }
  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private tripService: TripService,

    private errorMsgService: ErrorMsgService,
    private mapDirectionsService: MapDirectionsService,
    private fireStore:FireStoreService,
    private ref:ChangeDetectorRef
  ) {
    this.globalService.getLang().subscribe((lng) => {
      this.currLang = lng ? lng : "en";
      this.translate.use(this.currLang);
    });

    this.translate.onLangChange.subscribe((res) => {
      this.currLang = res.lang ? res.lang : "en";
      this.translate.use(this.currLang);
      this.setTableData(this.dataArray);
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.pageNumber = +params['page'] || 1;
      this.itemsPerPage = params['show'] || 25;
      if (params['order_by'] && params['order_type']) {
        this.order = {
          order_by: params['order_by'],
          order_type: params['order_type'],
        }
      }
      this.getRunningDailyTrips();

    });
  }

  // addCustomMapTypeControl(map: google.maps.Map) {
  //   const controlDiv = document.createElement('div');
  //   controlDiv.style.margin = '10px';

  //   const roadMapControlUI = document.createElement('button');
  //   roadMapControlUI.style.backgroundColor = '#fff';
  //   roadMapControlUI.style.border = '2px solid #fff';
  //   roadMapControlUI.style.borderRadius = '3px';
  //   roadMapControlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
  //   roadMapControlUI.style.cursor = 'pointer';
  //   roadMapControlUI.style.marginRight = '10px';
  //   roadMapControlUI.style.padding = '5px';
  //   roadMapControlUI.style.fontSize = '14px';
  //   roadMapControlUI.innerText = 'Map';
  //   controlDiv.appendChild(roadMapControlUI);

  //   const satelliteControlUI = document.createElement('button');
  //   satelliteControlUI.style.backgroundColor = '#fff';
  //   satelliteControlUI.style.border = '2px solid #fff';
  //   satelliteControlUI.style.borderRadius = '3px';
  //   satelliteControlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
  //   satelliteControlUI.style.cursor = 'pointer';
  //   satelliteControlUI.style.padding = '5px';
  //   satelliteControlUI.style.fontSize = '14px';
  //   satelliteControlUI.innerText = 'Satellite';
  //   controlDiv.appendChild(satelliteControlUI);

  //   roadMapControlUI.addEventListener('click', () => {
  //     map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
  //   });

  //   satelliteControlUI.addEventListener('click', () => {
  //     map.setMapTypeId(google.maps.MapTypeId.HYBRID);
  //   });

  //   map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(controlDiv);
  // }

  addCustomMapTypeControl(map: google.maps.Map) {
    const controlDiv = document.createElement('div');
    controlDiv.style.display = 'flex';
    controlDiv.style.flexDirection = 'row';
    controlDiv.style.marginInlineEnd = '10px';
    controlDiv.style.marginInlineStart = '10px';
    controlDiv.style.marginBottom = '10px';

    const roadMapControlUI = document.createElement('div');
    roadMapControlUI.classList.add('custom-map-control-button');
    roadMapControlUI.innerText = 'Map';
    controlDiv.appendChild(roadMapControlUI);

    const satelliteControlUI = document.createElement('div');
    satelliteControlUI.classList.add('custom-map-control-button');
    satelliteControlUI.innerText = 'Satellite';
    controlDiv.appendChild(satelliteControlUI);

    roadMapControlUI.addEventListener('click', () => {
      map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
      roadMapControlUI.classList.add('active');
      satelliteControlUI.classList.remove('active');
    });

    satelliteControlUI.addEventListener('click', () => {
      map.setMapTypeId(google.maps.MapTypeId.HYBRID);// Hybrid to show labels on satelite view without having the lables drobdown
      satelliteControlUI.classList.add('active');
      roadMapControlUI.classList.remove('active');
    });

    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv);

    // Set initial active button
    roadMapControlUI.classList.add('active');
  }
  getDailyTripData(id: number, refresh = false) {
    this.loading = true;
    forkJoin([
      this.tripService.getDailyTripById(id, ['daily_trip_passengers']),
      // this.tripService.getLastBusStatus(id),
    ]).subscribe(
      (Results) => {
        if (refresh) {
          this.trip = Results[0].data.daily_trip;
          this.drawDirections();
        } else {
          this.trip = Results[0].data.daily_trip;
          this.drawDirections();
          this.itemSelected = true;
        }
        // this.getLastBusStatus(Results[1].data);

        this.loading = false;
        this.lastUpdated = Date.now();
      },

      (error) => {
        this.loading = false;
        this.errorMsgService.setMessage(error.error.Error[0]);
      }
    );
  }
  setTableData(data: DailyTrip[]) {
    this.tableControls = {
      selection: false,
      paging: true,
      view: this.rowsView,
      title:'table-view.titles.active-trip',
      totalTitle:'table-view.total-titles.active-trip',
      pagination: {
        from: this.from,
        to: this.to,
        total: this.total,
      },
      columns: [
        {
          name: this.translate.instant('bus-tracking.trip-name'),
          sort: true,
          type: 'string',
          source: 'name',
        },
        {
          name: this.translate.instant('bus-tracking.bus-name'),
          sort: true,
          type: 'string',
          source: 'bus_name',
        },

        {
          name: this.translate.instant('bus-tracking.driver-name'),
          sort: false,
          type: 'string',
          source: 'driver_name',
        },
        {
          name: this.translate.instant('bus-tracking.supervisor-name'),
          sort: false,
          type: 'string',
          source: 'supervisor_name',
        },
        {
          name: this.translate.instant('bus-tracking.capacity'),
          sort: false,
          type: 'string',
          source: 'capacity',
        },
      ],
      data: data,
    };
  }
  tableEventHandeler(event: any) {
    switch (event.action) {
      case 'rowClicked':
        this.rowClicked(event.target);
        break;
      // case 'checkboxChanged':
      //   this.selectChanged(event.target);
      //   break;
      case 'sorting':
        this.sorting(event.target);
        break;

      case 'pagination':
        this.paginationHandling(event.target);
        break;
    }
  }
  sorting(target: { order: string; source: string }) {

    this.router.navigate(['layout/dashboard/bus-tracking'], {
      queryParams: { order_type: target.order, order_by: target.source },
      queryParamsHandling: 'merge',
    });
  }

  rowClicked(event: any) {
    this.getDailyTripData(event.data.id);
    this.getFireBaseBusLoc(event.data.id)
    event.index;
    event.data;
  }

  // selectChanged(event: any) {
  //   let index = event.index;
  //   let row = event.data;
  //   let checked = event.checked;
  //   if (checked) {
  //     // checked
  //     if (index == 'all') {
  //       this.selectedCheckBoxes = this.dataArray.map(
  //         (element: any) => element.id
  //       );
  //     } else {
  //       this.selectedCheckBoxes.push(row);
  //     }
  //   } else {
  //     // unchecked
  //     if (index == 'all') {
  //       this.selectedCheckBoxes = [];
  //     } else {
  //       let checkboxIndex = this.selectedCheckBoxes.indexOf(row);
  //       this.selectedCheckBoxes.splice(checkboxIndex, 1);
  //     }
  //   }
  // }

  paginationHandling(event: any) {
    this.pageNumber += event.dir;
    this.router.navigate(['layout/dashboard/bus-tracking'], {
      queryParams: { page: this.pageNumber },
      queryParamsHandling: 'merge',
    });
  }
  getRunningDailyTrips() {
    this.loading = true;
    this.tripService
      .getRunningDailyTrips(
        this.pageNumber,
        this.itemsPerPage,
        ['bus', 'driver', 'supervisor'],
        this.filter, this.order
      )
      .subscribe(
        (result) => {
          let res = result['data'].running_daily_trips;
          this.dataArray = res['data'].map((element: any) => {
            return {
              name: element.name,
              id: element.id,
              bus_name: element?.bus_name,
              driver_name: element?.driver_name,
              supervisor_name: element?.supervisor_name,
              capacity: element.capacity,
            };
          });
          this.from = res['from'];
          this.to = res['to'];
          this.total = res['total'];
          this.selectedCheckBoxes = [];
          this.setTableData(this.dataArray);
          this.loading = false;
        },
        (error) => {
          this.errorMsgService.setMessage(error.error.Error[0]);
        }
      );
  }
  setViewType(type: string) {
    this.rowsView = type;
  }
  // onSeachFn(text: any) {
  //   this.searchText = text;
  // this.pageNumber=1
  //   this.router.navigate(['layout/dashboard/bus-tracking'], {
  //     queryParams: { search: this.searchText },
  //     queryParamsHandling: 'merge',
  //   });
  // }
  setItemsPerPage($event: number) {
    this.itemsPerPage = $event;
    this.pageNumber = 1;
    this.router.navigate(['layout/dashboard/bus-tracking'], {
      queryParams: { show: this.itemsPerPage, page: this.pageNumber },
      queryParamsHandling: 'merge',
    });
    // this.getRunningDailyTrips();
  }

  back() {
    this.itemSelected = false;
    this.showDirectionOnMap = false;
    this.stopFireBaseBusLocSub();
  }

  drawDirections() {
    this.schoolPoints = this.getlocationFromObjectKeys(this.trip.school_points, 'school_points');
    this.studentPoints = this.getlocationFromObjectKeys(this.trip.student_home_points, 'student_home_points');

    switch (this.trip.way) {
      case 'to_school':
        this.stepperPoints = this.combineArrays(this.studentPoints, this.schoolPoints);
        break;
      case 'to_home':
        this.stepperPoints = this.combineArrays(this.schoolPoints, this.studentPoints)
        break;
    }
    this.getOriginDestinationWaypoints();
    // this.getDirection(this.origin , this.destination , this.waypoints);
  }
  getLastBusStatus(data: any) {
    // debugger
    if (!data.last_bus_status) {
      //not start pick
      // return null
      this.busLat = this.lat;
      this.busLng = this.lng;
    } else {
      //start pick
      //return  object
      let { destination_location, source_location } = data.last_bus_status;
      if (this.trip.start_drop) {
        //destination
        this.busLat = +destination_location.substring(
          0,
          destination_location.indexOf(',')
        );
        this.busLng = +destination_location.substring(
          destination_location.indexOf(',') + 1
        );
      } else {
        // start_drop==null
        //source
        this.busLat = +source_location.substring(
          0,
          source_location.indexOf(',')
        );
        this.busLng = +source_location.substring(
          source_location.indexOf(',') + 1
        );
      }
    }
    this.lastUpdated = Date.now();
  }
  refresh() {
    this.getDailyTripData(this.trip.id, true);
  }

  getStopIconByStatus(stopStatus: any, stopType: string): any {
    let stopIcon = {}
    if (stopType == "school_points") {
      switch (stopStatus) {
        case 'arrived':
          stopIcon = {
            draggable: false,
            icon: '../../../../assets/images/svg/schoolVisited.svg',
            zIndex: 5000
          }
          break;
          case null:
            stopIcon = {
              draggable: false,
              icon: '../../../../assets/images/svg/school.svg',
              zIndex: 5000
            }
          break;
        default:
          stopIcon = {
            draggable: false,
            icon: '../../../../assets/images/svg/school.svg',
            zIndex: 5000
          }
          break;
      }
    }
    else {
      switch (stopStatus) {
        case 'arrived':
          stopIcon = {
            draggable: false,
            icon: '../../../../assets/images/svg/visitedStop.svg',
            zIndex: 5000
          }
          break;
        case 'drop_off':
          stopIcon = {
            draggable: false,
            icon: '../../../../assets/images/svg/visitedStop.svg',
            zIndex: 5000
          }
          break;
        case 'absent':
          stopIcon = {
            draggable: false,
            icon: '../../../../assets/images/svg/missedStop.svg',
            zIndex: 5000
          }
          break;
        case null:
          stopIcon = {
            draggable: false,
            icon: '../../../../assets/images/svg/notVisitedStop.svg',
            zIndex: 4000
          }
          break;
      }
    }
    return stopIcon
  }
  convertDateTime(dateTime: string , addTzOffset:boolean) {
    let time:any;
    if (dateTime != null) {
      if(addTzOffset){
        time = moment(dateTime)
        .add(localStorage.getItem('timeZoneOffset'), 'h')
        .locale(this.currLang)
        .format('hh:mm A');
      }
      else if (addTzOffset == false){
        time = moment(dateTime)
        .locale(this.currLang)
        .format('hh:mm:ss A');
      }
      return time;
    } else {
      return null;
    }
  }

  // formatNumber(num: any) {
  //   let formatedNum;
  //   formatedNum = this.currLang == 'ar' ? new Intl.NumberFormat("ar-EG").format(num) : num;
  //   return formatedNum
  // }

  // toggleDirectionAndShortestPath(type: any, trip_way: any, studentMapPoints?: any) {
  //   // debugger
  //   let optimiziedWaypoints: any = [];
  //   let mapedStudentMapPoints: any = []
  //   if (this.waypoints.length > 0) {
  //     optimiziedWaypoints = this.waypoints.map((obj) => {
  //       return {
  //         'location': new google.maps.LatLng(obj.location.lat, obj.location.lng),
  //         'stopover': true
  //       }
  //     });
  //   }
  //   if (!!studentMapPoints && studentMapPoints.length > 0) {
  //     mapedStudentMapPoints = studentMapPoints.map((e: any) => {
  //       return {
  //         location: {
  //           lat: +e.source_location.substring(0, e.source_location.indexOf(',')),
  //           lng: +e.source_location.substring(e.source_location.indexOf(',') + 1),
  //         },
  //       };
  //     });
  //   }

  //   if (trip_way == 'to_home') {
  //     this.optimizeShortestPath(this.origin, this.destination)
  //   }
  //   else {
  //     let optimizedStudentMapPoints = mapedStudentMapPoints.map((obj: any) => {
  //       return new google.maps.LatLng(obj.location.lat, obj.location.lng)
  //     });
  //     this.preSetOriginDestinationForOptimizationGoTrip(optimizedStudentMapPoints)
  //   }
  //   this.showDirectionOnMap = true;
  // }
  // preSetOriginDestinationForOptimizationGoTrip(studentMapPoints: any) {
  //   let optimizationOrigin: any = {};
  //   console.log(this.schoolPoints);

  //   optimizationOrigin.lat = +this.schoolPoints[0].location.lat;
  //   optimizationOrigin.lng = +this.schoolPoints[0].location.lng;
  //   this.optimizeShortestPath(optimizationOrigin, studentMapPoints)
  // }

  // optimizeShortestPath(origin: any, destination: any) {
  //   const service = new google.maps.DistanceMatrixService();
  //   // finding the latlng object of origin , destination and waypoints for distance matrix api
  //   let latlngDestination = this.tripData.way == 'to_home' ? new google.maps.LatLng(destination.lat, destination.lng) : destination;
  //   let latlngOrigin = new google.maps.LatLng(origin.lat, origin.lng)
  //   let latlngWaypoints = this.waypoints.map((obj) => {
  //     return new google.maps.LatLng(obj.location.lat, obj.location.lng)
  //   });
  //   // destinations array contains all points except origin , to use this array in distance matrix api to find the distance between every point and origin
  //   let destinations = this.tripData.way == 'to_home' ? [...latlngWaypoints, latlngDestination] : destination;
  //   // creating the distance matrix object to send in the request to distance matrix api , it takes origins , destinations and travel mode as required parameters
  //   let distanceMatrixReqObj: any = {}
  //   distanceMatrixReqObj.origins = [latlngOrigin];
  //   distanceMatrixReqObj.destinations = destinations;
  //   distanceMatrixReqObj.travelMode = "DRIVING";
  //   distanceMatrixReqObj.unitSystem = 0;
  //   distanceMatrixReqObj.avoidHighways = false;
  //   distanceMatrixReqObj.avoidTolls = false;
  //   // the call to the distance matrix api
  //   service.getDistanceMatrix(distanceMatrixReqObj).then((response) => {
  //     console.log(response);
  //     // this method to find the index of the fathest point from origin
  //     const maxIndex = this.findIndexOfMaxDistance(response.rows[0].elements);
  //     // now i need to get the farthest point from the destinations array using the maxIndex i have , this will be my new destination to get direction
  //     const farthestDestination = destinations[maxIndex];
  //     // now i need to create a new waypoints array without the destination point 
  //     let wayPointsWithoutDestination = this.tripData.way == 'to_home' ? destinations.filter((destination: any) => destination != farthestDestination) : destinations;
  //     console.log('dir', wayPointsWithoutDestination);

  //     // this map just to organize the structure of the waypoints to meet the requirements of the Directions api , to get the new optimized directions
  //     let optimizeWaypoints = wayPointsWithoutDestination.map((obj: any) => {
  //       return {
  //         'location': obj,
  //         'stopover': true
  //       }
  //     });
  //     // this.getDirection(origin,farthestDestination,optimizeWaypoints , maxIndex)
  //   })
  // }

  // findIndexOfMaxDistance(arr: any) {
  //   return arr.reduce((maxIndex: any, elem: any, i: any, arr: any) =>
  //     elem?.distance?.value > arr[maxIndex]?.distance?.value ? i : maxIndex, 0);
  // }

  getDirection(origin: any, destination: any, waypoints: any, maxIndex?: any) {
    const request: google.maps.DirectionsRequest = {
      destination: destination,
      origin: origin,
      waypoints: waypoints,
      optimizeWaypoints: true,
      travelMode: google.maps.TravelMode.DRIVING,
      // provideRouteAlternatives:true
    };
    this.directionsResults$ = this.mapDirectionsService.route(request).pipe(map(response => response.result));
  }

  getlocationFromObjectKeys(jsonOfStopPoints: any, stopType: string) {
    
    const mappedData: { location: { lat: number, lng: number }, studentNames: string[], last_d_t_p_log: any, stopIcon: any, stopTime: any }[] = [];

    Object.entries(jsonOfStopPoints).forEach(([location, subscriptions]) => {
      const subscriptionsArray: any[] = subscriptions as any[];
      const names = subscriptionsArray.map(subscription => subscription.subscription_histroy.subscription_name);
      const [lat, lng] = location.split(',').map(parseFloat);
      const last_d_t_p_log = subscriptionsArray[0]?.last_d_t_p_log;

      mappedData.push({
        location: { lat, lng },
        studentNames: names,
        last_d_t_p_log: last_d_t_p_log,
        stopIcon: this.getStopIconByStatus(!!last_d_t_p_log ? last_d_t_p_log.action : null, stopType),
        stopTime: !!last_d_t_p_log ? last_d_t_p_log.action_date : null,
      });
    });

    return mappedData;
  }

  combineArrays(array1: any[], array2: any[]): any[] {
    return [...array1, ...array2];
  }

  getOriginDestinationWaypoints() {
    console.log(this.stepperPoints);
    
    //origin is the first point in the stepperPoints (all points)
    this.origin = this.center = this.stepperPoints[0].location
    //Destination is the last point in the stepperPoints (all points)
    this.destination = this.stepperPoints[this.stepperPoints.length - 1]?.location
    //way points are the rest of points without first(origin) and last (destination)
    this.waypoints = this.stepperPoints.slice(1, this.stepperPoints.length - 1);
    this.lat = +this.origin.lat;
    this.lng = +this.origin.lng;
  }

  getFireBaseBusLoc(tripId:string){
    this.busLocationSub$ = this.fireStore.getItemById(tripId).subscribe((res:any)=>{
      const newLat = res?.latitude;
      const newLng = res?.longitude;
      // Ensure the new coordinates are valid and different from the current ones
      if (newLat && newLng && (newLat !== 0 || newLng !== 0) && (newLat !== this.busLat || newLng !== this.busLng)) {
        this.busLat = newLat;
        this.busLng = newLng;
        // Create a new array for busPath to trigger change detection , the path array used to draw the bus path polyline
        // this.busPath = [...this.busPath, { lat: this.busLat, lng: this.busLng }];
        // console.log('Updated busPath:', this.busPath);
        // this.ref.detectChanges();
      }
    })
  }
  stopFireBaseBusLocSub(){
    if(this.busLocationSub$){
      this.busLocationSub$.unsubscribe();
      // reset bus path on destroy , to clear the array
      // this.busPath = [];
    }
  }

  ngOnDestroy() {
    this.stopFireBaseBusLocSub();
  }
}
