import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../services/global.service';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-tabs',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './tabs.component.html',
  styleUrl: './tabs.component.scss'
})
export class TabsComponent {
  @Input() tabs: any[] = [];
  activeTab!: string;
  activePath!: string;
  currLang!: string;
  constructor(
    private translate: TranslateService,
    private globalService: GlobalService,
    private router: Router
  ) {
    this.globalService.getLang().subscribe((lng) => {
      this.currLang = lng ? lng : "en";
      this.translate.use(this.currLang);
    });
    this.translate.onLangChange.subscribe((res) => {
      this.currLang = res.lang ? res.lang : "en";
      this.translate.use(this.currLang);
    });
  }
  ngOnInit(): void {
    this.activeTab = window.location.pathname.split('/')[3];
    this.router.events.subscribe((res: any) => {
      if (res.urlAfterRedirects) {
        const module = res.urlAfterRedirects.split('/')[3]
        this.activePath = module;
        // console.log('module' , module);

        this.activeTab = module && module.split("?")[0];
      }
    });
    // Capture the active route on page load (initialization)
    const currentUrl = this.router.url;
    if (currentUrl) {
      const module = currentUrl.split('/')[3];
      this.activePath = module;
      this.activeTab = module && module.split("?")[0];
    }
  }

}
