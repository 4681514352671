import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface Notification {
  id: string;
  message: string;
  status: 'pending' | 'finished' | 'failed';
  downloadUrl?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ExportNotificationService {

  private notificationsSubject = new BehaviorSubject<Notification[]>([]);
  notifications$ = this.notificationsSubject.asObservable();

  private notifications: Notification[] = [];

  addNotification(notification: Notification) {
    this.notifications.push(notification);
    this.notificationsSubject.next([...this.notifications]); // Emit the updated list
  }

  updateNotification(id: string, status: 'finished' | 'failed', downloadUrl?: string) {
    const notification = this.notifications.find(n => n.id === id);
    if (notification) {
      notification.status = status;
      if (downloadUrl) {
        notification.downloadUrl = downloadUrl;
      }
      this.notificationsSubject.next([...this.notifications]); 
    }
  }

  removeNotification(notificationId: string) {
    this.notifications = this.notifications.filter((n) => n.id !== notificationId);
    this.notificationsSubject.next([...this.notifications]); // Update subscribers with the new array
  }

  resetAllNotifications(){
    this.notifications = [];
    this.notificationsSubject.next([...this.notifications]); // Update subscribers with the new empty array 
  }
}
