import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { RouterModule, provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
// import { HttpLoaderFactory } from './app.component';
import { SvgIconComponent, provideAngularSvgIcon } from 'angular-svg-icon';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpInterceptorService } from './core/authentication/interceptors/http-interceptor.service';
import { StorageService } from './core/authentication/services/storage.service';
import { ToastrModule } from 'ngx-toastr';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}
export const appConfig: ApplicationConfig = {
  providers: [provideZoneChangeDetection({ eventCoalescing: true }), provideRouter(routes) , 
    provideHttpClient(withInterceptorsFromDi()),
    NgbActiveModal,
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    importProvidersFrom(
      HttpClientModule,
      CommonModule,
      BrowserAnimationsModule,
      StorageService,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
      ToastrModule.forRoot({
        positionClass :'toast-bottom-right'
      }),
    ),
    provideAngularSvgIcon(),
    provideFirebaseApp(() => initializeApp({
      apiKey: "AIzaSyAhAC8VA4wZUv2xBoSPBOYH_FFqSH_ac5Y",
      authDomain: "blueride-v2.firebaseapp.com",
      databaseURL: "https://blueride-v2.firebaseio.com",
      projectId: "blueride-v2",
      storageBucket: "blueride-v2.appspot.com",
      messagingSenderId: "260468705826",
      appId: "1:260468705826:web:65546bcd95e3eab15f987b",
      measurementId: "G-NX0SMFZH08"
    })),
    provideFirestore(() => getFirestore())
  ]
};
