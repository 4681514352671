import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Trip } from '../../../../models/trip.model';
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../../../../shared/services/global.service';
import { ErrorMsgService } from '../../../../shared/services/error-msg.service';
import { SupervisiorService } from '../../_services/supervisior.service';
import { ArabicNumberPipe } from "../../../../shared/pipes/arabic-number.pipe";
@Component({
    selector: 'app-add-supervisior',
    standalone: true,
    templateUrl: './add-supervisior.component.html',
    styleUrl: './add-supervisior.component.scss',
    imports: [CommonModule, TranslateModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, ArabicNumberPipe]
})
export class AddSupervisiorComponent {
  supervisiorForm!: UntypedFormGroup;
  currLang!: string;
  modalTitle!: string;
  formFlag: string = 'add';
  loading!: boolean;
  fieldsReadOnly = false;
  id!: number;
  image: any;
  imageFile: any;
  selectedImage: any;

  trips: Trip[] = [];

  constructor(
    private supervisiorService: SupervisiorService,
    private translate: TranslateService,
    public activeModal: NgbActiveModal,

    private globalService: GlobalService,
    private errorMsgService: ErrorMsgService
  ) {
    this.globalService.getLang().subscribe((lng) => {
      this.currLang = lng ? lng : "en";
      this.translate.use(this.currLang);
    });

    this.translate.onLangChange.subscribe((res) => {
      this.currLang = res.lang ? res.lang : "en";
      this.translate.use(this.currLang);
    });
  }
  ngOnInit(): void {
    this.supervisiorForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      national_id: new UntypedFormControl(''),
      phone_no: new UntypedFormControl('', [Validators.required, Validators.pattern("[0-9]*")]),

      username: new UntypedFormControl(''),
      password: new UntypedFormControl(''),
    });
    this.supervisiorService.getSupervisiorData().subscribe((res) => {
      if (res?.supervisior != null) {
        this.formFlag = 'view';
        this.fieldsReadOnly = true;
        this.id = res.supervisior.id;
        this.supervisiorForm.controls['username'].setValue(
          res.supervisior.username
        );
        // this.image = environment.img_url + res.supervisior.image_path;

        this.supervisiorForm.controls['name'].setValue(res.supervisior.name);
        this.supervisiorForm.controls['phone_no'].setValue(
          res.supervisior.phone_no
        );
        this.supervisiorForm.controls['national_id'].setValue(
          res.supervisior.national_id
        );
        this.trips = res.supervisior.trips;
      }
    });
    this.setValidators();
  }
  setValidators() {
    const usernameControl:any = this.supervisiorForm.get('username');
    const passwordControl:any = this.supervisiorForm.get('password');

    if (this.formFlag === 'add') {
      usernameControl.setValidators([Validators.required]);
      passwordControl.setValidators([Validators.required]);
    } else {
      // to clear the validation in case of edit as the fields are hidden in edit view , so the save btn isn't disabled
      usernameControl.clearValidators();
      passwordControl.clearValidators();
    }

    usernameControl.updateValueAndValidity();
    passwordControl.updateValueAndValidity();
  }
  closeModal() {
    this.activeModal.close('Close click');
  }
  getImage(url: any) {
    // return environment.img_url + url;
  }

  getValidationMessage(control: string) {
    let errorMsg;
    if (this.supervisiorForm.controls[control].errors) {
      const fieldName = this.translate.instant(
        'supervisiors.add-supervisior-modal.' + control
      );
      const requiredText = this.translate.instant(
        'supervisiors.add-supervisior-modal.required'
      );
      const invalidText = this.translate.instant(
        'supervisiors.add-supervisior-modal.invalid'
      );
      errorMsg =
        this.supervisiorForm.controls[control]?.errors &&
          this.supervisiorForm.controls[control]?.errors?.['required']
          ? `${fieldName + requiredText}`
          : `${fieldName + invalidText}`;
    }
    return errorMsg;
  }

  // image upload
  changeSupervisiorProfile() {
    let fileUpload: any;
    fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.onclick = function () {
      fileUpload.value = null;
    };
    fileUpload.onchange = () => {
      this.selectedImage = null;
      // tslint:disable-next-line:prefer-for-of
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.imageFile = file;
      }
    };

    fileUpload.click();
  }

  onFileSelected(file: any) {
    // file: FileList
    this.selectedImage = file.item(0);
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.image = event.target.result;
    };
    reader.readAsDataURL(this.selectedImage);
  }
  editSupervisior() {
    this.formFlag = 'edit';
    this.fieldsReadOnly = false;
  }

  saveSupervisior() {
    let sub;

    const body = this.supervisiorForm.value;
    if (this.formFlag == 'add') {
      sub = this.supervisiorService.addSupervisior(body)
    } else {
      delete body.password;
      sub = this.supervisiorService
        .editSupervisior({ ...body, supervisor_id: this.id })
    }
    sub.subscribe(
      (res) => {
        this.supervisiorService.setLoadSupervisiorsData(true);
        this.closeModal()
      },
      (error) => {
        this.errorMsgService.setMessage(error.error.Error[0]);
      }
    );
  }
  deactive() { }
  convertTime(time: any) {
    return this.globalService.convertTime(time);
  }

  ngOnDestroy() {
    this.supervisiorService.setSupervisiorData(null, 'add')
  }

}
