import { Component } from '@angular/core';
import { ExportNotificationService , Notification } from '../../../services/export-notification.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [CommonModule , TranslateModule],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss'
})
export class NotificationsComponent {
  notifications: Notification[] = [];

  constructor(private exportNotificationService: ExportNotificationService) {}

  ngOnInit() {
    this.exportNotificationService.notifications$.subscribe(notifications => {
      this.notifications = notifications;
    });
  }

  downloadFile(downloadUrl:any , notificationId:any) {
    window.location.href = downloadUrl; // Trigger file download
    this.removeNotification(notificationId)
  }
  
  removeNotification(notificationId:any) {
    this.notifications = this.notifications.filter(n => n.id !== notificationId); // Remove notification by ID locally 
    this.exportNotificationService.removeNotification(notificationId); // Remove notification from notification service listner
  }
}
