import { Component, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../services/global.service';
import { ConfirmDeleteService } from './confirm-delete.service';
import { CommonModule } from '@angular/common';
import { ArabicNumberPipe } from '../../pipes/arabic-number.pipe';
import { LocalizeCountPipe } from '../../pipes/localize-count.pipe';

@Component({
  selector: 'app-confirm-delete',
  standalone: true,
  imports: [CommonModule ,TranslateModule , ArabicNumberPipe , LocalizeCountPipe ],
  templateUrl: './confirm-delete.component.html',
  styleUrl: './confirm-delete.component.scss'
})
export class ConfirmDeleteComponent {
  msg = "";
  currLang!: any;

  @Input() deletedItemsType!: string;
  @Input() deletedItemsCount!: number;

  @Output() onConfirmDelete = new EventEmitter<void>();

  constructor(private confirmDeleteService: ConfirmDeleteService,
    private translate: TranslateService,
    private globalService: GlobalService,
    private renderer: Renderer2
  ) {
    this.globalService.getLang().subscribe((lng) => {
      this.currLang = lng ? lng : "en";
      this.translate.use(this.currLang);
    });
  }
  ngOnInit(): void {
  }


  localizeDeletedItems(type: string): string {
    if (this.currLang == 'en') {
      return type; // Return the input directly if language is English
    } else {
      return this.getArabicNames(type); // Return the Arabic name for other languages
    }
  }

  getArabicNames(type: string): string {
    switch (type) {
      case "trip": return 'الرحلة';
      case "bus": return 'الحافلة';
      case "area": return 'المنطقة';
      case "driver": return 'السائق';
      case "supervisor": return 'المشرف';
      default: return 'العنصر'; // Return a default Arabic name if type is not recognized
    }
  }

  confirmDelete() {
    this.onConfirmDelete.emit();
  }

  
  hideModal() {
    const modal = this.renderer.selectRootElement('#confirm-delete-popup', true);
    this.renderer.setStyle(modal, 'display', 'none');
    this.renderer.removeClass(modal, 'show');
  }

}
