import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-period-filter',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './period-filter.component.html',
  styleUrl: './period-filter.component.scss'
})
export class PeriodFilterComponent {
  @Input() selectedFilter: string = 'morning';
  @Output() filterChange = new EventEmitter<string>();

  ngOnInit() {
  }

  setFilter(filter: string) {
    this.selectedFilter = filter;
    this.filterChange.emit(this.selectedFilter);
  }
}
