<div *ngIf="guageIndex == 'canvas1'" class="row guage-chart-container">
    <div class="col-lg-12">
        <!-- <select [(ngModel)]="testGuage" (change)="randomGuage($event , 'canvas1')">
            <option *ngFor="let guage of testGuageOptions" [value]="guage">{{ guage }}</option>
        </select> -->
        <!-- Guage -->
        <div class="canvas-with-text-container">
            <canvas id="canvas1" width="300" height="300"></canvas>
            <div class="text-container">
                <div class="time-wrapper">
                    <div class="time">
                        {{guageInnerTime | arabicNumber: currLang}}
                    </div>
                    <div class="time-prefix">
                        {{'new-dashboard.delay-section.time-unit' | translate}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="guageIndex == 'canvas2'" class="row guage-chart-container">
    <!-- <select [(ngModel)]="testGuage" (change)="randomGuage($event , 'canvas2')">
        <option *ngFor="let guage of testGuageOptions" [value]="guage">{{ guage }}</option>
    </select> -->
    <div class="col-lg-12">
        <!-- Guage -->
        <div class="canvas-with-text-container">
            <canvas id="canvas2" width="300" height="300"></canvas>
            <div class="text-container">
                <div class="time-wrapper">
                    <div class="time">
                        {{guageInnerTime | arabicNumber: currLang}}
                    </div>
                    <div class="time-prefix">
                        {{'new-dashboard.delay-section.time-unit' | translate}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>