import { Component } from '@angular/core';
import { TripReports } from '../../../models/trip-reports.model';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../../shared/services/global.service';
import { ReportsService } from '../../management/_services/reports.service';
import { ErrorMsgService } from '../../../shared/services/error-msg.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ExportExcelService } from '../../management/_services/export-excel.service';
import { PrintService } from '../../management/_services/print.service';
import moment from 'moment';
import { ReportsHeaderComponent } from '../reports-header/reports-header.component';
import { CommonModule } from '@angular/common';
import { ControlBarComponent } from '../../../shared/components/control-bar/control-bar.component';
import { TableViewComponent } from '../../../shared/components/table-view/table-view.component';
import { ExportServiceService } from '../../../shared/services/export-service.service';

@Component({
  selector: 'app-trip-reports',
  standalone: true,
  imports: [ReportsHeaderComponent , CommonModule , ControlBarComponent , TableViewComponent],
  templateUrl: './trip-reports.component.html',
  styleUrl: './trip-reports.component.scss'
})
export class TripReportsComponent {
  tableControls: any = { data: [] };
  searchText: string = '';
  pageNumber: number = 1;
  itemsPerPage: number = 25;
  currLang: string = 'en';
  loading!: boolean;
  rowsView: string = 'list';
  filter = {};
  // filterStatus!: boolean;
  from!: number;
  to!: number;
  total!: number;
  from_date: any;
  to_date: any;

  dataArray: TripReports[] = [];
routeQueryParams:any;
  constructor(private translate: TranslateService,
    private globalService: GlobalService,
    private reportsService: ReportsService,
    private errorMsgService: ErrorMsgService,
    private router: Router,
    private route:ActivatedRoute,
    private exportExcel: ExportExcelService,
    private printService: PrintService,
    private exportService:ExportServiceService
  ) {
    this.globalService.getLang().subscribe((lng) => {
      this.currLang = lng ? lng : "en";
      this.translate.use(this.currLang);
    });

    this.translate.onLangChange.subscribe((res) => {
      this.currLang = res.lang ? res.lang : "en";
      this.translate.use(this.currLang);
      this.setTableData(this.dataArray);
    });
  }

  ngOnInit(): void {
 
    this.route.queryParams.subscribe((params) => {
      this.pageNumber=+params['page']||1;
      this.searchText=params['search']||'';
      this.from_date=params['from'] 
      this.to_date=params['to'] ;
      this.itemsPerPage=params['show']||25;
      this.routeQueryParams=params
      
      this.getTripsData();
    })
  }

  setTableData(data: TripReports[]) {
    this.tableControls = {
      selection: false,
      paging: true,
      title:'table-view.titles.trip-report',
      totalTitle:'table-view.total-titles.trip-report',
      view: this.rowsView,
      pagination: {
        from: this.from,
        to: this.to,
        total: this.total,
      },
      columns: [
        {
          name: this.translate.instant('reports.trip-reports.trip-name'),
          sort: false,
          type: 'string',
          source: 'trip_name',
        },
        {
          name: this.translate.instant('reports.trip-reports.trip-date'),
          sort: false,
          type: 'string',
          source: 'trip_date',
        },
        {
          name: this.translate.instant('reports.trip-reports.start-time'),
          sort: false,
          type: 'string',
          source: 'start_time',
        },
        {
          name: this.translate.instant('reports.trip-reports.end-time'),
          sort: false,
          type: 'string',
          source: 'end_time',
        },
        {
          name: this.translate.instant('reports.trip-reports.duration'),
          sort: false,
          type: 'string',
          source: 'trip_duration',
        },
        {
          name: this.translate.instant('reports.trip-reports.bus-name'),
          sort: false,
          type: 'string',
          source: 'bus_name',
        },
        {
          name: this.translate.instant('reports.trip-reports.from'),
          sort: false,
          type: 'string',
          source: 'from',
        },
        {
          name: this.translate.instant('reports.trip-reports.to'),
          sort: false,
          type: 'string',
          source: 'to',
        },
        {
          name: this.translate.instant('reports.trip-reports.supervisor'),
          sort: false,
          type: 'string',
          source: 'supervisor',
        },
        {
          name: this.translate.instant('reports.trip-reports.driver'),
          sort: false,
          type: 'string',
          source: 'driver',
        },
        {
          name: this.translate.instant('reports.trip-reports.status'),
          sort: false,
          type: 'string',
          source: 'status',
        }
      ],
      data: data,
    };
  }

  getTripsData() {

    this.loading = true;
    
    this.reportsService
      .getTripReports(this.pageNumber, this.itemsPerPage, this.searchText, this.from_date, this.to_date)
      .subscribe(
        (result) => {
          let res = result['data'].daily_trips;
          this.dataArray = res['data'].map((element: any) => {
            return {
              trip_name: element?.name,
              trip_name_link: true,
              trip_date: element?.date,
              start_time: element?.start_time,
              end_time: element?.end_time,
              // trip_duration: parseInt(element?.end_time.split(':')[0],10) -         
              // parseInt(element?.start_time.split(':')[0],10),
              trip_duration: this.getDuration(element?.start_time, element?.end_time),
              bus_name: element?.bus_name,
              from: element?.way == 'to_school' ? 'Home' : 'School',
              to: element?.way == 'to_school' ? 'School' : 'Home',
              id: element?.id,
              driver: element?.driver_name,
              supervisor: element?.supervisor_name,
              status:element?.status
            };
          });
          this.from = res['from'];
          this.to = res['to'];
          this.total = res['total'];
          this.setTableData(this.dataArray);
          this.loading = false;
        },
        (error) => {
          this.errorMsgService.setMessage(error.error.Error[0])

        }
      );
  }

  eventHandeler(event: any) {
    switch (event.action) {
      case 'linkClicked':
        this.linkClicked(event.target);
        break;
      case 'pagination':
        this.paginationHandling(event.target);
        break;
      case 'print':
        this.print();
        break;
      case 'export':
        this.exportEcxel();
        break;
    }
  }

  linkClicked(event: any) {
   this.viewTripDetails(event.row.id,event.row.trip_name)
  }


  onSeachFn(text: any) {
    this.searchText = text;
    this.pageNumber=1
    this.filter = {
      trips: {
        name: text.trim().toLowerCase(),
        operator: 'or',
      },
    };
    
    this.router.navigate(['layout/reports'], {
      queryParams: { page:this.pageNumber,search:this.searchText},
      queryParamsHandling: 'merge',
    });
  }
  setItemsPerPage($event: any) {
    this.itemsPerPage = $event;
    this.pageNumber = 1;
    this.router.navigate(['layout/reports'], {
      queryParams: { page: this.pageNumber,show:this.itemsPerPage },
      queryParamsHandling: 'merge',
    });

  }

  paginationHandling(event: any) {
    this.pageNumber += event.dir;
    this.router.navigate(['layout/reports'], {
      queryParams: { page: this.pageNumber },
      queryParamsHandling: 'merge',
    });
  }

  viewTripDetails(id: any,tripName:any) {
    localStorage.setItem('tripDetailsId', id)
    localStorage.setItem('tripDetailsName', tripName);
    this.reportsService.setLastTripsReportQueryParams(this.routeQueryParams)
    this.router.navigate([`layout/trip-details/${id}`]);
  }

  // exportEcxel() {
  //   this.loading = true;
  //   this.reportsService.exportTripReports(this.from_date, this.to_date, this.searchText).subscribe(res => {
  //     this.loading = false;
  //     this.exportExcel.downloadFile(res, 'TripReports');
  //   })
  //   // let valuesToBeExported: any = [];
  //   // if (this.currLang == 'en') {
  //   //   valuesToBeExported = this.dataArray.map(data => {
  //   //     return {
  //   //       'Trip Name': data['trip_name'],
  //   //       'Trip Date': data['trip_date'],
  //   //       'Start Time': data['start_time'],
  //   //       'End Time': data['end_time'],
  //   //       'Trip Duration': data['trip_duration'],
  //   //       'Bus Name': data['bus_name'],
  //   //       'From': data['from'],
  //   //       'To': data['to'],
  //   //       'Supervisor': data['supervisor'],
  //   //       'Driver': data['driver']
  //   //     }
  //   //   })
  //   // }
  //   // else if (this.currLang == 'ar') {
  //   //   valuesToBeExported = this.dataArray.map(data => {
  //   //     return {
  //   //       'اسم الرحله	': data['trip_name'],
  //   //       'تاريخ الرحلة	': data['trip_date'],
  //   //       'وقت البداية': data['start_time'],
  //   //       'وقت النهاية': data['end_time'],
  //   //       'مدة الرحلة': data['trip_duration'],
  //   //       'اسم الحافله': data['bus_name'],
  //   //       'من': data['from'],
  //   //       'إلى': data['to'],
  //   //       'المشرف': data['supervisor'],
  //   //       'السائق': data['driver']
  //   //     }
  //   //   })
  //   // }
  //   // this.exportExcel.exportAsExcelFile(valuesToBeExported, 'Trips Reports')
  // }

  exportEcxel(){
    const params: any = {search: this.searchText};

    if (this.from_date) {
      params.from_date = this.from_date;
    }
    
    if (this.to_date) {
      params.to_date = this.to_date;
    }
    this.exportService.exportFile(
      'backoffice/report/exportDailyTrips',
      'backoffice/Exports/getExportById',
      params
    )
  }


  print() {
    this.printService.printPdf('.table-container');
  }

  setDate(event: any, flag: string) {
    switch (flag) {
      case 'from':
        this.from_date = event;
        this.from_date = this.from_date == undefined || this.from_date == '' ? null : this.from_date;
        break;
      case 'to':
        this.to_date = event;
        this.to_date = this.to_date == undefined || this.to_date == '' ? null : this.to_date;
        break;
    }
    if ((this.from_date != null && this.to_date != null) || (this.from_date == null && this.to_date == null)) {
      this.router.navigate(['layout/reports'], {
        queryParams: { from:this.from_date,to:this.to_date },
        queryParamsHandling: 'merge',
      });
    
    }
  }

  getDuration(start: any, end: any) {
    let duration;
    let trip_dur;
    let hour_suffix;
    let mins_suffix;
    let hours;
    let absHours;
    let minutes;
    let absMins
    // get duration diff
    duration = moment.duration(moment(end, "HH:mm").diff(moment(start, "HH:mm"))).asMinutes();
    hours = Math.floor(duration / 60);
    // to handle negative hour diff in case of midnight 
    absHours = hours < 0 ? 12 + hours : hours;
    minutes = duration % 60;
    // to handle negative minutes
    absMins = minutes < 0 ? 60 + minutes : minutes;
    // to change the suffix string in case of plural hours and minutes
    hour_suffix = hours > 1 ? "Hours" : "Hour";
    mins_suffix = minutes > 1 ? "Minutes" : "Minute";
    /*
      case 1 : show minutes only in case of hours diff is less than one and minutes diff is more than zero
      case 2 : show hours and mins in case of hours diff is more than zero and minutes diff is more than zero
      case 3 : show hours only in case of hours diff is more than one and minutes diff is equal to zero
    */
    switch (true) {
      case absHours < 1 && absMins > 0:
        trip_dur = `${absMins} ${mins_suffix}`
        break;
      case absHours > 0 && minutes > 0:
        trip_dur = `${absHours} ${hour_suffix} and ${minutes} ${mins_suffix}`;
        break;
      case absHours > 0 && minutes == 0:
        trip_dur = `${absHours} ${hour_suffix}`
        break;
    }
    return trip_dur;
  }

}
