import { ChangeDetectorRef, Component, QueryList, ViewChildren } from '@angular/core';
import { TableViewComponent } from '../../../shared/components/table-view/table-view.component';
import { CommonModule } from '@angular/common';
import { ControlBarComponent } from '../../../shared/components/control-bar/control-bar.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArabicNumberPipe } from '../../../shared/pipes/arabic-number.pipe';
import { MatProgressSpinner, MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { environment } from '../../../../../environments/environment';
import moment from 'moment';
import { concat, concatMap, of } from 'rxjs';
import { GlobalService } from '../../../shared/services/global.service';
import { ErrorMsgService } from '../../../shared/services/error-msg.service';
import { BaseChartDirective, NgChartsModule } from 'ng2-charts';
import { Chart } from 'chart.js';
import { DashboardService } from '../dashboard.service';
import { Router, RouterModule } from '@angular/router';
import { ErrorMsgComponent } from '../../../shared/components/error-msg/error-msg.component';
import { CalendarModule } from 'primeng/calendar';
import { HalfCircleProgressBarComponent } from '../../../shared/components/half-circle-progress-bar/half-circle-progress-bar.component';
import { PeriodFilterComponent } from '../../../shared/components/period-filter/period-filter.component';
import { TableControls } from '../../../models/table-controls.model';
import { DailyGeneralCounts } from './_interfaces/dailyGeneralCounts.interface';
import { JourneyTimesData } from './_interfaces/journeyTimes.interface';
import { WaitingTimeData } from './_interfaces/waitingTime.interface';
import { BusAttendanceData, attendanceCountData } from './_interfaces/studentsAttendance.interface';
import { dailyDelayData } from './_interfaces/dailyDelay.interface';
import { driverDailyProgression, DriversDP } from './_interfaces/driverDailyProgression.interface'
import { StorageService } from '../../../authentication/services/storage.service';
declare var $: any;

@Component({
  selector: 'app-dashboard-view',
  standalone: true,
  imports: [
    CommonModule,
    ControlBarComponent,
    TableViewComponent,
    TranslateModule,
    ReactiveFormsModule,
    ArabicNumberPipe,
    MatProgressSpinnerModule,
    MatProgressSpinner,
    MatProgressBarModule,
    RouterModule,
    ErrorMsgComponent,
    CalendarModule,
    FormsModule,
    NgChartsModule,
    HalfCircleProgressBarComponent,
    PeriodFilterComponent
  ],
  templateUrl: './dashboard-view.component.html',
  styleUrl: './dashboard-view.component.scss'
})
export class DashboardViewComponent {

  driversArray: any = []
  // testGuage = 0;
  // testGuageOptions = [0, 1, 2, 3, 4, 5, 5.5, 6, 7, 7.5, 8, 9, 10, 11, 12, 13, 14, 15, 15.5, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 30.5, 50, 70, 80, 90, 95, 100]
  // users sections(main count api) 
  usersLoader = false;
  dismissalTrackingLoader = true;
  dailyGeneralCounts: DailyGeneralCounts = {
    "drivers_count": 0,
    "completed_trips_count": 0,
    "upcoming_trips_count": 0,
    "live_trips_count": 0,
    "passengers_count": 0,
    "present_passengers_count": 0,
    "missed_passengers_count": 0,
    "absent_passengers_count": 0,
    "supervisors_count": 0
  };

  // Journey Times section section
  lineChartInstance: any;
  lineChartOptions: any;
  lineChartLabels: any;
  lineChartType: any;
  lineChartLegend: any;
  lineChartData: any;
  lineChartRangeDates: any = [];
  formatedLineChartRangeDates: any = [];
  retrievedJourneyTimes: JourneyTimesData = {
    "morning_journey_times": [],
    "afternoon_journey_times": [],
    "dates": []
  }
  lineChartLoader = false;

  // Waiting time  Guage
  timingRank: string = "";
  timingRankClass: string = "";
  guageInnerTime: number = 0;
  waitingTimeGuageLoader: boolean = false;
  retrievedWaitingTime: WaitingTimeData = {
    morning_average_waiting_time: 0,
    afternoon_average_waiting_time: 0
  }

  // bus attendance section (pickup request api)
  busAttendanceBarChartOptions: any;
  busAttendanceBarChartLabels: any;
  busAttendanceBarChartType: any;
  busAttendanceBarChartLegend: any;
  busAttendanceBarChartData: any;
  busAttendanceRangeDates: any = [];
  busAttendanceBarChartLoader = false;
  retreivedBusAttendanceData: BusAttendanceData = {
    dates: [],
    morning_bus_attendance: {
      present_passengers_count: [],
      missed_passengers_count: [],
      absent_passengers_count: []
    },
    afternoon_bus_attendance: {
      present_passengers_count: [],
      missed_passengers_count: [],
      absent_passengers_count: []
    }
  }

  // daily delay times 
  dailyDelayLoader = false;
  retreivedDailyDelay: dailyDelayData = {
    morning_delay_time: 0,
    afternoon_delay_time: 0
  }

  // staff on duty 
  staffOnDutyLoader = false;
  staffOnDutySearch = "";
  staffOnDutyPerPage = 4;
  staffOnDutyIspaginated = 1;
  retreivedStaffOnDuty: any = {}

  // driver daily progression table (ddp)
  ddp_loader = false;
  ddp_search = '';
  ddp_isPaginated = 1;
  ddp_perpage = 5;
  ddp_currentPage = 1;
  ddp_from!: number;
  ddp_to!: number;
  ddp_total!: number;
  ddp_tableControls: TableControls = { data: [] };
  ddp_tableArray: DriversDP = []
  retreivedDriverDailyProgression: any = {}

  // drivers on duty 
  driverOnDutyLoader = false;
  driverOnDutySearch = "";
  driverOnDutyPerPage = 4;
  driverOnDutyIspaginated = 1;
  retreivedDriverOnDuty: any = {}


  // tool tips 
  toolTipsTypes: any = {
    requested: false,
    not_requested: false,
    blueride_usage: false,
    blueride_usage_date_picker: false,
    dismissal_types: false,
    request_tracking: false,
    request_tracking_date_picker: false,
    dismissal_performance: false,
    dismissal_performance_date_picker: false,
    attendace: false,
    attendace_date_picker: false,
    leaderboard: false,
    leaderboard_date_picker: false,
    reach: false,
    parents: false,
    joined_bus: false,
  }

  dismissalPerformanceGuageLoader = false
  // Dates
  mainDate: any;
  sevenDaysAgo: any;
  formatedMainDate: any;
  todaysDate: any;
  dateFormat: string = "dd/mm/yy";
  attendanceRecordDate: any

  //general 
  currLang: any;
  company_id: any;
  baseUrl = environment.dashboard_img_url;
  periodFilterSelected1: string = 'morning';
  periodFilterSelected2: string = 'morning';
  periodFilterSelected3: string = 'morning';
  // testGuage = 0;
  // testGuageOptions = [0,1,2,3,4,5,5.5,6,7,7.5,8,9,10,11,12,13,14,15,15.5,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,30.5]

  // to get the reference to all charts directives on this page
  @ViewChildren(BaseChartDirective) chartDirectives!: QueryList<BaseChartDirective>;
  @ViewChildren(PeriodFilterComponent) periodFilters!: QueryList<PeriodFilterComponent>;

  constructor(private dashboarService: DashboardService, private globalService: GlobalService, private translate: TranslateService, private errorMsgService: ErrorMsgService,
    private storage: StorageService,
    private cdr: ChangeDetectorRef,
    private router: Router,
  ) {
    this.globalService.getLang().subscribe(lng => {
      this.currLang = lng;
    });
    this.translate.onLangChange.subscribe((res) => {
      this.currLang = res.lang ? res.lang : "en";
      this.translate.use(this.currLang);
      this.setDdpTableData();
    });
  }

  ngOnInit(): void {
    this.company_id = JSON.parse(localStorage.getItem('userData') || '{}')?.value?.company_id ?? null;
    this.todaysDate = new Date();
    this.initDashboard(null);
    this.globalService.getLang().subscribe((lng: any) => {
      this.currLang = lng;
      this.setLineChart('morning');
      this.drawBusAttendanceChart('morning');
    });
  }

  ngAfterViewInit(): void {
    this.errorMsgService.cleanUpOpenedModals();
    this.drawGuage(0);
    this.lineChartInstance = (document.getElementById('lineChart') as any).chartInstance; // Access the chart instance
    this.updateChartGradient();
  }

  initDashboard(date: any) {
    if (date) {
      this.resetPeriodFilters();
    }
    this.ddp_currentPage = 1;
    // main dashboard date
    this.mainDate = date == null || date == '' ? new Date() : this.attachCurrentTimeToSelectedDate(date);
    localStorage.setItem('dashboardMainDate', this.mainDate);
    // this.formatedMainDate = moment(new Date(this.mainDate)).locale('en').format('YYYY-MM-DD');
    this.formatedMainDate = moment.utc(this.mainDate).locale('en').format('YYYY-MM-DD');
    // this.formatedMainDate = moment(this.mainDate).subtract(3,'h').add(localStorage.getItem('timeZoneOffset'),'h').locale('en').format('YYYY-MM-DD');
    // seven days ago from main date
    this.sevenDaysAgo = new Date(new Date(this.mainDate).setDate(new Date(this.mainDate).getDate() - 7));

    // calling all dashboard apis one by one
    this.concatDashboardApis();
    localStorage.setItem('journeyPeriodValue', 'morning');
    localStorage.setItem('attendancePeriodValue', 'morning');
    // localStorage.setItem('dashboardMainDate', this.mainDate);
  }

  concatDashboardApis() {
    this.usersLoader = true;
    this.dismissalTrackingLoader = true;
    this.lineChartLoader = true;
    this.busAttendanceBarChartLoader = true;
    this.dailyDelayLoader = true;
    this.staffOnDutyLoader = true;
    this.ddp_loader = true;
    this.driverOnDutyLoader = true;

    const serviceCall1 = this.dashboarService.getDailyCounts(this.formatedMainDate, this.company_id);
    const serviceCall2 = this.dashboarService.getJourneyTimes(this.formatedMainDate, this.company_id);
    const serviceCall3 = this.dashboarService.getWaitingTime(this.formatedMainDate, this.company_id);
    const serviceCall4 = this.dashboarService.getBusAttendance(this.formatedMainDate, this.company_id);
    const serviceCall5 = this.dashboarService.getDailyDelay(this.formatedMainDate, this.company_id);
    const serviceCall6 = this.dashboarService.getStaffOnDuty(this.formatedMainDate, this.company_id, this.staffOnDutySearch, this.staffOnDutyPerPage, this.staffOnDutyIspaginated , 1);
    const serviceCall7 = this.dashboarService.getDriverDailyProgression(this.formatedMainDate, this.company_id, this.ddp_search, this.ddp_perpage, this.ddp_isPaginated, this.ddp_currentPage);
    const serviceCall8 = this.dashboarService.getDriverOnDuty(this.formatedMainDate, this.company_id, this.driverOnDutySearch, this.driverOnDutyPerPage, this.driverOnDutyIspaginated , 1);
    const results = concat(
      serviceCall1.pipe(concatMap(response => of({ response1: response }))),
      serviceCall2.pipe(concatMap(response => of({ response2: response }))),
      serviceCall3.pipe(concatMap(response => of({ response3: response }))),
      serviceCall4.pipe(concatMap(response => of({ response4: response }))),
      serviceCall5.pipe(concatMap(response => of({ response5: response }))),
      serviceCall6.pipe(concatMap(response => of({ response6: response }))),
      serviceCall7.pipe(concatMap(response => of({ response7: response }))),
      serviceCall8.pipe(concatMap(response => of({ response8: response })))
    );
    results.subscribe((result: any) => {
      if (result.response1) {
        if (typeof result.response1 === 'object'
          && result.response1.hasOwnProperty('data')
          && result.response1.data
          && typeof result.response1.data === 'object'
          && Object.keys(result.response1.data).length > 0) {
          this.dailyGeneralCounts = result.response1.data;
        }
        else {
          this.resetDailyGeneralCountsObj();
        }
        this.usersLoader = false;
        this.dismissalTrackingLoader = false;
      }
      if (result.response2) {
        if (typeof result.response2 === 'object'
          && result.response2.hasOwnProperty('data')
          && result.response2.data
          && typeof result.response2.data === 'object'
          && Object.keys(result.response2.data).length > 0) {
          this.retrievedJourneyTimes = result.response2.data;
          this.setLineChart('morning');
          this.lineChartLoader = false;
        }
        // this.missingChartsTodaysDataReplacements(this.retrievedRequestUsage, 'bluerideUsage', this.formatedLineChartRangeDates[1]);
      }
      if (result.response3) {
        if (typeof result.response3 === 'object'
          && result.response3.hasOwnProperty('data')
          && result.response3.data
          && typeof result.response3.data === 'object'
          && Object.keys(result.response3.data).length > 0) {
          this.retrievedWaitingTime = result.response3.data;
          this.drawGuage(this.retrievedWaitingTime.morning_average_waiting_time);
          this.waitingTimeGuageLoader = false;
        }
      }
      if (result.response4) {
        if (typeof result.response4 === 'object'
          && result.response4.hasOwnProperty('data')
          && result.response4.data
          && typeof result.response4.data === 'object'
          && Object.keys(result.response4.data).length > 0) {
          this.retreivedBusAttendanceData = result.response4.data;
          // this.missingChartsTodaysDataReplacements(this.retreivedPickupTrackingData, 'pickupTracking', this.formatedPickupTrackingRangeDates[1]);
          this.drawBusAttendanceChart('morning');
          this.busAttendanceBarChartLoader = false;
        }
      }
      if (result.response5) {
        if (typeof result.response5 === 'object'
          && result.response5.hasOwnProperty('data')
          && result.response5.data
          && typeof result.response5.data === 'object'
          && Object.keys(result.response5.data).length > 0) {
          this.retreivedDailyDelay = result.response5.data;
          this.dailyDelayLoader = false;
        }
      }
      if (result.response6) {
        if (typeof result.response6 === 'object'
          && result.response6.hasOwnProperty('data')
          && result.response6.data
          && typeof result.response6.data === 'object'
          && Object.keys(result.response6.data).length > 0) {
          this.retreivedStaffOnDuty = result.response6.data.supervisors;
          this.staffOnDutyLoader = false;
        }
      }
      if (result.response7) {
        if (typeof result.response7 === 'object'
          && result.response7.hasOwnProperty('data')
          && result.response7.data
          && typeof result.response7.data === 'object'
          && Object.keys(result.response7.data).length > 0) {
          this.retreivedDriverDailyProgression = result.response7.data.drivers;
          this.dailyProgressionResponseMapper(this.retreivedDriverDailyProgression);
          this.setDdpTableData();
          this.ddp_loader = false;
        }
      }
      if (result.response8) {
        if (typeof result.response8 === 'object'
          && result.response8.hasOwnProperty('data')
          && result.response8.data
          && typeof result.response8.data === 'object'
          && Object.keys(result.response8.data).length > 0) {
          this.retreivedDriverOnDuty = result.response8.data.drivers;
          this.driverOnDutyLoader = false;
        }
      }
    }, err => {
      this.errorMsgService.setMessage(err?.error?.message);
    });
  }


  setLineChart(periodFilter: string) {
    this.lineChartData = [
      {
        type: 'line',
        data: periodFilter == 'afternoon' ? this.retrievedJourneyTimes?.afternoon_journey_times : this.retrievedJourneyTimes?.morning_journey_times,
        borderColor: '#0074EC',
        borderWidth: 2,
        // borderDash: [10, 10],
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        fill: true,
        tension: 0.5,
        backgroundColor: this.createGradient()
      },
      // {
      //   type: 'line',
      //   data: this.retrievedRequestUsage?.not_requested_count,
      //   borderColor: '#F46363',
      //   borderWidth: 2,
      //   borderDash: [10, 10],
      //   pointBackgroundColor: 'transparent',
      //   pointBorderColor: 'transparent',
      //   fill: false,
      //   tension: 0.5,
      // },
    ]
    this.lineChartLabels = this.formatChartDates(this.retrievedJourneyTimes?.dates, 'multiple'),

      this.lineChartOptions = {
        scales: {
          x: {
            grid: {
              display: false,
            }
          },
          y: {
            grid: {
              display: true,
              color: '#DEDEDE',
              // borderColor: 'grey',
              tickColor: 'transparent'
            }
          }
        },
        maintainAspectRatio: false,
        tooltips: { enabled: true, displayColors: false },
        scaleShowVerticalLines: false,
        responsive: true,
        plugins: {
          legend: { display: false }
        }
      };
    this.lineChartType = 'line'
  }


  // pickup tracking section
  drawBusAttendanceChart(periodFilter: string) {
    let retrievedDatePerPeriod: attendanceCountData = {
      present_passengers_count: [],
      missed_passengers_count: [],
      absent_passengers_count: [],
    }
    retrievedDatePerPeriod = periodFilter == 'afternoon' ? this.retreivedBusAttendanceData.afternoon_bus_attendance : this.retreivedBusAttendanceData.morning_bus_attendance

    this.busAttendanceBarChartData = [
      {
        type: 'line',
        data: this.getArraysMaxValues([retrievedDatePerPeriod.present_passengers_count, retrievedDatePerPeriod?.absent_passengers_count, retrievedDatePerPeriod?.missed_passengers_count]),
        borderColor: '#75B5F8',
        borderWidth: 2,
        borderDash: [10, 10],
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        fill: false,
        tension: 0.5,
      },
      {
        type: 'bar',
        data: retrievedDatePerPeriod?.present_passengers_count,
        label: this.currLang == 'ar' ? "حاضر" : "Present",
        backgroundColor: "#46CCA0",
        hoverBackgroundColor: "#46CCA0",
        // barPercentage: 0.5,
        // barThickness: 23,
        // maxBarThickness: 10,
        // minBarLength: 2,
        // borderRadius: 10,
        name: 'Present',
        hidden: false,
      },
      {
        type: 'bar',
        data: retrievedDatePerPeriod?.absent_passengers_count,
        label: this.currLang == 'ar' ? "غائب" : "Absent",
        backgroundColor: "#D92D20",
        hoverBackgroundColor: "#D92D20",
        // barPercentage: 0.5,
        // barThickness: 23,
        // maxBarThickness: 10,
        // minBarLength: 2,
        // borderRadius: 10,
        name: 'Absent',
        hidden: false,

      },
      {
        type: 'bar',
        data: retrievedDatePerPeriod?.missed_passengers_count,
        label: this.currLang == 'ar' ? "تم تفويت الرحلة" : "Missed",
        backgroundColor: "#FDA29B",
        hoverBackgroundColor: "#FDA29B",
        // barPercentage: 0.5,
        // barThickness: 23,
        // maxBarThickness: 10,
        // minBarLength: 2,
        // borderRadius: 10,
        name: 'Missed',
        hidden: false,
      },
    ]
    this.busAttendanceBarChartLabels = this.formatChartDates(this.retreivedBusAttendanceData?.dates, 'multiple'),

      this.busAttendanceBarChartOptions = {
        categoryPercentage: .65,
        barPercentage: 0.8,
        scales: {
          x: {
            grid: {
              display: false,
            }
          },
          y: {
            grid: {
              display: true,
              color: '#DEDEDE',
              // borderColor: 'grey',
              tickColor: 'transparent'
            }
          }
        },
        maintainAspectRatio: false,
        tooltips: { enabled: true, displayColors: false },
        scaleShowVerticalLines: false,
        responsive: true,
        plugins: {
          legend: { display: false }
        }
      };
  }



  drawGuage(indicatorValue: any) {
    let indicatorColor;
    // let guageInicatorValue = indicatorValue;
    this.guageInnerTime = indicatorValue;
    if (indicatorValue < 0) {
      // indicatorValue = guageInicatorValue = 0;
      indicatorValue = this.guageInnerTime = 0
    }
    if (indicatorValue > 30) {
      indicatorValue = 30;
    }
    // Get the canvas element and its 2d context
    const canvas: any = document.getElementById("gaugeCanvas");
    const ctx = canvas.getContext("2d");
    // to clear the canvas
    // Define the gauge properties
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    const radius = 100;
    const startAngle = Math.PI;
    const endAngle = 2.02 * Math.PI;
    const numRanges = 4;
    // const rangeColors = ["#F46363", "#F5D21E", "#ECA336", "#46CEA2"];
    const rangeColors = ["#46CEA2", "#ECA336", "#F5D21E", "#F46363"];
    const rangeMaxValue = 30;
    const rangeWidth = (endAngle - startAngle) / numRanges; // Calculate the range width
    const rangeThickness = 0.2; // Adjust the thickness of the ranges (in radians)
    const rangeSpacing = 0.06; // Adjusted range spacing
    const borderRadius = 0.02;
    // Define the indicator properties
    const indicatorRadius = 14 // Adjust the indicator circle's radius
    const indicatorDistance = -5;
    // let indicatorValue = 90; // Adjust this value to set the indicator position

    switch (true) {
      case indicatorValue <= 7.5:
        indicatorColor = '#46CEA2'
        this.timingRank = 'Excellent'
        this.timingRankClass = 'excellent'
        break;
      case indicatorValue > 7.5 && indicatorValue <= 15:
        indicatorColor = '#ECA336'
        this.timingRank = 'Moderate'
        this.timingRankClass = 'moderate'

        break;
      case indicatorValue > 15 && indicatorValue <= 22.5:
        indicatorColor = '#F5D21E'
        this.timingRank = 'Okay'
        this.timingRankClass = 'okay'

        break;

      case indicatorValue > 22.5:
        indicatorColor = '#F46363'
        this.timingRank = 'Weak'
        this.timingRankClass = 'weak'
        break;
    }

    // Draw the gauge ranges with thickness
    for (let i = 0; i < numRanges; i++) {
      const start = startAngle + i * rangeWidth;
      const end = start + rangeWidth - rangeSpacing;

      ctx.beginPath();
      ctx.arc(centerX, centerY, radius, start, end);
      ctx.strokeStyle = rangeColors[i];
      ctx.lineWidth = rangeThickness * radius;
      ctx.stroke();
    }

    // Function to draw the indicator
    const normalizedValue = indicatorValue / rangeMaxValue;
    const indicatorAngle = startAngle + normalizedValue * (endAngle - startAngle);
    const x = centerX + (Math.cos(indicatorAngle) * (radius - rangeSpacing * radius - indicatorDistance));
    const y = centerY + (Math.sin(indicatorAngle) * (radius - rangeSpacing * radius - indicatorDistance));
    ctx.beginPath();
    ctx.arc(x, y, indicatorRadius, 0, 2 * Math.PI);
    ctx.fillStyle = indicatorColor;
    ctx.fill();

    // Function to draw the inner indicator
    ctx.beginPath();
    ctx.arc(x, y, indicatorRadius / 2, 0, 2 * Math.PI);
    ctx.fillStyle = '#FFF';
    ctx.fill();
  }

  // limmitting the chart dates to one month only for obtimization
  limitDateToOneMonth(rangeDates: any) {
    // limit range difference between the two dates to be 30 dates
    if (rangeDates[0] && rangeDates[1]) {
      const millisecondsInOneDay = 24 * 60 * 60 * 1000;
      const maxDifferenceInDays = 30;
      const daysDifference = Math.ceil((rangeDates[1].getTime() - rangeDates[0].getTime()) / millisecondsInOneDay);

      if (daysDifference > maxDifferenceInDays) {
        // If the difference is greater than 30 days, update the second date to be 30 days ahead of the first date
        rangeDates[1] = new Date(rangeDates[0].getTime() + (maxDifferenceInDays * millisecondsInOneDay));
      }
    }

    // limit the dates to be in the same month
    // if (rangeDates[0] && rangeDates[1]) {
    //   if (rangeDates[0].getMonth() !== rangeDates[1].getMonth()) {
    //     rangeDates[1] = new Date(
    //       rangeDates[0].getFullYear(),
    //       rangeDates[0].getMonth() + 1,
    //       0
    //     );
    //   }
    // }
  }

  // toggle Generate excel modal
  generateExcelFile() {
    $('#exportReports').modal("show");
  }

  // toggle toolTips
  toggleToolTip(type: any, action: any) {
    if (action == 'show') {
      this.toolTipsTypes[type] = true;
    }
    else {
      this.toolTipsTypes[type] = false;
    }
  }

  // General Methods for calculations
  getPercentage(value: any, total: any) {
    let progress: any
    if (total == 0) {
      total = 1
    }
    progress = Math.round(((value / total) * 100) * 10) / 10;
    return progress
  }

  destructObject2Array(obj: any, selectedKeys: string[]) {
    const selectedValuesArray = selectedKeys.map(key => obj[key]);
    return selectedValuesArray
  }

  getTotalOfNumbers(...numbers: number[]): number {
    return numbers.reduce((sum, num) => sum + num, 0);
  }

  subtractNumbers(...numbers: number[]): number {
    if (numbers.length === 0) {
      throw new Error('At least one number must be provided for subtraction.');
    }
    return numbers.reduce((difference, num) => difference - num);
  }

  formatChartDates(dates: any, type: any) {
    if (type == 'multiple') {
      return dates.map((dateString: any) => {
        const [year, month, day] = dateString.split('-');
        return `${day}/${month}`;
      });
    }
    else {
      let tempDate;
      tempDate = moment(new Date(dates)).format('YYYY-MM-DD');
      const [year, month, day] = tempDate.split('-');
      return `${day}/${month}`;
    }
  }

  formatDateForApi(date: any) {
    let tempDate;
    tempDate = moment(new Date(date)).format('YYYY-MM-DD');
    return tempDate;
  }

  getArraysMaxValues(arrays: number[][]): number[] {
    // Ensure that there's at least one array
    if (arrays.length === 0) {
      throw new Error('At least one array must be provided.');
    }

    // Use the reduce function to iterate over each array and find the maximum for each index
    return arrays.reduce((maxValues, currentArray) => {
      // Iterate over each element in the current array
      currentArray.forEach((value, index) => {
        // Update the maximum value for the current index
        maxValues[index] = Math.max(maxValues[index] || Number.MIN_SAFE_INTEGER, value);
      });

      return maxValues;
    }, []);
  }

  resetDailyGeneralCountsObj() {
    this.dailyGeneralCounts = {
      "drivers_count": 0,
      "completed_trips_count": 0,
      "upcoming_trips_count": 0,
      "live_trips_count": 0,
      "passengers_count": 0,
      "present_passengers_count": 0,
      "missed_passengers_count": 0,
      "absent_passengers_count": 0,
      "supervisors_count": 0
    };
  }

  // randomGuage(event){
  //   console.log(event.target.value);
  //   this.drawGuage(event.target.value);
  // }

  createGradient(): CanvasGradient {
    const ctx = (document.getElementById('lineChart') as HTMLCanvasElement).getContext('2d');
    if (!ctx) {
      throw new Error('Context not found');
    }

    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    // Use the values from the Figma gradient

    gradient.addColorStop(0.33, 'rgba(197, 226, 249 , 0.5'); // Start 
    gradient.addColorStop(0.5, 'rgba(237, 245, 252  , 0.5)');
    gradient.addColorStop(0.7, 'rgba(237, 245, 252  , 0.7)');
    gradient.addColorStop(0.99, 'rgba(255, 255, 255 , 0.9)');
    return gradient;
  }

  updateChartGradient() {
    // Force the chart to redraw after the gradient is created
    (this.lineChartInstance as any)?.update();
  }


  randomGuage(event: any) {
    // console.log(event.target.value);
    this.drawGuage(event.target.value);
  }

  periodFilterChanged(event: string, chart: string) {
    switch (chart) {
      case 'journey':
        localStorage.setItem('journeyPeriodValue', event)
        this.setLineChart(event)
        break;
      case 'guage':
        let guagueValue = event == 'afternoon' ? this.retrievedWaitingTime.afternoon_average_waiting_time : this.retrievedWaitingTime.morning_average_waiting_time
        this.drawGuage(guagueValue)
        break;
      case 'attendance':
        localStorage.setItem('attendancePeriodValue', event)
        this.drawBusAttendanceChart(event)
        break;
      default:
        break;
    }
  }

  resetPeriodFilters() {
    // Update the references to trigger ngOnChanges in the child components
    this.periodFilters.forEach((filterComponent) => {
      filterComponent.setFilter('morning');
    });
    this.cdr.detectChanges();
  }

  getTableData() {
    let driver: any = {
      name: "Ahmed Mohamed",
      phone: "966632830",
      live_trip: "MorningTripmanhal 6-october",
      unfinished_trips: "1/9",
      canceled_trips: '5'
    }
    for (let index = 0; index < 100; index++) {
      this.driversArray.push(driver)
    }
  }

  // daily driver progression
  getdailyProgressionData() {
    this.ddp_loader = true;
    const serviceCall = this.dashboarService.getDriverDailyProgression(this.formatedMainDate, this.company_id, this.ddp_search, this.ddp_perpage, this.ddp_isPaginated, this.ddp_currentPage);
    serviceCall.subscribe((result: any) => {
      this.retreivedDriverDailyProgression = result.data.drivers;
      this.dailyProgressionResponseMapper(this.retreivedDriverDailyProgression);
      this.setDdpTableData();
      this.ddp_loader = false;
    }, err => {
      this.errorMsgService.setMessage(err?.error?.message);
    });
  }

  dailyProgressionResponseMapper(res: any) {
    let dataArray: DriversDP = [];
    dataArray = res['data'].map((element: driverDailyProgression) => (
      {
        image_path: this.storage.imageUrl + '/' + element.image_path,
        name: element.name,
        canceled_trips_count: element.canceled_trips_count,
        id: element.id,
        in_progress_trips: element.in_progress_trips,
        in_progress_trip: element.in_progress_trips.length != 0 ? element.in_progress_trips[0] : 0,
        phone_no: element.phone_no,
        total_trips_count: element.total_trips_count,
        unfinished_trips_count: element.unfinished_trips_count,
        trips_combined_counts: {
          first_number: element.unfinished_trips_count,
          second_number: element.total_trips_count,
        }
      }
    ))
    this.ddp_tableArray = dataArray;
    this.ddp_from = res.from;
    this.ddp_to = res.to;
    this.ddp_total = res.total;
  }

  ddpTableEventHandeler(event: { action: string; target: any }) {
    switch (event.action) {
      case 'pagination':
        this.ddpPaginationHandling(event.target);
        break;
    }
  }


  ddpPaginationHandling(event: { dir: number }) {
    this.ddp_currentPage += event.dir;
    this.getdailyProgressionData();
  }

  setDdpTableData() {
    this.ddp_tableControls = {
      selection: false,
      paging: true,
      view: 'list',
      title: 'new-dashboard.driver-daily-progression-section.title',
      showTotalNumberFlag: false,
      pagination: {
        from: this.ddp_from,
        to: this.ddp_to,
        total: this.ddp_total,
      },
      columns: [
        {
          name: '',
          sort: false,
          type: 'image',
          source: 'image_path',
        },
        {
          name: this.translate.instant('new-dashboard.driver-daily-progression-section.name'),
          sort: false,
          type: 'string',
          source: 'name',
        },
        {
          name: this.translate.instant('new-dashboard.driver-daily-progression-section.phone'),
          name2: 'drivers.trips',
          sort: false,
          type: 'string',
          source: 'phone_no',
        },
        {
          name: this.translate.instant('new-dashboard.driver-daily-progression-section.progress-trip'),
          sort: false,
          type: 'translateNumbers',
          source: 'in_progress_trip',
        },
        {
          name: this.translate.instant('new-dashboard.driver-daily-progression-section.unfinished-trips'),
          sort: false,
          type: 'backSlashSeperatedNumbers',
          source: 'trips_combined_counts',
        },
        {
          name: this.translate.instant('new-dashboard.driver-daily-progression-section.cancelled-trips'),
          sort: false,
          type: 'translateNumbers',
          source: 'canceled_trips_count',
        }
      ],
      data: this.ddp_tableArray,
    };
  }

  getAttendanceRecordDate(date: any) {
    let formatedDate: any
    formatedDate = this.currLang == 'ar' ? moment(new Date(date)).locale(this.currLang).format('YYYY/MM/DD') :
      moment(new Date(date)).locale(this.currLang).format('DD/MM/YYYY');
    return formatedDate
  }

  attachCurrentTimeToSelectedDate(selectedDate: any) {
    let currentTime = new Date()
    let dateTime = new Date(selectedDate.getTime());
    dateTime.setHours(currentTime.getHours(), currentTime.getMinutes(), currentTime.getSeconds());
    return dateTime
  }

  navigateToReport(reportType: string, period?: string) {
    switch (reportType) {
      case 'delay':
        localStorage.setItem('delayPeriodValue', period as string)
        this.router.navigateByUrl('/layout/dashboard/daily-delay-report');
        break;
      case 'driver':
        this.router.navigateByUrl('/layout/dashboard/driver-duty-report');
        break;
      case 'staff':
        this.router.navigateByUrl('/layout/dashboard/supervisor-duty-report');
        break;
      default:
        break;
    }
  }
}
