<div class="header">
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" appDropdown>
        <img src="../../../../assets/images/svg/menu.svg" alt="menu" />
    </button>

    <mat-menu #menu="matMenu">
        <button (click)="openProfile()" mat-menu-item>
            <img src="../../../../assets/images/svg/profile.svg" alt="profile" />

            <span>{{ "side-nav.profile" | translate }}</span>
        </button>
        <button mat-menu-item (click)="toggleLang()">
            <img src="../../../../assets/images/svg/lang.svg" alt="language" />

            <span>{{ "side-nav.language" | translate }}</span>
        </button>
        <button (click)="logOut()" mat-menu-item>
            <img src="../../../../assets/images/svg/logout.svg" alt="logout" />

            <span>{{ "side-nav.logout" | translate }}</span>
        </button>
    </mat-menu>

    <div class="names-container">
        <span class="logged-name">
            <span>{{ loginName }} </span>
        </span>
    </div>

    <img class="school-img" src="" alt="" />
</div>