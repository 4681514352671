import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Trip } from '../../../../models/trip.model';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../../../../shared/services/global.service';
import { ErrorMsgService } from '../../../../shared/services/error-msg.service';
import { BusService } from '../../_services/bus.service';
import { ArabicNumberPipe } from "../../../../shared/pipes/arabic-number.pipe";

@Component({
    selector: 'app-add-bus',
    standalone: true,
    templateUrl: './add-bus.component.html',
    styleUrl: './add-bus.component.scss',
    imports: [CommonModule, TranslateModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, ArabicNumberPipe]
})
export class AddBusComponent {
  busForm!: UntypedFormGroup;
  currLang!: string;
  modalTitle!: string;
  formFlag: string = 'add';
  loading!: boolean;
  fieldsReadOnly = false;
  id!: number;

  trips: Trip[] = [];

  @ViewChild('saveBtn', { static: false }) saveBtn!: ElementRef;


  constructor(
    private busService: BusService,
    private translate: TranslateService,
    public activeModal: NgbActiveModal,
    private globalService: GlobalService,
    private errorMsgService: ErrorMsgService
  ) {
    this.globalService.getLang().subscribe((lng) => {
      this.currLang = lng ? lng : "en";
      this.translate.use(this.currLang);
    });

    this.translate.onLangChange.subscribe((res) => {
      this.currLang = res.lang ? res.lang : "en";
      this.translate.use(this.currLang);
    });
  }
  ngOnInit(): void {

    this.busForm = new UntypedFormGroup({
      name: new UntypedFormControl(''),
      // color: new UntypedFormControl('#000000'),
      plate_no: new UntypedFormControl('', Validators.required),
      brand: new UntypedFormControl(''),
      // model: new UntypedFormControl(''),
      capacity: new UntypedFormControl(null),
    });
    // this.busForm.controls['color'].enable();

    this.busService.getBusData().subscribe((res) => {
      if (res?.bus != null) {
        this.formFlag = 'view';
        this.fieldsReadOnly = true;
        this.id = res.bus.id;
        // this.busForm.controls['color'].setValue(res.bus.color);
        // this.busForm.controls['color'].disable();

        this.busForm.controls['name'].setValue(res.bus.name);
        this.busForm.controls['plate_no'].setValue(res.bus.plate_no);
        // this.busForm.controls['model'].setValue(res.bus.model);
        this.busForm.controls['capacity'].setValue(res.bus.capacity);
        this.busForm.controls['brand'].setValue(res.bus.brand);
        this.trips = res.bus.trips;
      }
    });
  }

  ngAfterViewInit(): void {
  }
  closeModal() {
    this.activeModal.close('Close click');
  }
  getValidationMessage(control: string) {
    let errorMsg;
    if (this.busForm.controls[control].errors) {
      const fieldName = this.translate.instant(
        'busses.add-bus-modal.' + control
      );
      const requiredText = this.translate.instant(
        'busses.add-bus-modal.required'
      );
      const invalidText = this.translate.instant(
        'busses.add-bus-modal.invalid'
      );
      errorMsg = this.busForm.controls[control].errors?.['required']
        ? `${fieldName + requiredText}`
        : `${control + invalidText}`;
    }
    return errorMsg;
  }

  editBus() {
    this.formFlag = 'edit';
    // this.busForm.controls['color'].enable();

    this.fieldsReadOnly = false;
  }

  saveBus() {
    let sub;
    let body = this.busForm.value;

    if (this.formFlag == 'add') {
      sub = this.busService.addBus(body)

    } else {
      sub = this.busService.editBus({ ...body, bus_id: this.id })

    }
    sub.subscribe(
      () => {
        this.busService.setLoadBussesData(true);
        this.closeModal();
      },
      (error) => {
        this.errorMsgService.setMessage(error.error.Error[0]);
      })
  }
  convertTime(time: any) {
    return this.globalService.convertTime(time);
  }

  ngOnDestroy() {
    this.busService.setBusData(null, 'add')
  }

}
