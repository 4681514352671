<div class="modal" id="error-msg-popup" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" (click)="closeModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="error text-center">
                    <img src="./../../../assets/images/error.svg">
                    <p>
                        {{msg}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>