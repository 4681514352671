import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../authentication/services/login.service';
import { GlobalService } from '../../services/global.service';
import { StorageService } from '../../../authentication/services/storage.service';
import { UserService } from '../../services/user.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap'
import { MatMenuModule } from '@angular/material/menu';
import { ProfileComponent } from '../profile/profile.component';
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [MatMenuModule,TranslateModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  currLang: any;
  direction!: string;
  lngFlag!: string;
  loginName!: string;
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private translate: TranslateService,
    private loginService: LoginService,

    private globalService: GlobalService,
    public storage: StorageService,
    private userService:UserService
  ) {
    this.globalService.getLang().subscribe((lng) => {
      this.currLang = lng ? lng : "en";
      this.translate.use(this.currLang);
    });
    this.translate.onLangChange.subscribe((res) => {
      this.currLang = res.lang ? res.lang : "en";
      this.translate.use(this.currLang);
    });
  }

  ngOnInit(): void {
    this.userService.getUserDataChanged().subscribe(res=>{
      this.loginName = this.storage.userData.name;
    })
  }
  openProfile() {
    this.modalService.open(ProfileComponent, { size: 'xl' });

  
  }
  toggleLang() {
    if (this.currLang == 'en') {
      this.globalService.setLang('ar');
      this.translate.use('ar');
      this.currLang = 'ar';
      this.direction = document.dir;
    } else {
      this.globalService.setLang('en');
      this.currLang = 'en';
      this.translate.use('en');
      this.direction = document.dir;
    }
    this.router.navigateByUrl(this.router.url);
  }
  logOut() {
    this.loginService.logout();
  }

}
