import { Component } from '@angular/core';
import { SideNavComponent } from "../shared/components/side-nav/side-nav.component";
import { HeaderComponent } from "../shared/components/header/header.component";
import { RouterModule } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../shared/services/global.service';
import { ErrorMsgComponent } from '../shared/components/error-msg/error-msg.component';
import { NotificationsComponent } from '../shared/components/export-notification/notifications/notifications.component';

@Component({
    selector: 'app-layout',
    standalone: true,
    templateUrl: './layout.component.html',
    styleUrl: './layout.component.scss',
    imports: [SideNavComponent, HeaderComponent , RouterModule , ErrorMsgComponent , NotificationsComponent]
})
export class LayoutComponent {
currLang!:string;
constructor(
  private translate: TranslateService,
  private globalService: GlobalService
) {
  this.globalService.getLang().subscribe((lng) => {
    this.currLang = lng ? lng :'en';
    this.translate.use(this.currLang);
  });
  this.translate.onLangChange.subscribe((res) => {
    this.currLang = res.lang ? res.lang : "en";
    this.translate.use(this.currLang);
  });
}
ngOnInit() {}
}
