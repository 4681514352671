import { Component } from '@angular/core';
import { TripDetails } from '../../../models/trip-details.model';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../../shared/services/global.service';
import { ReportsService } from '../../management/_services/reports.service';
import { ErrorMsgService } from '../../../shared/services/error-msg.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ExportExcelService } from '../../management/_services/export-excel.service';
import { PrintService } from '../../management/_services/print.service';
import moment from 'moment';
import { ReportsHeaderComponent } from '../reports-header/reports-header.component';
import { ControlBarComponent } from '../../../shared/components/control-bar/control-bar.component';
import { TableViewComponent } from '../../../shared/components/table-view/table-view.component';
import { CommonModule } from '@angular/common';
import { ExportServiceService } from '../../../shared/services/export-service.service';

@Component({
  selector: 'app-trip-details',
  standalone: true,
  imports: [ReportsHeaderComponent , ControlBarComponent , TableViewComponent , CommonModule],
  templateUrl: './trip-details.component.html',
  styleUrl: './trip-details.component.scss'
})
export class TripDetailsComponent {
  tableControls: any = { data: [] };
  searchText: string = '';
  pageNumber: number = 1;
  itemsPerPage: number = 25;
  currLang: string = 'en';
  loading!: boolean;
  rowsView: string = 'list';
  filter = {};
  from!: number;
  to!: number;
  total!: number;
  tripId!: number;
  tripName!: any;

  dataArray: TripDetails[] = [];
  sub: Subscription = new Subscription();
  routeQueryParams = {}
  constructor(
    private translate: TranslateService,
    private globalService: GlobalService,
    private reportsService: ReportsService,
    private errorMsgService: ErrorMsgService,
    private router: Router,
    private _ActivatedRoute: ActivatedRoute,
    private exportExcel: ExportExcelService,
    private printService: PrintService,
    private route: ActivatedRoute,
    private exportService:ExportServiceService

  ) {
    this.tripId = _ActivatedRoute.snapshot.params['id'];

    this.globalService.getLang().subscribe((lng) => {
      this.currLang = lng ? lng : "en";
      this.translate.use(this.currLang);
    });

    this.translate.onLangChange.subscribe((res) => {
      this.currLang = res.lang ? res.lang : "en";
      this.translate.use(this.currLang);
      this.setTableData(this.dataArray);
    });
  }

  ngOnInit(): void {
    this.tripName = localStorage.getItem('tripDetailsName');
    this.route.queryParams.subscribe((params) => {
      this.searchText = params['search'] || '';
      this.routeQueryParams = params
      this.getTripDetailsData();
    })

  }

  setTableData(data: TripDetails[]) {
    this.tableControls = {
      selection: false,
      paging: false,
      view: this.rowsView,
      title:'table-view.titles.trip-detail',
      totalTitle:'table-view.total-titles.trip-detail',
      columns: [
        {
          name: this.translate.instant('reports.trip-details.passenger-name'),
          sort: false,
          type: 'string',
          source: 'passenger_name',
        },
        // {
        //   name: this.translate.instant('reports.trip-details.location'),
        //   sort: false,
        //   type: 'string',
        //   source: 'location',
        // },
        {
          name: this.translate.instant('reports.trip-details.approach-time'),
          sort: false,
          type: 'date_time',
          source: 'approach_time',
        },
        {
          name: this.translate.instant('reports.trip-details.notified-parent'),
          sort: false,
          type: 'date_time',
          source: 'notified_parent',
        },
        {
          name: this.translate.instant('reports.trip-details.pickup-time'),
          sort: false,
          type: 'date_time',
          source: 'pickup_time',
        },
        {
          name: this.translate.instant('reports.trip-details.dropoff-time'),
          sort: false,
          type: 'date_time',
          source: 'dropoff_time',
        },
        {
          name: this.translate.instant('reports.trip-details.attendance'),
          sort: false,
          type: 'attendanceStatus',
          source: 'attendance',
        },
      ],
      data: data,
    };
  }

  getTripDetailsData() {
    this.loading = true;
    this.setTableData(this.dataArray);
    setTimeout(() => {
      this.loading = false;
    }, 2000);
    this.reportsService
      .getTripDetailsById(this.tripId, this.searchText)
      .subscribe(
        (result) => {
          let res = result['data'].daily_trip_passengers;
          this.dataArray = res.map((element: any) => {
            return {
              id: element?.id,
              passenger_name: element?.subscriber_name,
              passenger_id: element?.subscription_id,
              passenger_name_link: true,
              location: 'location not found',
              approach_time: element?.approach_time,
              notified_parent: element?.arrived_time,
              pickup_time: element?.pick_up_time,
              dropoff_time: element?.drop_off_time,
              attendance: element?.attendance,
            };
          });
          this.setTableData(this.dataArray);
          this.loading = false;
        },
        (error) => {
          this.errorMsgService.setMessage(error.error.Error[0]);
        }
      );
  }
  convertDateTime(dateTime: string) {
    if (dateTime != null) {
      const date = dateTime.split(' ')[0];
      const time = moment(dateTime)
        .add(localStorage.getItem('timeZoneOffset'), 'h')
        .locale(this.currLang)
        .format('hh:mm A');
      return date + '   ' + time;
    } else {
      return '';
    }
  }
  eventHandeler(event: any) {
    switch (event.action) {
      case 'linkClicked':
        this.linkClicked(event.target);
        break;
      case 'pagination':
        this.paginationHandling(event.target);
        break;
      case 'print':
        this.print();
        break;
      case 'export':
        this.exportEcxel();
        break;
      case 'back':
        this.back();
        break;
    }
  }

  linkClicked(event: any) {
    this.viewStudentHistory(event.row);
  }

  onSeachFn(text: any) {
    this.searchText = text.trim().toLowerCase();
    this.pageNumber = 1;
    this.filter = {
      trips: {
        name: text.trim().toLowerCase(),
        operator: 'or',
      },
    };
    this.router.navigate(['layout/trip-details/' + this.tripId], {
      queryParams: { page: this.pageNumber, search: this.searchText },
      queryParamsHandling: 'merge',
    });
    // this.getTripDetailsData();
  }
  setItemsPerPage($event: any) {
    this.itemsPerPage = $event;
    this.pageNumber = 1;
    this.getTripDetailsData();
  }

  paginationHandling(event: any) {
    this.pageNumber += event.dir;
    this.getTripDetailsData();
  }

  viewStudentHistory(event: any) {
    localStorage.setItem('userNameTripHistory', event.passenger_name);

    this.reportsService.setLastTripDetailsQueryParams(this.routeQueryParams)
    this.router.navigate([`layout/user-history/${event.passenger_id}`]);
  }

  back() {
    this.reportsService.getLastTripsReportQueryParams().subscribe(res => {
      if (res) {

        this.router.navigate([`layout/reports`],
          {
            queryParams: res
          });
      } else {
        this.router.navigate([`layout/reports`]);
      }
    })
  }

  print() {
    this.printService.printPdf('.table-container');
  }

  // exportEcxel() {

  //   let convertedDate: any = moment(new Date()).format('YYYY-MM-DD');
  //   this.loading = true;
  //   this.reportsService
  //     .exportTripDetails(this.tripId, this.searchText)
  //     .subscribe((res) => {
  //       this.loading = false;
  //       this.sub = this.reportsService.getReportDate().subscribe((date) => {
  //         if (date) {
  //           convertedDate = date;
  //         }
  //       });

  //       this.exportExcel.downloadFile(res, `${this.tripName} ${convertedDate}`);
  //     });
  //   // let valuesToBeExported:any = [];
  //   // if(this.currLang == 'en'){
  //   //   valuesToBeExported = this.dataArray.map(data => {
  //   //     return {
  //   //       'Passenger Name': data['passenger_name'],
  //   //       'Location': data['location'],
  //   //       'Approach Time': data['approach_time'],
  //   //       'Notified Parent': data['notified_parent'],
  //   //       'Pick Up Time': data['pickup_time'],
  //   //       'Drop Off Time': data['dropoff_time'],
  //   //       'Attendance': data['attendance']
  //   //     }
  //   //   })
  //   // }
  //   // else if(this.currLang == 'ar'){
  //   //   valuesToBeExported = this.dataArray.map(data => {
  //   //     return {
  //   //       'اسم الراكب': data['passenger_name'],
  //   //       'موقع': data['location'],
  //   //       'وقت الاقتراب	': data['approach_time'],
  //   //       'وقت إعلام الوالد': data['notified_parent'],
  //   //       'وقت المغادرة	': data['pickup_time'],
  //   //       'وقت النزول	': data['dropoff_time'],
  //   //       'الحضور': data['attendance']
  //   //     }
  //   //   })
  //   // }
  //   // this.exportExcel.exportAsExcelFile(valuesToBeExported,'Trip Details')
  // }

  exportEcxel(){
    const params = {daily_trip_id: this.tripId, search: this.searchText};
    this.exportService.exportFile(
      'backoffice/report/exportDailyTripPassengersFilters',
      'backoffice/Exports/getExportById',
      params
    )
  }
  ngOnDestroy() {

    this.reportsService.setReportDate(null);
    this.sub.unsubscribe();
  }

}
