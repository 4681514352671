import { Component, Renderer2 } from '@angular/core';
import { Filter } from '../../../models/filter.model';
import { TableControls } from '../../../models/table-controls.model';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../../../shared/services/global.service';
import { ErrorMsgService } from '../../../shared/services/error-msg.service';
import { map } from 'rxjs';
import { ToastrService } from 'ngx-toastr'
import { CommonModule } from '@angular/common';
import { ControlBarComponent } from '../../../shared/components/control-bar/control-bar.component';
import { TableViewComponent } from '../../../shared/components/table-view/table-view.component';
import { Driver } from '../../../models/driver.model';
import { DriverService } from '../_services/driver.service';
import { MatDialog } from '@angular/material/dialog';
import { AddDriverComponent } from './add-driver/add-driver.component';
import { Trip } from '../../../models/trip.model';
import { ConfirmDeleteComponent } from '../../../shared/components/confirm-delete/confirm-delete.component';
declare var $: any;

@Component({
  selector: 'app-drivers',
  standalone: true,
  imports: [CommonModule, ControlBarComponent, TableViewComponent, AddDriverComponent, ConfirmDeleteComponent],
  templateUrl: './drivers.component.html',
  styleUrl: './drivers.component.scss'
})
export class DriversComponent {
  tableControls: TableControls = { data: [] };

  searchText: string = '';
  pageNumber: number = 1;
  itemsPerPage: number = 25;
  order: { order_type?: string; order_by?: string } = {};
  currLang: string = 'en';
  loading!: boolean;
  filter: Filter = {};
  selectedCheckBoxes: number[] = [];
  from!: number;
  to!: number;
  total!: number;

  dataArray: Driver[] = [];
  actions = [
    {
      type: 'add',
      name: 'drivers.add-new',
    },
    {
      type: 'del',
      name: 'drivers.delete',
    },
  ];
  isAddDriverModalOpen = false;

  constructor(
    public dialog: MatDialog,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,

    private globalService: GlobalService,
    private driverService: DriverService,
    private errorMsgService: ErrorMsgService,
    private toastr: ToastrService,
    private renderer: Renderer2
  ) {
    this.globalService.getLang().subscribe((lng) => {
      this.currLang = lng ? lng : "en";
      this.translate.use(this.currLang);
    });
    this.translate.onLangChange.subscribe((res) => {
      this.currLang = res.lang ? res.lang : "en";
      this.translate.use(this.currLang);
      this.setTableData(this.dataArray);
    });
    this.driverService.getLoadDriversData().subscribe((res) => {
      if (res) {
        this.getDriversData();
      }
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.searchText = params['search'] || '';

      this.filter = {
        drivers: {
          username: this.searchText.trim().toLowerCase(),
          name: this.searchText.trim().toLowerCase(),
          national_id: this.searchText.trim().toLowerCase(),
          phone_no: this.searchText.trim().toLowerCase(),
          operator: 'or',
        },

      }
      if (params['order_by'] && params['order_type']) {
        this.order = {
          order_by: params['order_by'],
          order_type: params['order_type'],
        };
      }
      this.pageNumber = +params['page'] || 1;
      this.itemsPerPage = params['show'] || 25;
      this.getDriversData();
    })
  }
  setTableData(data: any) {
    this.tableControls = {
      selection: true,
      paging: true,
      title: 'table-view.titles.driver',
      selectedTitle: 'table-view.selected-titles.driver',
      totalTitle: 'table-view.total-titles.driver',
      pagination: {
        from: this.from,
        to: this.to,
        total: this.total,
      },
      columns: [
        {
          name: this.translate.instant('drivers.name'),
          sort: true,
          type: 'string',
          source: 'name',
        },
        {
          name: this.translate.instant('drivers.no-assign-trip'),
          name2: 'drivers.trips',
          sort: false,
          type: 'multipleValues',
          source: 'tripsLength',
          source2: 'ways',
        },
        {
          name: this.translate.instant('drivers.national_id'),
          sort: false,
          type: 'string',
          source: 'national_id',
        },
        {
          name: this.translate.instant('drivers.phone_no'),
          sort: false,
          type: 'string',
          source: 'phone_no',
        },
      ],
      data: data,
    };
  }
  tableEventHandeler(event: { action: string; target: any }) {
    switch (event.action) {
      case 'rowClicked':
        this.rowClicked(event.target);
        break;
      case 'checkboxChanged':
        this.selectChanged(event.target);
        break;
      case 'sorting':
        this.sorting(event.target);
        break;

      case 'pagination':
        this.paginationHandling(event.target);
        break;
      case 'del':
        this.showDeleteConfirmation();
        break;
      case 'add':
        this.addDriver();
        break;
    }
  }
  sorting(target: { order: string; source: string }) {

    this.selectChanged({
      index: 'all',
      checked: false,
    });
    this.router.navigate(['layout/management/drivers'], {
      queryParams: { order_type: target.order, order_by: target.source },
      queryParamsHandling: 'merge',
    });
  }

  rowClicked(event: { index: number; data: Driver }) {
    if (this.isAddDriverModalOpen) {
      return; // Prevent opening multiple modals
    }
    this.isAddDriverModalOpen = true; // Set flag to indicate modal is open
    this.driverService.getDriverById(event.data.id!, ['trips']).subscribe((res: any) => {
      this.driverService.setDriverData(res.data.driver, 'show');

      const modalRef = this.modalService.open(AddDriverComponent, { size: 'xl' });

      modalRef.result.finally(() => {
        this.isAddDriverModalOpen = false; // Reset flag when modal is closed
      });
    }, () => {
      this.isAddDriverModalOpen = false; // Reset flag in case of error
    });
  }

  selectChanged(event: {
    index: number | 'all';
    data?: Driver;
    checked: boolean;
  }) {
    let index = event.index;
    let row = event.data;

    let checked = event.checked;
    if (checked) {
      // checked
      if (index == 'all') {
        this.selectedCheckBoxes = this.dataArray.map((element: Driver) => element.id!);
      } else {
        this.selectedCheckBoxes.push(row?.id!);
      }
    } else {
      // unchecked
      if (index == 'all') {
        this.selectedCheckBoxes = [];
      } else {
        let checkboxIndex = this.selectedCheckBoxes.indexOf(row?.id!);

        this.selectedCheckBoxes.splice(checkboxIndex, 1);
      }
    }
  }


  paginationHandling(event: { dir: number }) {
    this.pageNumber += event.dir;

    this.router.navigate(['layout/management/drivers'], {
      queryParams: { page: this.pageNumber },
      queryParamsHandling: 'merge',
    });
  }
  getDriversData() {
    this.loading = true;
    this.driverService
      .getDrivers(this.pageNumber, this.itemsPerPage, ['trips'], this.filter, this.order)
      .pipe(map((res) => res['data'].drivers))

      .subscribe(
        (res) => {
          let dataArray: Driver[] = [];
          dataArray = res['data'].map((element: any) => {
            let ways: string[] = [];

            element.trips.map((el: Trip) => {
              ways.push(el.way!);
            });
            return {
              national_id: element.national_id,
              name: element.name,
              id: element.id,
              phone_no: element.phone_no,
              tripsLength: element.trips.length,
              ways: ways,
            };
          });

          this.dataArray = dataArray;
          this.from = res['from'];
          this.to = res['to'];
          this.total = res['total'];
          this.selectedCheckBoxes = [];
          this.setTableData(dataArray);
          this.loading = false;
        },

        (error) => {
          this.errorMsgService.setMessage(error.error.Error[0]);
        }
      );
  }

  onSeachFn(text: string) {
    this.searchText = text;
    this.pageNumber = 1;

    this.router.navigate(['layout/management/drivers'], {
      queryParams: { page: this.pageNumber, search: this.searchText },
      queryParamsHandling: 'merge',
    });
  }
  setItemsPerPage($event: number) {
    this.itemsPerPage = $event;
    this.pageNumber = 1;

    this.router.navigate(['layout/management/drivers'], {
      queryParams: { show: this.itemsPerPage, page: this.pageNumber },
      queryParamsHandling: 'merge',
    });
  }

  addDriver() {
    this.modalService.open(AddDriverComponent, { size: 'xl' });

  }

  showDeleteConfirmation() {
    const modal = this.renderer.selectRootElement('#confirm-delete-popup', true);
    this.renderer.setStyle(modal, 'display', 'block');
    this.renderer.addClass(modal, 'show');
  }

  hideDeleteConfirmation() {
    const modal = this.renderer.selectRootElement('#confirm-delete-popup', true);
    this.renderer.setStyle(modal, 'display', 'none');
    this.renderer.removeClass(modal, 'show');
  }

  deleteDrivers() {
    let currPage = this.pageNumber;

    if (this.selectedCheckBoxes.length == this.dataArray.length) {
      this.pageNumber--;
      this.pageNumber = this.pageNumber == 0 ? 1 : this.pageNumber;
    }
    this.driverService.deleteDrivers(this.selectedCheckBoxes).subscribe(
      (res) => {
        if (currPage == this.pageNumber) {

          this.getDriversData();
        }
        else {
          this.router.navigate(['layout/management/drivers'], {
            queryParams: { page: this.pageNumber },
            queryParamsHandling: 'merge',
          });
        }
        this.toastr.success(this.deleteToastrMessage(), this.deleteToastrTitle(), {
          positionClass: 'toast-top-center'
        });
      },
      (error) => {
        this.hideDeleteConfirmation();
        this.errorMsgService.setMessage(error.error.Error[0]);
      }, () => {
        this.hideDeleteConfirmation();
      }
    );
  }

  deleteToastrMessage(): string {
    return this.currLang == 'en' ? 'Deleted Successfully' : 'تم الحذف بنجاح';
  }
  deleteToastrTitle(): string {
    return this.currLang == 'en' ? 'Drivers Deletion' : 'حذف السائقين';
  }

}
