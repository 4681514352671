<div class="snackBar-content">
    <svg xmlns="http://www.w3.org/2000/svg" id="error_1_" data-name="error (1)" width="38.753" height="33.754"
        viewBox="0 0 38.753 33.754">
        <g id="Group_5394" data-name="Group 5394" transform="translate(0 0)">
            <g id="Group_5393" data-name="Group 5393">
                <path id="Path_5573" data-name="Path 5573"
                    d="M38.312,58.191A1.514,1.514,0,1,0,35.5,59.313a3.236,3.236,0,0,1-.346,3.015,3.193,3.193,0,0,1-2.683,1.418H6.287a3.251,3.251,0,0,1-2.762-4.961l13.092-21.2a3.246,3.246,0,0,1,5.525,0l9.683,15.677A1.514,1.514,0,0,0,34.4,51.675L24.717,36a6.273,6.273,0,0,0-10.676,0L.949,57.195a6.279,6.279,0,0,0,5.338,9.579H32.471a6.178,6.178,0,0,0,5.189-2.748A6.26,6.26,0,0,0,38.312,58.191Z"
                    transform="translate(0 -33.02)" fill="#f46363" />
                <path id="Path_5574" data-name="Path 5574"
                    d="M237.56,363.018a1.514,1.514,0,0,0,0-3.027h0a1.514,1.514,0,0,0,0,3.027Z"
                    transform="translate(-218.18 -335.243)" fill="#f46363" />
                <path id="Path_5575" data-name="Path 5575"
                    d="M239.069,155.649V144.524a1.514,1.514,0,0,0-3.027,0v11.125a1.514,1.514,0,1,0,3.027,0Z"
                    transform="translate(-218.176 -134.685)" fill="#f46363" />
            </g>
        </g>
    </svg>
    <span>{{ message }}</span>
</div>