import { Component, Input, OnInit, SimpleChange } from '@angular/core';
import { ArabicNumberPipe } from '../../pipes/arabic-number.pipe';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-half-circle-progress-bar',
  standalone: true,
  imports: [ArabicNumberPipe, CommonModule, TranslateModule, FormsModule],
  templateUrl: './half-circle-progress-bar.component.html',
  styleUrl: './half-circle-progress-bar.component.scss'
})
export class HalfCircleProgressBarComponent {
  // testGuage = 0;
  // testGuageOptions = [0,0.5,0.1,0.2,0.4,0.9,1,1.5,2,3,4,5,5.5,6,7,7.5,8,9,10,11,12,13,14,15,15.5,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,30.5,50,70,80,90,95,100 , 200 , 400 , 500 , 900 ,]
  @Input() percentage: number = 0; // Input percentage (0-100)
  @Input() guageIndex: any; // Input percentage (0-100)
  performanceGuageValue: any;
  currLang: string = 'en';
  timingRank: any;
  timingRankClass: any;
  guageInnerTime: any;

  constructor(
    private globalService: GlobalService,
  ) {
    this.globalService.getLang().subscribe(lng => {
      this.currLang = lng;
    });
  }
  ngOnInit() {
    this.globalService.getLang().subscribe(lng => {
      this.currLang = lng;
    });
  }

  ngOnChanges(changes:any){
    // console.log('changed' , changes );
    setTimeout(() => {
      this.drawGuage(Number(changes.percentage.currentValue), this.guageIndex);
    }, 100);    
  }

  ngAfterViewInit(): void {
  }


  drawGuage(indicatorValue: any, index: any) {
    // console.log(indicatorValue , index);
    let percentage = indicatorValue * 100;
    let indicatorColor = '#98A2B3';
    // let guageInicatorValue = indicatorValue;
    this.guageInnerTime = indicatorValue;
    if (percentage < 0) {
      // percentage = guageInicatorValue = 0;
      percentage = this.guageInnerTime = 0
    }
    if (percentage > 1000) {
      percentage = 1000;
    }
    // Get the canvas element and its 2d context
    const canvas: any = document.getElementById(index);
    const ctx = canvas.getContext("2d");
    // to clear the canvas
    // Define the gauge properties
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    const radius = 100;
    const startAngle = Math.PI;
    const endAngle = 2.02 * Math.PI;
    const numRanges = 1;
    // const rangeColors = ["#F46363", "#F5D21E", "#ECA336", "#46CEA2"];
    const rangeColors = ["#F46363", "#F1F6FA", "#F46363", "#F46363"];
    const rangeMaxValue = 1000;
    const rangeWidth = (endAngle - startAngle) / numRanges; // Calculate the range width
    const rangeThickness = 0.2; // Adjust the thickness of the ranges (in radians)
    const rangeSpacing = 0; // Adjusted range spacing
    const borderRadius = 0.02;
    // Define the indicator properties
    const indicatorRadius = 14 // Adjust the indicator circle's radius
    const indicatorDistance = -5;
    // let percentage = 90; // Adjust this value to set the indicator position

    // Draw the full gauge background with neutral color
    ctx.beginPath();
    ctx.arc(centerX, centerY, radius, startAngle, endAngle);
    ctx.strokeStyle = '#F1F6FA'; // Neutral background color
    ctx.lineWidth = rangeThickness * radius;
    ctx.stroke();

    // Draw the filled portion of the gauge based on percentage
    const filledAngle = startAngle + (percentage / rangeMaxValue) * (endAngle - startAngle);
    ctx.beginPath();
    ctx.arc(centerX, centerY, radius, startAngle, filledAngle);
    ctx.strokeStyle = '#F46363'; // Color of the filled portion
    ctx.lineWidth = rangeThickness * radius;
    ctx.stroke();

    // Draw the gauge ranges with thickness
    // for (let i = 0; i < numRanges; i++) {
    //   const start = startAngle + i * rangeWidth;
    //   const end = start + rangeWidth - rangeSpacing;
    //   ctx.beginPath();
    //   ctx.arc(centerX, centerY, radius, start, end);
    //   ctx.strokeStyle = rangeColors[i];
    //   ctx.lineWidth = rangeThickness * radius;
    //   ctx.stroke();
    // }

    // Function to draw the indicator
    const normalizedValue = percentage / rangeMaxValue;
    const indicatorAngle = startAngle + normalizedValue * (endAngle - startAngle);
    const x = centerX + (Math.cos(indicatorAngle) * (radius - 0.06 * radius - indicatorDistance));
    const y = centerY + (Math.sin(indicatorAngle) * (radius - 0.06 * radius - indicatorDistance));
    ctx.beginPath();
    ctx.arc(x, y, indicatorRadius, 0, 2 * Math.PI);
    ctx.fillStyle = indicatorColor;
    ctx.fill();

    // Function to draw the inner indicator
    ctx.beginPath();
    ctx.arc(x, y, indicatorRadius / 2, 0, 2 * Math.PI);
    ctx.fillStyle = '#FFF';
    ctx.fill();
  }

  // randomGuage(event:any , index:any){
  //   console.log(event.target.value);
  //   this.drawGuage(event.target.value , index);
  // }
}
