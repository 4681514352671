<div class="login-section" [class.rtl]="currLang == 'ar'">
    <div class="login-section-text">
        <label style="padding-bottom: 0.5rem"><span class="bold">{{ "login.head1" | translate }}</span>
            <span class="light">{{ "login.head2" | translate }}</span></label>
        <label style="padding-bottom: 0.5rem" class="text">{{
            "login.description" | translate
            }}</label>
        <div class="login-section-text-list">
            <ul>
                <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="42.505" height="44.183"
                    viewBox="0 0 42.505 44.183">
                    <g id="Capa_1" data-name="Capa 1">
                        <path id="Path_5517" data-name="Path 5517"
                            d="M69.34,347.9H66.732a.472.472,0,0,0-.472.472v3.911a.472.472,0,0,0,.472.472H69.34a.472.472,0,0,0,.472-.472v-3.911A.472.472,0,0,0,69.34,347.9Zm-.472,3.911H67.2v-2.967h1.664Z"
                            transform="translate(-61.046 -320.523)" fill="#fff" />
                        <path id="Path_5518" data-name="Path 5518"
                            d="M69.34,268.65H66.732a.472.472,0,0,0-.472.472v3.911a.472.472,0,0,0,.472.472H69.34a.472.472,0,0,0,.472-.472v-3.911A.472.472,0,0,0,69.34,268.65Zm-.472,3.911H67.2v-2.967h1.664Z"
                            transform="translate(-61.046 -247.509)" fill="#fff" />
                        <path id="Path_5519" data-name="Path 5519"
                            d="M187.074,199.3a4.344,4.344,0,1,0,4.344,4.344A4.344,4.344,0,0,0,187.074,199.3Zm0,7.822a3.478,3.478,0,1,1,3.478-3.478,3.478,3.478,0,0,1-3.478,3.478Z"
                            transform="translate(-168.351 -183.617)" fill="#fff" />
                        <path id="Path_5520" data-name="Path 5520"
                            d="M232.627,231.605h-.91v-1.562a.393.393,0,0,0-.787,0V232a.393.393,0,0,0,.393.393h1.3a.393.393,0,0,0,0-.787Z"
                            transform="translate(-212.758 -211.578)" fill="#fff" />
                        <path id="Path_5521" data-name="Path 5521"
                            d="M90.022,497a.472.472,0,1,0,0,.944H108.7c-.184-.308-.358-.624-.518-.944Z"
                            transform="translate(-82.503 -457.89)" fill="#fff" />
                        <path id="Path_5522" data-name="Path 5522"
                            d="M36.107,17.6a.472.472,0,0,0-.1-.514l-6.518-6.519a.472.472,0,0,0-.334-.138H21.94L19.2,8.077V6.159h6.046a.472.472,0,0,0,.393-.734L24.505,3.732l1.129-1.693a.472.472,0,0,0-.393-.734H19.2V.472a.472.472,0,0,0-.944,0v7.6l-2.745,2.352H8.294a.472.472,0,0,0-.334.138L1.442,17.086a.472.472,0,0,0,.334.806h.832V36.5H1.776a.472.472,0,0,0-.472.472V39.11H.472a.472.472,0,1,0,0,.944H5.158a.472.472,0,1,0,0-.944H2.247V37.447H24.981c-.107-.309-.2-.63-.288-.944h-.935V28.319h.18a.472.472,0,0,0,.29-.1v-.744a.472.472,0,0,0-.29-.1H13.508a.472.472,0,0,0,0,.944h.184V36.5H11.373V17.823l7.35-6.3,7.35,6.3V24.3a2.256,2.256,0,0,0,.944-1.468v-4.2l.524.449a.48.48,0,0,0,.505.071.472.472,0,0,0,.275-.429v-.832h5.574v3.972h.944V17.892h.832a.472.472,0,0,0,.436-.292ZM19.2,2.248h5.164L23.545,3.47a.472.472,0,0,0,0,.523l.814,1.222H19.2Zm0,26.073h3.62v8.184H19.2Zm-4.564,0h3.62v8.184H14.637ZM8.489,11.373H14.4L9.29,15.758a.472.472,0,0,0-.165.358v.832H2.916ZM10.429,36.5H3.551V17.892H9.128v.831a.472.472,0,0,0,.78.359l.524-.449ZM27.377,17.7,19.03,10.545a.472.472,0,0,0-.614,0L10.073,17.7V16.333l8.656-7.417,8.656,7.417Zm.944-.749v-.83a.472.472,0,0,0-.165-.358l-5.115-4.385h5.918l5.574,5.575Z"
                            fill="#fff" />
                        <path id="Path_5523" data-name="Path 5523"
                            d="M341.912,302.114A2.015,2.015,0,0,1,339.9,300.1a.4.4,0,0,0-.4-.4H328.236a.4.4,0,0,0-.4.4,2.014,2.014,0,0,1-2.011,2.012.4.4,0,0,0-.4.4v6.565a11.686,11.686,0,0,0,8.333,11.2.394.394,0,0,0,.231,0,11.687,11.687,0,0,0,8.333-11.2v-6.565a.4.4,0,0,0-.405-.4Zm-.4,6.967a10.819,10.819,0,0,1-7.641,10.394,10.88,10.88,0,0,1-7.644-10.394V302.89a2.823,2.823,0,0,0,2.389-2.385h10.514a2.823,2.823,0,0,0,2.385,2.385Z"
                            transform="translate(-299.812 -276.116)" fill="#fff" />
                        <path id="Path_5524" data-name="Path 5524"
                            d="M388.458,396.053l1.805,1.805a.542.542,0,0,0,.767,0l4.514-4.514a.542.542,0,1,0-.767-.766l-4.131,4.13-1.422-1.422a.542.542,0,0,0-.766.767Z"
                            transform="translate(-357.743 -361.539)" fill="#fff" />
                    </g>
                </svg>
                <span>{{ "login.list-item1" | translate }}</span>
            </ul>
            <ul>
                <svg id="appointment_1_" data-name="appointment (1)" xmlns="http://www.w3.org/2000/svg" width="34.708"
                    height="34.708" viewBox="0 0 34.708 34.708">
                    <g id="Group_4618" data-name="Group 4618">
                        <g id="Group_4617" data-name="Group 4617">
                            <path id="Path_5506" data-name="Path 5506"
                                d="M31.319,2.983H28.087V2.034a2.034,2.034,0,0,0-4.067,0v.949H12.631V2.034a2.034,2.034,0,1,0-4.067,0v.974a.676.676,0,0,0-.181-.025h-2.7A3.393,3.393,0,0,0,2.293,6.372V17.919a.678.678,0,1,0,1.356,0V9.965H28.087a.678.678,0,1,0,0-1.356H3.649V6.372A2.036,2.036,0,0,1,5.682,4.339h2.7a.679.679,0,0,0,.181-.025v.872a2.034,2.034,0,1,0,4.067,0V4.339H24.02v.847a2.034,2.034,0,0,0,4.067,0V4.339h3.231a2.036,2.036,0,0,1,2.034,2.034V32.132a1.221,1.221,0,0,1-2.441,0V30.378a.678.678,0,0,0-.678-.678H22.348a.678.678,0,0,0,0,1.356h7.207v1.076a2.561,2.561,0,0,0,.308,1.221H2.576a1.222,1.222,0,0,1-1.22-1.22V31.056H16.529a.678.678,0,1,0,0-1.356H9.755a5.559,5.559,0,1,0-7.244,0H.678A.678.678,0,0,0,0,30.378v1.754a2.579,2.579,0,0,0,2.576,2.576H32.132a2.579,2.579,0,0,0,2.576-2.576V6.372A3.393,3.393,0,0,0,31.319,2.983Zm-20.043,2.2a.678.678,0,1,1-1.356,0V2.034a.678.678,0,0,1,1.356,0Zm15.456,0a.678.678,0,1,1-1.356,0V2.034a.678.678,0,1,1,1.356,0Zm-24.8,20.3a4.2,4.2,0,1,1,4.2,4.2A4.208,4.208,0,0,1,1.931,25.489Z"
                                fill="#fff" />
                        </g>
                    </g>
                    <g id="Group_4620" data-name="Group 4620" transform="translate(30.589 8.609)">
                        <g id="Group_4619" data-name="Group 4619">
                            <path id="Path_5507" data-name="Path 5507"
                                d="M452.4,127.2a.677.677,0,1,0,.2.479A.683.683,0,0,0,452.4,127.2Z"
                                transform="translate(-451.24 -127)" fill="#fff" />
                        </g>
                    </g>
                    <g id="Group_4622" data-name="Group 4622" transform="translate(19.088 29.7)">
                        <g id="Group_4621" data-name="Group 4621">
                            <path id="Path_5508" data-name="Path 5508"
                                d="M282.737,438.328a.678.678,0,1,0,.2.479A.682.682,0,0,0,282.737,438.328Z"
                                transform="translate(-281.58 -438.13)" fill="#fff" />
                        </g>
                    </g>
                    <g id="Group_4624" data-name="Group 4624" transform="translate(12.761 20.608)">
                        <g id="Group_4623" data-name="Group 4623">
                            <path id="Path_5509" data-name="Path 5509"
                                d="M190.821,304a2.576,2.576,0,1,0,2.576,2.576A2.579,2.579,0,0,0,190.821,304Zm0,3.8a1.22,1.22,0,1,1,1.22-1.22A1.222,1.222,0,0,1,190.821,307.8Z"
                                transform="translate(-188.245 -304)" fill="#fff" />
                        </g>
                    </g>
                    <g id="Group_4626" data-name="Group 4626" transform="translate(19.088 20.608)">
                        <g id="Group_4625" data-name="Group 4625">
                            <path id="Path_5510" data-name="Path 5510"
                                d="M284.154,304a2.576,2.576,0,1,0,2.576,2.576A2.579,2.579,0,0,0,284.154,304Zm0,3.8a1.22,1.22,0,1,1,1.22-1.22A1.222,1.222,0,0,1,284.154,307.8Z"
                                transform="translate(-281.578 -304)" fill="#fff" />
                        </g>
                    </g>
                    <g id="Group_4628" data-name="Group 4628" transform="translate(25.415 20.608)">
                        <g id="Group_4627" data-name="Group 4627">
                            <path id="Path_5511" data-name="Path 5511"
                                d="M377.488,304a2.576,2.576,0,1,0,2.576,2.576A2.579,2.579,0,0,0,377.488,304Zm0,3.8a1.22,1.22,0,1,1,1.22-1.22A1.222,1.222,0,0,1,377.488,307.8Z"
                                transform="translate(-374.912 -304)" fill="#fff" />
                        </g>
                    </g>
                    <g id="Group_4630" data-name="Group 4630" transform="translate(6.434 13.558)">
                        <g id="Group_4629" data-name="Group 4629">
                            <path id="Path_5512" data-name="Path 5512"
                                d="M97.488,200a2.576,2.576,0,1,0,2.576,2.576A2.579,2.579,0,0,0,97.488,200Zm0,3.8a1.22,1.22,0,1,1,1.22-1.22A1.222,1.222,0,0,1,97.488,203.8Z"
                                transform="translate(-94.912 -200)" fill="#fff" />
                        </g>
                    </g>
                    <g id="Group_4632" data-name="Group 4632" transform="translate(19.088 13.558)">
                        <g id="Group_4631" data-name="Group 4631">
                            <path id="Path_5513" data-name="Path 5513"
                                d="M284.154,200a2.576,2.576,0,1,0,2.576,2.576A2.579,2.579,0,0,0,284.154,200Zm0,3.8a1.22,1.22,0,1,1,1.22-1.22A1.222,1.222,0,0,1,284.154,203.8Z"
                                transform="translate(-281.578 -200)" fill="#fff" />
                        </g>
                    </g>
                    <g id="Group_4634" data-name="Group 4634" transform="translate(25.415 13.558)">
                        <g id="Group_4633" data-name="Group 4633">
                            <path id="Path_5514" data-name="Path 5514"
                                d="M377.488,200a2.576,2.576,0,1,0,2.576,2.576A2.579,2.579,0,0,0,377.488,200Zm0,3.8a1.22,1.22,0,1,1,1.22-1.22A1.222,1.222,0,0,1,377.488,203.8Z"
                                transform="translate(-374.912 -200)" fill="#fff" />
                        </g>
                    </g>
                    <g id="Group_4636" data-name="Group 4636" transform="translate(12.761 13.558)">
                        <g id="Group_4635" data-name="Group 4635">
                            <path id="Path_5515" data-name="Path 5515"
                                d="M190.821,200a2.576,2.576,0,1,0,2.576,2.576A2.579,2.579,0,0,0,190.821,200Zm0,3.8a1.22,1.22,0,1,1,1.22-1.22A1.222,1.222,0,0,1,190.821,203.8Z"
                                transform="translate(-188.245 -200)" fill="#fff" />
                        </g>
                    </g>
                    <g id="Group_4638" data-name="Group 4638" transform="translate(3.47 23.638)">
                        <g id="Group_4637" data-name="Group 4637">
                            <path id="Path_5516" data-name="Path 5516"
                                d="M56.272,348.9a.678.678,0,0,0-.959,0l-2.208,2.208-.754-.754a.678.678,0,0,0-.959.959l1.234,1.234a.678.678,0,0,0,.959,0l2.687-2.687A.678.678,0,0,0,56.272,348.9Z"
                                transform="translate(-51.194 -348.7)" fill="#fff" />
                        </g>
                    </g>
                </svg>
                <span>{{ "login.list-item2" | translate }}</span>
            </ul>
            <ul>
                <svg xmlns="http://www.w3.org/2000/svg" width="40.811" height="38.461" viewBox="0 0 40.811 38.461">
                    <g id="bus_2_" data-name="bus (2)" transform="translate(0 0)">
                        <path id="Path_5502" data-name="Path 5502"
                            d="M39.571,7.2h-.754a2.2,2.2,0,0,0-2.2-2.156H34.8V2.2A2.2,2.2,0,0,0,32.6,0H8.209a2.2,2.2,0,0,0-2.2,2.2V5.045H4.189A2.2,2.2,0,0,0,1.994,7.2H1.24A1.241,1.241,0,0,0,0,8.44v6.385a1.241,1.241,0,0,0,1.24,1.24h2.7a1.241,1.241,0,0,0,1.24-1.24V8.44A1.241,1.241,0,0,0,3.943,7.2H3.193a1,1,0,0,1,1-.958H6.013V28.465a.6.6,0,0,0,1.2,0v-4.94a2.179,2.179,0,0,0,1,.241H32.6a2.179,2.179,0,0,0,1-.241V33.2a1,1,0,0,1-1,1H8.209a1,1,0,0,1-1-1V31.233a.6.6,0,0,0-1.2,0V33.2a2.2,2.2,0,0,0,2.2,2.2h.623v1.392a1.679,1.679,0,0,0,1.677,1.677H13.2a1.679,1.679,0,0,0,1.677-1.677V35.391H25.932v1.392a1.679,1.679,0,0,0,1.677,1.677H30.3a1.679,1.679,0,0,0,1.677-1.677V35.391H32.6a2.2,2.2,0,0,0,2.2-2.2V6.242h1.824a1,1,0,0,1,1,.958h-.751a1.241,1.241,0,0,0-1.24,1.24v6.385a1.241,1.241,0,0,0,1.24,1.24h2.7a1.241,1.241,0,0,0,1.24-1.24V8.44a1.241,1.241,0,0,0-1.24-1.24ZM3.943,8.4a.041.041,0,0,1,.041.042v6.385a.042.042,0,0,1-.041.042H1.24a.042.042,0,0,1-.042-.042V8.44A.042.042,0,0,1,1.24,8.4Zm9.738,28.385a.48.48,0,0,1-.479.479H10.51a.48.48,0,0,1-.479-.479V35.391h3.651v1.392Zm17.1,0a.48.48,0,0,1-.479.479H27.609a.48.48,0,0,1-.479-.479V35.391h3.651ZM33.6,7.154H9.411a.6.6,0,0,0,0,1.2H33.6V19.7H29.643a.6.6,0,1,0,0,1.2H33.6v.673a1,1,0,0,1-1,1H8.209a1,1,0,0,1-1-1V20.9H26.848a.6.6,0,1,0,0-1.2H20.957l5.651-4.724a.6.6,0,0,0-.767-.917L19.093,19.7H16.52l5.651-4.724a.6.6,0,1,0-.767-.917L14.656,19.7H7.211V5.237H33.6Zm0-3.115H7.211V2.2a1,1,0,0,1,1-1H32.6a1,1,0,0,1,1,1Zm6.013,10.786a.042.042,0,0,1-.042.042h-2.7a.042.042,0,0,1-.042-.042V8.44a.042.042,0,0,1,.042-.042h2.7a.042.042,0,0,1,.042.042Zm0,0"
                            transform="translate(0 0)" fill="#fff" />
                        <path id="Path_5503" data-name="Path 5503"
                            d="M115.994,327.523a1,1,0,0,0,1-1v-.958a2.358,2.358,0,0,0-2.356-2.356h-3.674a1,1,0,0,0-1,1v.958a2.359,2.359,0,0,0,2.356,2.356Zm-4.832-2.356v-.759h3.474a1.159,1.159,0,0,1,1.158,1.158v.759h-3.474A1.16,1.16,0,0,1,111.162,325.167Zm0,0"
                            transform="translate(-101.199 -297.448)" fill="#fff" />
                        <path id="Path_5504" data-name="Path 5504"
                            d="M318.536,327.523a2.359,2.359,0,0,0,2.356-2.356v-.958a1,1,0,0,0-1-1H316.22a2.359,2.359,0,0,0-2.356,2.356v.958a1,1,0,0,0,1,1Zm-3.474-1.957a1.159,1.159,0,0,1,1.158-1.158h3.474v.759a1.159,1.159,0,0,1-1.158,1.158h-3.474Zm0,0"
                            transform="translate(-288.846 -297.448)" fill="#fff" />
                        <path id="Path_5505" data-name="Path 5505"
                            d="M219.259,381.767a.768.768,0,0,0,.767-.767v-1.58a.768.768,0,0,0-.767-.767H213.97a.768.768,0,0,0-.767.767V381a.768.768,0,0,0,.767.767Zm-4.858-1.917h4.427v.719H214.4Zm0,0"
                            transform="translate(-196.209 -348.471)" fill="#fff" />
                    </g>
                </svg>
                <span>{{ "login.list-item3" | translate }}</span>
            </ul>
        </div>
    </div>
    <div class="login-section-inputs">
        <div class="login-section-inputs-logo">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" width="68.976" height="68.976" viewBox="0 0 68.976 68.976">
                <defs>
                    <linearGradient id="linear-gradient" x1="2.096" y1="6.184" x2="2.096" y2="6.182"
                        gradientUnits="objectBoundingBox">
                        <stop offset="0" stop-color="#1a7fe1" />
                        <stop offset="1" stop-color="#215ec1" />
                    </linearGradient>
                    <linearGradient id="linear-gradient-2" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                        <stop offset="0" stop-color="#50bcfa" />
                        <stop offset="0.42" stop-color="#52cafc" />
                        <stop offset="0.56" stop-color="#54defe" />
                        <stop offset="1" stop-color="#55e8ff" />
                    </linearGradient>
                </defs>
                <g id="Group_478" data-name="Group 478">
                    <g id="BG">
                        <path id="Intersection_1" data-name="Intersection 1" d="M49.082,68.976H0V0H68.976V52.459"
                            fill="url(#linear-gradient)" />
                    </g>
                </g>
                <path id="Path_5492" data-name="Path 5492"
                    d="M235.7,244.29a.742.742,0,0,0,.493.164h3.993a4.037,4.037,0,0,0,1.95-.462,3.428,3.428,0,0,0,1.339-1.276,3.6,3.6,0,0,0,.485-1.88,2.978,2.978,0,0,0-.642-1.965,3.219,3.219,0,0,0-1.691-1.058,2.925,2.925,0,0,0,1.19-1,2.578,2.578,0,0,0,.454-1.5,2.873,2.873,0,0,0-.445-1.6,3.162,3.162,0,0,0-1.206-1.089,3.6,3.6,0,0,0-1.7-.4h-3.806a.59.59,0,0,0-.611.611v11.01A.552.552,0,0,0,235.7,244.29Zm1.026-10.892h3.194a2.42,2.42,0,0,1,1.552.485,1.717,1.717,0,0,1,.611,1.431,1.861,1.861,0,0,1-.611,1.485,2.294,2.294,0,0,1-1.552.532h-3.2Zm0,5.105h3.461a2.939,2.939,0,0,1,1.331.29,2.219,2.219,0,0,1,.908.814,2.268,2.268,0,0,1,.329,1.23,2.48,2.48,0,0,1-.329,1.3,2.219,2.219,0,0,1-.908.846,2.872,2.872,0,0,1-1.331.3h-3.461Z"
                    transform="translate(-219.609 -216.551)" fill="#fff" />
                <path id="Path_5493" data-name="Path 5493"
                    d="M390.852,243.84a1.908,1.908,0,0,0,1.12.344h.2a.767.767,0,0,0,.51-.166.54.54,0,0,0,.2-.431.642.642,0,0,0-.141-.431.473.473,0,0,0-.376-.164h-.391a.81.81,0,0,1-.7-.423,1.985,1.985,0,0,1-.274-1.1v-8.925a.6.6,0,1,0-1.19,0v8.927a3.407,3.407,0,0,0,.274,1.409A2.253,2.253,0,0,0,390.852,243.84Z"
                    transform="translate(-363.501 -216.28)" fill="#fff" />
                <path id="Path_5494" data-name="Path 5494"
                    d="M459.483,294.716a4.205,4.205,0,0,0,1.989.462,3.837,3.837,0,0,0,1.848-.446,3.53,3.53,0,0,0,1-.794v.536a.608.608,0,0,0,.165.447.584.584,0,0,0,.431.164.607.607,0,0,0,.439-.164.589.589,0,0,0,.172-.447v-7.36a.568.568,0,0,0-.172-.431.607.607,0,0,0-.439-.165.565.565,0,0,0-.6.6v4.633a2.009,2.009,0,0,1-.36,1.174,2.47,2.47,0,0,1-.979.815,3.384,3.384,0,0,1-2.817-.013,2.348,2.348,0,0,1-1-.939,3.127,3.127,0,0,1-.368-1.6v-4.072a.577.577,0,0,0-.172-.423.6.6,0,0,0-.439-.172.589.589,0,0,0-.6.6v4.072a4.337,4.337,0,0,0,.51,2.177A3.387,3.387,0,0,0,459.483,294.716Z"
                    transform="translate(-426.706 -267.18)" fill="#fff" />
                <path id="Path_5495" data-name="Path 5495"
                    d="M612.323,293.575a4.56,4.56,0,0,0,3.782.266,4.26,4.26,0,0,0,1.315-.728.454.454,0,0,0,.18-.376.582.582,0,0,0-.212-.4.547.547,0,0,0-.375-.117.642.642,0,0,0-.392.148,2.748,2.748,0,0,1-.916.493,3.475,3.475,0,0,1-1.135.2,3.221,3.221,0,0,1-1.66-.422,3.056,3.056,0,0,1-1.135-1.16,3.242,3.242,0,0,1-.4-1.3h6.327a.568.568,0,0,0,.4-.149.526.526,0,0,0,.157-.4,4.739,4.739,0,0,0-.485-2.177,3.648,3.648,0,0,0-1.371-1.5,3.9,3.9,0,0,0-2.075-.54,4,4,0,0,0-2.129.564,3.961,3.961,0,0,0-1.452,1.539,4.721,4.721,0,0,0-.524,2.263,4.515,4.515,0,0,0,.556,2.256A4,4,0,0,0,612.323,293.575Zm.446-6.664a2.882,2.882,0,0,1,1.566-.423,2.75,2.75,0,0,1,1.512.4,2.681,2.681,0,0,1,.979,1.127,3.389,3.389,0,0,1,.309,1.143H611.4a3.511,3.511,0,0,1,.326-1.088A2.823,2.823,0,0,1,612.769,286.911Z"
                    transform="translate(-569.045 -266.147)" fill="#fff" />
                <path id="Path_5496" data-name="Path 5496"
                    d="M236.115,491.3a.59.59,0,0,0,.611-.611v-4.181h2.321c.091,0,.182,0,.27-.007l2.814,4.486a.717.717,0,0,0,.211.235.572.572,0,0,0,.321.078.586.586,0,0,0,.572-.281.64.64,0,0,0-.086-.658l-2.569-4.1a3.772,3.772,0,0,0,.5-.219,3.5,3.5,0,0,0,1.908-3.2,3.763,3.763,0,0,0-.509-1.966,3.549,3.549,0,0,0-1.4-1.331,4.244,4.244,0,0,0-2.036-.478h-2.928a.59.59,0,0,0-.611.611v11.011a.589.589,0,0,0,.611.611Zm.611-11.056h2.321a2.971,2.971,0,0,1,1.417.329,2.508,2.508,0,0,1,.979.916,2.541,2.541,0,0,1,.357,1.354,2.332,2.332,0,0,1-.36,1.282,2.508,2.508,0,0,1-.975.888,3.033,3.033,0,0,1-1.417.321h-2.321Z"
                    transform="translate(-219.609 -446.732)" fill="#fff" />
                <path id="Path_5497" data-name="Path 5497"
                    d="M377.424,485.676a.855.855,0,0,0,.861-.861.78.78,0,0,0-.258-.619.865.865,0,0,0-.587-.227.885.885,0,0,0-.6.227.782.782,0,0,0-.258.619.832.832,0,0,0,.25.611A.815.815,0,0,0,377.424,485.676Z"
                    transform="translate(-351.161 -451.305)" fill="#fff" />
                <path id="Path_5498" data-name="Path 5498"
                    d="M380.44,533.543a.6.6,0,0,0-.165.446v7.345a.568.568,0,0,0,.611.611.583.583,0,0,0,.431-.164.6.6,0,0,0,.165-.447v-7.345a.6.6,0,0,0-.165-.446.581.581,0,0,0-.431-.165A.6.6,0,0,0,380.44,533.543Z"
                    transform="translate(-354.608 -497.379)" fill="#fff" />
                <path id="Path_5499" data-name="Path 5499"
                    d="M439.07,490.828a4.594,4.594,0,0,0,4.424.008,4.184,4.184,0,0,0,1.552-1.543,4.514,4.514,0,0,0,.6-2.2V479.7a.588.588,0,0,0-.172-.446.667.667,0,0,0-.877,0,.588.588,0,0,0-.172.446v4.6a3.806,3.806,0,0,0-1.363-1.182,4,4,0,0,0-1.9-.446,4.114,4.114,0,0,0-3.672,2.13,4.446,4.446,0,0,0-.556,2.227,4.382,4.382,0,0,0,.572,2.239A4.2,4.2,0,0,0,439.07,490.828Zm-.533-5.467a3.225,3.225,0,0,1,4.377-1.167,3.071,3.071,0,0,1,1.127,1.167,3.577,3.577,0,0,1,0,3.344,3.149,3.149,0,0,1-1.127,1.167,3.252,3.252,0,0,1-3.234,0,3.187,3.187,0,0,1-1.143-1.167A3.513,3.513,0,0,1,438.537,485.361Z"
                    transform="translate(-407.452 -446.756)" fill="#fff" />
                <path id="Path_5500" data-name="Path 5500"
                    d="M603.092,540.407a4.554,4.554,0,0,0,3.78.266,4.252,4.252,0,0,0,1.315-.728.45.45,0,0,0,.18-.376.58.58,0,0,0-.211-.405.548.548,0,0,0-.376-.117.64.64,0,0,0-.391.149,2.75,2.75,0,0,1-.916.493,3.481,3.481,0,0,1-1.136.2,3.223,3.223,0,0,1-1.66-.423,3.064,3.064,0,0,1-1.136-1.163,3.249,3.249,0,0,1-.393-1.3h6.323a.566.566,0,0,0,.405-.148.525.525,0,0,0,.156-.4,4.742,4.742,0,0,0-.485-2.177,3.644,3.644,0,0,0-1.37-1.5,3.9,3.9,0,0,0-2.075-.54,4.007,4.007,0,0,0-2.13.564,3.966,3.966,0,0,0-1.448,1.543,4.728,4.728,0,0,0-.523,2.264,4.516,4.516,0,0,0,.556,2.256,4,4,0,0,0,1.536,1.541Zm.447-6.664a2.879,2.879,0,0,1,1.566-.423,2.747,2.747,0,0,1,1.511.4,2.683,2.683,0,0,1,.976,1.131A3.407,3.407,0,0,1,607.9,536h-5.737a3.545,3.545,0,0,1,.326-1.088,2.826,2.826,0,0,1,1.045-1.168Z"
                    transform="translate(-560.438 -496.319)" fill="#fff" />
                <path id="Path_5501" data-name="Path 5501" d="M215.32,746.37v4.279h33.931l6.059-4.279Z"
                    transform="translate(-200.788 -695.997)" fill="url(#linear-gradient-2)" />
            </svg>
        </div>
        <div class="login-section-inputs-body">
            <form [formGroup]="loginForm" (keydown)="keyDownFunc($event)">
                <mat-form-field appearance="outline" dir="ltr">
                    <mat-label>{{ "login.email" | translate }}</mat-label>
                    <input matInput formControlName="email" />
                    <mat-error>{{ getValidationMessage("email") }}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" dir="ltr">
                    <mat-label>{{ "login.password" | translate }}</mat-label>
                    <input matInput [type]="passwordType" formControlName="password" />
                    <mat-error>{{ getValidationMessage("password") }}</mat-error>
                    <div *ngIf="passwordType === 'text'">
                        <svg class="eye-icon" (click)="togglePassword()" xmlns="http://www.w3.org/2000/svg"
                            width="17.529" height="13.137" viewBox="0 0 17.529 13.137">
                            <g id="hide_2_" data-name="hide (2)" transform="translate(0.004 -64.008)">
                                <g id="Group_5396" data-name="Group 5396" transform="translate(6.591 68.408)">
                                    <g id="Group_5395" data-name="Group 5395">
                                        <path id="Path_5576" data-name="Path 5576"
                                            d="M196.787,192.649a.365.365,0,1,0-.516.516,2.192,2.192,0,0,1-3.1,3.1.365.365,0,1,0-.516.516,2.921,2.921,0,1,0,4.13-4.13Z"
                                            transform="translate(-192.55 -192.542)" fill="#707070" />
                                    </g>
                                </g>
                                <g id="Group_5398" data-name="Group 5398" transform="translate(5.842 67.659)">
                                    <g id="Group_5397" data-name="Group 5397">
                                        <path id="Path_5577" data-name="Path 5577"
                                            d="M174.1,170.709a2.892,2.892,0,0,0-3.43,2.871,2.857,2.857,0,0,0,.051.509.366.366,0,0,0,.359.3.323.323,0,0,0,.064-.006.365.365,0,0,0,.3-.423,2.117,2.117,0,0,1-.039-.382,2.164,2.164,0,0,1,2.573-2.152.365.365,0,0,0,.127-.719Z"
                                            transform="translate(-170.668 -170.659)" fill="#707070" />
                                    </g>
                                </g>
                                <g id="Group_5400" data-name="Group 5400" transform="translate(4.567 66.488)">
                                    <g id="Group_5399" data-name="Group 5399">
                                        <path id="Path_5578" data-name="Path 5578"
                                            d="M146.307,140.317a17.738,17.738,0,0,0-4.783-3.815.365.365,0,1,0-.335.649,17.159,17.159,0,0,1,4.349,3.4c-.87.943-4.3,4.382-7.907,4.382a8.419,8.419,0,0,1-3.672-.88.365.365,0,1,0-.317.657,9.131,9.131,0,0,0,3.989.953c4.5,0,8.508-4.677,8.677-4.876a.365.365,0,0,0,0-.47Z"
                                            transform="translate(-133.435 -136.461)" fill="#707070" />
                                    </g>
                                </g>
                                <g id="Group_5402" data-name="Group 5402" transform="translate(0 65.468)">
                                    <g id="Group_5401" data-name="Group 5401">
                                        <path id="Path_5579" data-name="Path 5579"
                                            d="M11.159,107.08a7.54,7.54,0,0,0-2.4-.421c-4.5,0-8.508,4.677-8.677,4.876a.365.365,0,0,0-.02.445,13.556,13.556,0,0,0,3.35,3.176.364.364,0,0,0,.405-.605,13.353,13.353,0,0,1-2.982-2.757c.841-.913,4.289-4.4,7.926-4.4a6.835,6.835,0,0,1,2.163.382.36.36,0,0,0,.462-.229.364.364,0,0,0-.227-.467Z"
                                            transform="translate(0 -106.659)" fill="#707070" />
                                    </g>
                                </g>
                                <g id="Group_5404" data-name="Group 5404" transform="translate(2.19 64.008)">
                                    <g id="Group_5403" data-name="Group 5403">
                                        <path id="Path_5580" data-name="Path 5580"
                                            d="M77.034,64.115a.365.365,0,0,0-.516,0L64.1,76.529a.365.365,0,0,0,.516.516L77.034,64.631a.365.365,0,0,0,0-.516Z"
                                            transform="translate(-63.996 -64.008)" fill="#707070" />
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>


                    <div *ngIf="passwordType === 'password'">
                        <svg class="eye-icon" (click)="togglePassword()" id="view" xmlns="http://www.w3.org/2000/svg"
                            width="17.622" height="10.094" viewBox="0 0 17.622 10.094">
                            <path id="Path_89" data-name="Path 89"
                                d="M15.2,15.3a9.086,9.086,0,0,0-12.836,0L0,17.662l2.422,2.422a9.086,9.086,0,0,0,12.836,0l2.364-2.364Zm-.362,4.365a8.491,8.491,0,0,1-11.994,0l-2-2,1.943-1.943a8.491,8.491,0,0,1,11.994,0l2,2Z"
                                transform="translate(0 -12.644)" fill="#707070" />
                            <path id="Path_90" data-name="Path 90"
                                d="M23.656,21.57a2.086,2.086,0,0,0-2.084,2.084.3.3,0,0,0,.6,0,1.49,1.49,0,0,1,1.488-1.488.3.3,0,1,0,0-.6Z"
                                transform="translate(-15.151 -18.913)" fill="#707070" />
                            <path id="Path_91" data-name="Path 91"
                                d="M20.442,16.57a3.87,3.87,0,1,0,3.87,3.87A3.874,3.874,0,0,0,20.442,16.57Zm0,7.144a3.274,3.274,0,1,1,3.274-3.274A3.278,3.278,0,0,1,20.442,23.714Z"
                                transform="translate(-11.639 -15.401)" fill="#707070" />
                        </svg>
                    </div>
                </mat-form-field>
            </form>
            <div class="login-section-inputs-body-extras">
                <!-- <span><input (change)="toggleRemeber()" type="checkbox"> keep me signed in</span> -->
                <!-- <a href="http://138.68.58.187:555/password/reset" target="_blank">Forget Password</a> -->
                <!-- <a
            class="forget-password"
            (click)="openForgetPassword()"
            target="_blank"
            >{{ "login.forget-password" | translate }}</a
          > -->
            </div>
        </div>
        <div class="login-section-inputs-footer">
            <button [disabled]="loginForm.invalid" (click)="login()" class="btn btn-primary login-btn">
                {{ "login.login-btn" | translate }}
            </button>
            <small>{{ "login.terms1" | translate }}
                <a href="https://www.blueride.co/terms-and-privacy-policy" target="_blank">{{ "login.terms2" | translate
                    }}</a></small>
        </div>
    </div>
    <div class="copy-rights">
        <span>{{ "login.copy-right1" | translate }}</span>
        <span>{{ "login.copy-right2" | translate }}</span>
    </div>
</div>