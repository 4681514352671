import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../services/user.service';
import { StorageService } from '../../../../authentication/services/storage.service';
import { map } from 'rxjs';
import { ErrorMsgService } from '../../../services/error-msg.service';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-user-info',
  standalone: true,
  imports: [TranslateModule , CommonModule , MatProgressSpinnerModule , MatFormFieldModule , ReactiveFormsModule , MatInputModule],
  templateUrl: './user-info.component.html',
  styleUrl: './user-info.component.scss'
})
export class UserInfoComponent {
  @Input() editMode!: boolean;
  @Output() changeAction = new EventEmitter();
  @Output() changeEditMode = new EventEmitter();
  userForm!: UntypedFormGroup;

  userData: any;
  image: any;
  imageFile: any;
  selectedImage: any;
  loading!: boolean;
  constructor(
    private translate: TranslateService,
    private userService: UserService,
    private errorMsgService: ErrorMsgService,
    private storage: StorageService
  ) {}

  ngOnInit() {
    this.userForm = new UntypedFormGroup({
      name: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
    });

    this.getUserData();
  }
  ngOnChanges(changes: SimpleChanges) {
    const { currentValue: isEdit, previousValue } = changes['editMode'];
    if (previousValue != undefined) {
      if (isEdit) {
        this.name.enable();
      } else {
        this.name.disable();
      }
    }
  }
  get name() {
    return this.userForm.controls['name'];
  }
  getUserData() {
    this.loading = true;
    this.userService
      .getUserData()
      .pipe(map((res) => res.data.user))
      .subscribe(
        (res) => {
         
          this.userData = res;
          this.name.setValue(res.name);
          this.loading = false;
        },
        (err) => {
          this.errorMsgService.setMessage(err.error.Error[0]);
        }
      );
  }
  saveInfo() {
    this.userService.updateUserData(this.userForm.value.name).subscribe(
      (res) => {
        this.storage.userData = res.data.user;
        this.userService.setUserDataChanged(true)
        this.changeEditMode.emit(false);

      },
      (err) => {
        this.errorMsgService.setMessage(err.error.Error[0]);
      }
    );
  }
  getValidationMessage(control: string) {
    let errorMsg;
    if (this.userForm.controls[control].errors) {
      const fieldName = this.translate.instant('profile.' + control);
      const requiredText = this.translate.instant('profile.required');
      const invalidText = this.translate.instant('profile.invalid');
      errorMsg = this.userForm.controls[control].errors?.['required']
        ? `${fieldName + requiredText}`
        : `${fieldName + invalidText}`;
    }
    return errorMsg;
  }
  handleChangeAction() {
    this.changeAction.emit('change_password');
  }

}
