import { HttpClient, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService {
  userToken: any = localStorage.getItem('token');
  userData: string = this.storage.userData;
  constructor(private http: HttpClient, private storage: StorageService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.storage.userToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.storage.userToken}`,
        },
      });
    }
    return next.handle(request);
  }
}
