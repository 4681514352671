import { Component, Renderer2 } from '@angular/core';
import { ErrorMsgService } from '../../services/error-msg.service';
import { Subscription, take } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-error-msg',
  standalone: true,
  imports: [],
  templateUrl: './error-msg.component.html',
  styleUrl: './error-msg.component.scss'
})
export class ErrorMsgComponent {
  msg: string | null = "";
  private isResettingMessage: boolean = false; // Flag to prevent recursive call
  private subscription: Subscription = new Subscription;

  constructor(private errorMsgService: ErrorMsgService, private renderer: Renderer2) {
    // this.subscription = this.errorMsgService.getMessage().subscribe((res: string | null) => {
    //   console.log(res);
    //   if (res != null) {
    //     this.msg = res;
    //     this.showModal();
    //   } else {
    //     this.closeModal();
    //   }
    // });
  }
  // Lifecycle hook for initialization logic
  ngOnInit(): void {
    this.initializeSubscription();
  }

  // Method to initialize the subscription to the message observable
  private initializeSubscription() {
    this.subscription = this.errorMsgService.getMessage().subscribe((res: string | null) => {
      if (this.isResettingMessage) {
        // If we are resetting the message, do nothing to prevent recursion
        this.isResettingMessage = false;
        return;
      }

      if (res != null) {
        this.msg = res;
        this.showModal();
      } else {
        this.closeModal(false); // Pass false to avoid resetting the message
      }
    });
  }

  // Method to show the modal
  showModal() {
    const modal = this.renderer.selectRootElement('#error-msg-popup', true);
    this.renderer.setStyle(modal, 'display', 'block');
    this.renderer.addClass(modal, 'show');

    const backdrop = this.renderer.createElement('div');
    this.renderer.addClass(backdrop, 'err-modal-backdrop');
    this.renderer.appendChild(document.body, backdrop);
  }

  // Method to close the modal
  closeModal(resetMessage: boolean = true) {
    if (resetMessage) {
      // Set flag to prevent recursive call
      this.isResettingMessage = true;
      this.errorMsgService.setMessage(null);
    }

    const modal = this.renderer.selectRootElement('#error-msg-popup', true);
    this.renderer.setStyle(modal, 'display', 'none');
    this.renderer.removeClass(modal, 'show');

    const backdrop = document.querySelector('.err-modal-backdrop');
    if (backdrop) {
      this.renderer.removeChild(document.body, backdrop);
    }
  }

  // Lifecycle hook for cleanup logic
  ngOnDestroy() {
    this.teardown();
  }

  // Method for cleanup: unsubscribe and reset message
  private teardown() {
    // Unsubscribe first to avoid recursive call issues
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    // Then reset the message
    this.errorMsgService.setMessage(null);
  }
}
