import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { StorageService } from './storage.service';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private loginService: LoginService,
    private router: Router,
    private storage: StorageService
  ) {}
  canActivate(route: ActivatedRouteSnapshot): any {
    if (this.loginService.isLoggedIn()) {
      return true;
    }
    this.loginService.logout();
    this.router.navigateByUrl('/login');
    return false;
  }
}
